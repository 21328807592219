import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestExpenseList(params) {
  if (params.alternateId) {
    return fetch.post(
      `${BASE_URL}/api/app/expense/getDataFromTicketId`,
      params
    );
  } else {
    return fetch.get(`${BASE_URL}/api/app/expense`, {
      params,
    });
  }
}

export function requestCreateExpense(data) {
  return fetch.post(`${BASE_URL}/api/app/expense`, data);
}

export function requestUpdateExpense(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/expense/${data._id}`, body);
}

export function requestDeleteExpense(data) {
  return fetch.delete(`${BASE_URL}/api/app/expense/${data}`);
}

export function requestExpenseDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/expense/getById/${data.id}`);
}
