import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestFeActivityList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/feactivity`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/feactivity?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateFeActivity(data) {
    return fetch.post(`${BASE_URL}/api/app/feactivity`, data)
}

export function requestDeleteFeActivity(data) {
    return fetch.delete(`${BASE_URL}/api/app/feactivity/${data}`);
}

export function requestFeActivityDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/feactivity/getById/${data._id}`);
}