import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    plantList: { loading: false, data: null, error: null },
    createPlant: { loading: false, data: null, error: null },
    updatePlant: { loading: false, data: null, error: null },
    deletePlant: { loading: false, data: null, error: null },
    getPlantById: { loading: false, data: null, error: null }
}

export const plantSlice = createSlice({
    name: "plant",
    initialState,
    reducers: {
        fetchPlantListRequest: (plant, action) => {
            plant.plantList.loading = true;
            plant.plantList.error = null;
        },
        fetchPlantListSuccess: (plant, action) => {
            plant.plantList.data = action.payload;
            plant.plantList.loading = false;
            plant.plantList.error = null;
        },
        fetchPlantListFailure: (plant, action) => {
            plant.plantList.data = null;
            plant.plantList.loading = false;
            plant.plantList.error = action.payload;
        },

        //create plant
        createPlantRequest: (plant, action) => {
            plant.createPlant.loading = true;
            plant.createPlant.error = null;
        },
        createPlantSuccess: (plant, action) => {
            plant.createPlant.data = action.payload;
            plant.createPlant.loading = false;
            plant.createPlant.error = null;
            
        },
        createPlantFailure: (plant, action) => {
            plant.createPlant.data = null;
            plant.createPlant.loading = false;
            plant.createPlant.error = action.payload;
        },

        //update plant details
        updatePlantRequest: (plant, action) => {
            plant.updatePlant.loading = true;
            plant.updatePlant.error = null;
        },
        updatePlantSuccess: (plant, action) => {
            plant.updatePlant.data = action.payload;
            plant.updatePlant.loading = false;
            plant.updatePlant.error = null;
        },
        updatePlantFailure: (plant, action) => {
            plant.updatePlant.data = null;
            plant.updatePlant.loading = false;
            plant.updatePlant.error = action.payload;
        },

        // delete plant
        deletePlantRequest: (plant, action) => {
            plant.deletePlant.loading = true;
            plant.deletePlant.error = null;
        },
        deletePlantSuccess: (plant, action) => {
            plant.deletePlant.data = action.payload;
            plant.deletePlant.loading = false;
            plant.deletePlant.error = null;
            plant.plantList = deleteNewPlantSuccess(action.payload, {
                ...plant.plantList,
            })
        },
        deletePlantFailure: (plant, action) => {
            plant.deletePlant.data = null;
            plant.deletePlant.loading = false;
            plant.deletePlant.error = action.payload;
        },

        // getById plant details
        getPlantByIdRequest: (plant, action) => {
            plant.getPlantById.loading = true;
            plant.getPlantById.error = null;
        },
        getPlantByIdSuccess: (plant, action) => {
            plant.getPlantById.data = action.payload;
            plant.getPlantById.loading = false;
            plant.getPlantById.error = null;
        },
        getPlantByIdFailure: (plant, action) => {
            plant.getPlantById.data = null;
            plant.getPlantById.loading = false;
            plant.getPlantById.error = action.payload;
        },

        // reset plant details
        resetPlant: (plant, action) => {
            plant.createPlant.data = null;
            plant.createPlant.loading = false;
            plant.createPlant.error = null;

            plant.updatePlant.data = null;
            plant.updatePlant.loading = false;
            plant.updatePlant.error = null;

            plant.deletePlant.data = null;
            plant.deletePlant.loading = false;
            plant.deletePlant.error = null;
        }
    }
})

function deleteNewPlantSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchPlantListRequest,
    fetchPlantListSuccess,
    fetchPlantListFailure,

    createPlantRequest,
    createPlantSuccess,
    createPlantFailure,

    updatePlantRequest,
    updatePlantSuccess,
    updatePlantFailure,

    deletePlantRequest,
    deletePlantSuccess,
    deletePlantFailure,

    getPlantByIdRequest,
    getPlantByIdSuccess,
    getPlantByIdFailure,

    resetPlant,
} = plantSlice.actions;

export const getPlantListState = (store) =>
    store?.plant?.plantList;
export const getPlantCreateState = (store) =>
    store?.plant?.createPlant;
export const getPlantUpdateState = (store) =>
    store?.plant?.updatePlant;
export const getPlantDeleteState = (store) =>
    store?.plant?.deletePlant;
export const getPlantGetByIdState = (store) =>
    store?.plant?.getPlantById;
export default plantSlice.reducer;