import { put, takeLatest } from "redux-saga/effects";
import {
    fetchSparePartsListFailure,
    fetchSparePartsListRequest,
    fetchSparePartsListSuccess
} from "redux/reducers/spareParts";
import {
    requestSparePartsList
} from "services/spareParts";

export function* watchSparePartsSaga() {
    yield takeLatest(fetchSparePartsListRequest, fetchSparePartsList);
}

function* fetchSparePartsList({ payload }) {
    try {
        const response = yield requestSparePartsList(payload);
        yield put(fetchSparePartsListSuccess(response));
    } catch (error) {
        yield put(
            fetchSparePartsListFailure("Failed to get list of spare Parts")
        )
    }
}