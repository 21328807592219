import { put, takeLatest } from "redux-saga/effects";
import {
    fetchClientListFailure,
    fetchClientListRequest,
    fetchClientListSuccess,
    createClientRequest,
    createClientSuccess,
    createClientFailure,
    updateClientRequest,
    updateClientSuccess,
    updateClientFailure,
    clientGetByIdRequest,
    clientGetByIdSuccess,
    clientGetByIdFailure
} from "redux/reducers/client";
import {
    requestClientList,
    requestCreateClient,
    requestUpdateClient,
    requestClientDataById
} from "services/client";

export function* watchClientSaga() {
    yield takeLatest(fetchClientListRequest, fetchClientList);
    yield takeLatest(createClientRequest, createClient);
    yield takeLatest(updateClientRequest, updateClient);
    yield takeLatest(clientGetByIdRequest, getClientGetById);
}

function* fetchClientList({ payload }) {
    try {
        const response = yield requestClientList(payload);
        yield put(fetchClientListSuccess(response));
    } catch (error) {
        yield put(
            fetchClientListFailure("Failed to get list of client")
        )
    }
}

function* createClient({ payload }) {
    try {
        const response = yield requestCreateClient(payload);
        yield put(createClientSuccess(response.data))
    } catch(error) {
        yield put (createClientFailure("Failed to create client"))
    }
}

function* updateClient({ payload }) {
    try {
        const response = yield requestUpdateClient(payload);
        yield put(updateClientSuccess(response.data))
    } catch(error) {
        yield put (updateClientFailure("Failed to update client"))
    }
}

function* getClientGetById({ payload }) {
    try {
        const response = yield requestClientDataById(payload);
        yield put(clientGetByIdSuccess(response))
    } catch(error) {
        yield put (clientGetByIdFailure("Failed to get client"))
    }
}