import { put, takeLatest } from "redux-saga/effects";
import {
    fetchMasterConfigListFailure,
    fetchMasterConfigListRequest,
    fetchMasterConfigListSuccess
} from "redux/reducers/masterConfig";
import {
    requestMasterConfigList
} from "services/masterConfig";

export function* watchMasterConfigSaga() {
    yield takeLatest(fetchMasterConfigListRequest, fetchMasterConfigList);
}

function* fetchMasterConfigList({ payload }) {
    try {
        const response = yield requestMasterConfigList(payload);
        yield put(fetchMasterConfigListSuccess(response));
    } catch (error) {
        yield put(
            fetchMasterConfigListFailure("Failed to get list of Master Config")
        )
    }
}