import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assetTypeList: { loading: false, data: null, error: null }
}

export const assetTypeSlice = createSlice({
    name: "assetType",
    initialState,
    reducers: {
        fetchAssetTypeListRequest: (assetType, action) => {
            assetType.assetTypeList.loading = true;
            assetType.assetTypeList.error = null;
        },
        fetchAssetTypeListSuccess: (assetType, action) => {
            assetType.assetTypeList.data = action.payload;
            assetType.assetTypeList.loading = false;
            assetType.assetTypeList.error = null
        },
        fetchAssetTypeListFailure: (assetType, action) => {
            assetType.assetTypeList.data = null;
            assetType.assetTypeList.loading = false;
            assetType.assetTypeList.error = action.payload;
        }
    }
})

export const {
    fetchAssetTypeListRequest,
    fetchAssetTypeListSuccess,
    fetchAssetTypeListFailure
} = assetTypeSlice.actions

export const getAssetTypeListState = (store) =>
    store?.assetType?.assetTypeList;
export default assetTypeSlice.reducer;