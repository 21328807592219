import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestDiscussionList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/discussion`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/discussion?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateDiscussion(data) {
    return fetch.post(`${BASE_URL}/api/app/discussion`, data)
}

export function requestUpdateDiscussion(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/discussion/${data._id}`, body);
}

export function requestDeleteDiscussion(data) {
    return fetch.delete(`${BASE_URL}/api/app/discussion/${data}`);
}

export function requestDiscussionDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/discussion/getById/${data.id}`);
}