import { put, takeLatest } from "redux-saga/effects";

import {
    requestActionTicketList,
    requestCreateActionTicket,
    requestUpdateActionTicket,
    requestDeleteActionTicket,
    requestActionTicketDataById,
} from "services/actionTicket";

import {
    fetchActionTicketListRequest,
    fetchActionTicketListSuccess,
    fetchActionTicketListFailure,
    createActionTicketRequest,
    createActionTicketSuccess,
    createActionTicketFailure,
    updateActionTicketRequest,
    updateActionTicketSuccess,
    updateActionTicketFailure,
    deleteActionTicketRequest,
    deleteActionTicketSuccess,
    deleteActionTicketFailure,
    getActionTicketByIdRequest,
    getActionTicketByIdSuccess,
    getActionTicketByIdFailure,
} from "redux/reducers/actionTicket";

export function* watchActionTicketSaga() {
    yield takeLatest(fetchActionTicketListRequest, fetchActionTicketList);
    yield takeLatest(createActionTicketRequest, createActionTicket);
    yield takeLatest(updateActionTicketRequest, updateActionTicket);
    yield takeLatest(deleteActionTicketRequest, deleteActionTicket);
    yield takeLatest(getActionTicketByIdRequest, getActionTicketById);
}

function* fetchActionTicketList({ payload }) {
    try {
        const response = yield requestActionTicketList(payload);
        yield put(fetchActionTicketListSuccess(response));
    } catch (error) {
        yield put(
            fetchActionTicketListFailure("Failed to get list of actionTicket")
        );
    }
}

function* createActionTicket({ payload }) {
    try {
        // service add
        const response = yield requestCreateActionTicket(payload);
        yield put(createActionTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create actionTicket";
        yield put(createActionTicketFailure(message));
    }
}

function* updateActionTicket({ payload }) {
    try {
        const response = yield requestUpdateActionTicket(payload);
        yield put(updateActionTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update actionTicket";
        yield put(updateActionTicketFailure(message));
    }
}

function* deleteActionTicket({ payload }) {
    try {
        const response = yield requestDeleteActionTicket(payload);
        yield put(deleteActionTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete actionTicket";
        yield put(deleteActionTicketFailure(message));
    }
}

function* getActionTicketById({ payload }) {
    try {
        const response = yield requestActionTicketDataById(payload);
        yield put(getActionTicketByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get actionTicket details";
        yield put(getActionTicketByIdFailure(message));
    }
}