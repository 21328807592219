import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sensorProductList: { loading: false, data: null, error: null },
  createSensorProduct: { loading: false, data: null, error: null },
  updateSensorProduct: { loading: false, data: null, error: null },
  deleteSensorProduct: { loading: false, data: null, error: null },
  getSensorProductById: { loading: false, data: null, error: null },
  getManufacturerList: { loading: false, data: null, error: null },
};

export const SensorProductSlice = createSlice({
  name: "sensorProduct",
  initialState,
  reducers: {
    fetchSensorProductListRequest: (sensorProduct, action) => {
      sensorProduct.sensorProductList.loading = true;
      sensorProduct.sensorProductList.error = null;
    },
    fetchSensorProductListSuccess: (sensorProduct, action) => {
      sensorProduct.sensorProductList.data = action.payload;
      sensorProduct.sensorProductList.loading = false;
      sensorProduct.sensorProductList.error = null;
    },
    fetchSensorProductListFailure: (sensorProduct, action) => {
      sensorProduct.sensorProductList.data = null;
      sensorProduct.sensorProductList.loading = false;
      sensorProduct.sensorProductList.error = action.payload;
    },

    // create sensorProduct
    createSensorProductRequest: (sensorProduct, action) => {
      sensorProduct.createSensorProduct.loading = true;
      sensorProduct.createSensorProduct.error = null;
    },
    createSensorProductSuccess: (sensorProduct, action) => {
      sensorProduct.createSensorProduct.data = action.payload;
      sensorProduct.createSensorProduct.loading = false;
      sensorProduct.createSensorProduct.error = null;
      sensorProduct.sensorProductList = addNewProductSuccess(action.payload, {
        ...sensorProduct.sensorProductList,
      });
    },
    createSensorProductFailure: (sensorProduct, action) => {
      sensorProduct.createSensorProduct.data = null;
      sensorProduct.createSensorProduct.loading = false;
      sensorProduct.createSensorProduct.error = action.payload;
    },

    // update sensorProduct details
    updateSensorProductRequest: (sensorProduct, action) => {
      sensorProduct.updateSensorProduct.loading = true;
      sensorProduct.updateSensorProduct.error = null;
    },
    updateSensorProductSuccess: (sensorProduct, action) => {
      sensorProduct.updateSensorProduct.data = action.payload;
      sensorProduct.updateSensorProduct.loading = false;
      sensorProduct.updateSensorProduct.error = null;
      sensorProduct.sensorProductList = updateProductSuccess(
        action.payload,
        sensorProduct.sensorProductList
      );
    },
    updateManagePrivilegeFailure: (sensorProduct, action) => {
      sensorProduct.updateSensorProduct.data = null;
      sensorProduct.updateSensorProduct.loading = false;
      sensorProduct.updateSensorProduct.error = action.payload;
    },

    // delete sensorProduct
    deleteSensorProductRequest: (sensorProduct, action) => {
      sensorProduct.deleteSensorProduct.loading = true;
      sensorProduct.deleteSensorProduct.error = null;
    },
    deleteSensorProductSuccess: (sensorProduct, action) => {
      sensorProduct.deleteSensorProduct.loading = false;
      sensorProduct.deleteSensorProduct.error = null;
      sensorProduct.deleteSensorProduct.data = action.payload;
      sensorProduct.sensorProductList = deleteProduct(action.payload, {
        ...sensorProduct.sensorProductList,
      });
    },
    deleteSensorProductFailure: (sensorProduct, action) => {
      sensorProduct.deleteSensorProduct.data = null;
      sensorProduct.deleteSensorProduct.loading = false;
      sensorProduct.deleteSensorProduct.error = action.payload;
    },

    // get by id sensorProduct details
    getSensorProductDetailsRequest: (sensorProduct, action) => {
      sensorProduct.getSensorProductById.loading = true;
      sensorProduct.getSensorProductById.error = null;
    },
    getSensorProductDetailsSuccess: (sensorProduct, action) => {
      sensorProduct.getSensorProductById.loading = false;
      sensorProduct.getSensorProductById.error = null;
      sensorProduct.getSensorProductById.data = action.payload;
    },
    getSensorProductDetailsFailure: (sensorProduct, action) => {
      sensorProduct.getSensorProductById.data = null;
      sensorProduct.getSensorProductById.loading = false;
      sensorProduct.getSensorProductById.error = action.payload;
    },

    fetchManufacturerListRequest: (state, action) => {
      state.getManufacturerList.loading = true;
      state.getManufacturerList.error = null;
    },
    fetchManufacturerListSuccess: (state, action) => {
      state.getManufacturerList.data = action.payload;
      state.getManufacturerList.loading = false;
      state.getManufacturerList.error = null;
    },
    fetchManufacturerListFailure: (state, action) => {
      state.getManufacturerList.data = null;
      state.getManufacturerList.loading = false;
      state.getManufacturerList.error = action.payload;
    },

    //Reset sensorProduct state
    resetSensorProduct: (sensorProduct, action) => {
      sensorProduct.createSensorProduct.data = null;
      sensorProduct.createSensorProduct.loading = false;
      sensorProduct.createSensorProduct.error = null;

      sensorProduct.updateSensorProduct.data = null;
      sensorProduct.updateSensorProduct.loading = false;
      sensorProduct.updateSensorProduct.error = null;

      sensorProduct.deleteSensorProduct.data = null;
      sensorProduct.deleteSensorProduct.loading = false;
      sensorProduct.deleteSensorProduct.error = null;
    },
  },
});

// addNewPrivilege function
function addNewProductSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// updatePrivilege function
function updateProductSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deletePrivilege function
function deleteProduct(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchSensorProductListRequest,
  fetchSensorProductListSuccess,
  fetchSensorProductListFailure,

  createSensorProductRequest,
  createSensorProductSuccess,
  createSensorProductFailure,

  updateSensorProductRequest,
  updateSensorProductSuccess,
  updateSensorProductFailure,

  deleteSensorProductRequest,
  deleteSensorProductSuccess,
  deleteSensorProductFailure,

  getSensorProductDetailsRequest,
  getSensorProductDetailsSuccess,
  getSensorProductDetailsFailure,

  fetchManufacturerListRequest,
  fetchManufacturerListSuccess,
  fetchManufacturerListFailure,

  resetSensorProduct,
} = SensorProductSlice.actions;

export const getSensorProductListState = (store) =>
  store?.sensorProduct?.sensorProductList;
export const getSensorProductCreateState = (store) =>
  store?.sensorProduct?.createSensorProduct;
export const getUpdateSensorProductState = (store) =>
  store?.sensorProduct?.updateSensorProduct;
export const getSensorProductDeleteState = (store) =>
  store?.sensorProduct?.deleteSensorProduct;
export const getSensorProductGetByIdState = (store) =>
  store?.sensorProduct?.getSensorProductById;
export const getManufacturerListState = (store) =>
  store?.sensorProduct?.getManufacturerList;
export default SensorProductSlice.reducer;
