import { put, takeLatest } from "redux-saga/effects";
import {
    fetchUnitListFailure,
    fetchUnitListRequest,
    fetchUnitListSuccess,
    createUnitRequest,
    createUnitSuccess,
    createUnitFailure,
    updateUnitRequest,
    updateUnitSuccess,
    updateUnitFailure,
    deleteUnitRequest,
    deleteUnitSuccess,
    deleteUnitFailure,
    getUnitByIdRequest,
    getUnitByIdSuccess,
    getUnitByIdFailure,
} from "redux/reducers/unit";
import {
    requestOrganizationList,
    requestCreateOrganization,
    requestUpdateOrganization,
    requestDeleteOrganization,
    requestOrganizationDataById,
} from "services/organization";

export function* watchUnitSaga() {
    yield takeLatest(fetchUnitListRequest, fetchUnitList);
    yield takeLatest(createUnitRequest, createUnit);
    yield takeLatest(updateUnitRequest, updateUnit);
    yield takeLatest(deleteUnitRequest, deleteUnit);
    yield takeLatest(getUnitByIdRequest, getUnitById);
}

function* fetchUnitList({ payload }) {
    try {
        const response = yield requestOrganizationList(payload);
        yield put(fetchUnitListSuccess(response.data));
    } catch (error) {
        yield put(
            fetchUnitListFailure("Failed to get Unit")
        )
    }
}

function* createUnit({ payload }) {
    try {
        // service add
        const response = yield requestCreateOrganization(payload);
        yield put(createUnitSuccess(response.data));
    } catch (error) {
        yield put(createUnitFailure("Failed to create Unit"));
    }
}

function* updateUnit({ payload }) {
    try {
        const response = yield requestUpdateOrganization(payload);
        yield put(updateUnitSuccess(response.data));
    } catch (error) {
        yield put(updateUnitFailure("Failed to update Unit"));
    }
}

function* deleteUnit({ payload }) {
    try {
        const response = yield requestDeleteOrganization(payload);
        yield put(deleteUnitSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete Unit";
        yield put(deleteUnitFailure(message));
    }
}

function* getUnitById({ payload }) {
    try {
        const response = yield requestOrganizationDataById(payload);
        yield put(getUnitByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get Unit details";
        yield put(getUnitByIdFailure(message));
    }
}