import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sensorDataList: { loading: false, data: null, error: null },
};

export const SensorData = createSlice({
  name: "sensorData",
  initialState,
  reducers: {
    fetchSensorDataListRequest: (state, action) => {
      state.sensorDataList.loading = true;
      state.sensorDataList.error = null;
    },
    fetchSensorDataListSuccess: (state, action) => {
      state.sensorDataList.data = action.payload;
      state.sensorDataList.loading = false;
      state.sensorDataList.error = null;
    },
    fetchSensorDataListFailure: (state, action) => {
      state.sensorDataList.data = null;
      state.sensorDataList.loading = false;
      state.sensorDataList.error = action.payload;
    },
  },
});

export const {
  fetchSensorDataListRequest,
  fetchSensorDataListSuccess,
  fetchSensorDataListFailure,
} = SensorData.actions;

export const getSensorDataListState = (store) =>
  store.sensorData?.sensorDataList;

export default SensorData.reducer;
