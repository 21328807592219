import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

/**
 *
 * @param {*} params
 * @returns {Promise<FetchIndustryListResponse>}
 */
export function requestSideMenuList(params) {
  return fetch.get(`${BASE_URL}/api/app/subscriber-user/getSideMenu`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}
