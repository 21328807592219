import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preSaleMaintenenceList: { loading: false, data: null, error: null },
  createPreSaleMaintenence: { loading: false, data: null, error: null },
  updatePreSaleMaintenence: { loading: false, data: null, error: null },
  deletePreSaleMaintenence: { loading: false, data: null, error: null },
  getPreSaleMaintenenceById: { loading: false, data: null, error: null },
};

export const preSaleMaintenenceSlice = createSlice({
  name: "preSaleMaintenence",
  initialState,
  reducers: {
    fetchPreSaleMaintenenceListRequest: (preSaleMaintenence, action) => {
      preSaleMaintenence.preSaleMaintenenceList.loading = true;
      preSaleMaintenence.preSaleMaintenenceList.error = null;
    },
    fetchPreSaleMaintenenceListSuccess: (preSaleMaintenence, action) => {
      preSaleMaintenence.preSaleMaintenenceList.data = action.payload;
      preSaleMaintenence.preSaleMaintenenceList.loading = false;
      preSaleMaintenence.preSaleMaintenenceList.error = null;
    },
    fetchPreSaleMaintenenceListFailure: (preSaleMaintenence, action) => {
      preSaleMaintenence.preSaleMaintenenceList.data = null;
      preSaleMaintenence.preSaleMaintenenceList.loading = false;
      preSaleMaintenence.preSaleMaintenenceList.error = action.payload;
    },

    //create PreSaleMaintenence
    createPreSaleMaintenenceRequest: (preSaleMaintenence, action) => {
      preSaleMaintenence.createPreSaleMaintenence.loading = true;
      preSaleMaintenence.createPreSaleMaintenence.error = null;
    },
    createPreSaleMaintenenceSuccess: (preSaleMaintenence, action) => {
      preSaleMaintenence.createPreSaleMaintenence.data = action.payload;
      preSaleMaintenence.createPreSaleMaintenence.loading = false;
      preSaleMaintenence.createPreSaleMaintenence.error = null;
      preSaleMaintenence.preSaleMaintenenceList =
        addNewPreSaleMaintenenceSuccess(action.payload, {
          ...preSaleMaintenence.preSaleMaintenenceList,
        });
    },
    createPreSaleMaintenenceFailure: (preSaleMaintenence, action) => {
      preSaleMaintenence.createPreSaleMaintenence.data = null;
      preSaleMaintenence.createPreSaleMaintenence.loading = false;
      preSaleMaintenence.createPreSaleMaintenence.error = action.payload;
    },

    //update preSaleMaintenence details
    updatePreSaleMaintenenceRequest: (preSaleMaintenence, action) => {
      preSaleMaintenence.updatePreSaleMaintenence.loading = true;
      preSaleMaintenence.updatePreSaleMaintenence.error = null;
    },
    updatePreSaleMaintenenceSuccess: (preSaleMaintenence, action) => {
      preSaleMaintenence.updatePreSaleMaintenence.data = action.payload;
      preSaleMaintenence.updatePreSaleMaintenence.loading = false;
      preSaleMaintenence.updatePreSaleMaintenence.error = null;
      preSaleMaintenence.preSaleMaintenenceList =
        updateNewPreSaleMaintenenceSuccess(
          action.payload,
          preSaleMaintenence.preSaleMaintenenceList
        );
    },
    updatePreSaleMaintenenceFailure: (preSaleMaintenence, action) => {
      preSaleMaintenence.updatePreSaleMaintenence.data = null;
      preSaleMaintenence.updatePreSaleMaintenence.loading = false;
      preSaleMaintenence.updatePreSaleMaintenence.error = action.payload;
    },

    // delete preSaleMaintenence
    deletePreSaleMaintenenceRequest: (preSaleMaintenence, action) => {
      preSaleMaintenence.deletePreSaleMaintenence.loading = true;
      preSaleMaintenence.deletePreSaleMaintenence.error = null;
    },
    deletePreSaleMaintenenceSuccess: (preSaleMaintenence, action) => {
      preSaleMaintenence.deletePreSaleMaintenence.data = action.payload;
      preSaleMaintenence.deletePreSaleMaintenence.loading = false;
      preSaleMaintenence.deletePreSaleMaintenence.error = null;
      preSaleMaintenence.preSaleMaintenenceList =
        deleteNewPreSaleMaintenenceSuccess(action.payload, {
          ...preSaleMaintenence.preSaleMaintenenceList,
        });
    },
    deletePreSaleMaintenenceFailure: (preSaleMaintenence, action) => {
      preSaleMaintenence.deletePreSaleMaintenence.data = null;
      preSaleMaintenence.deletePreSaleMaintenence.loading = false;
      preSaleMaintenence.deletePreSaleMaintenence.error = action.payload;
    },

    // getById preSaleMaintenence details
    getPreSaleMaintenenceByIdRequest: (preSaleMaintenence, action) => {
      preSaleMaintenence.getPreSaleMaintenenceById.loading = true;
      preSaleMaintenence.getPreSaleMaintenenceById.error = null;
    },
    getPreSaleMaintenenceByIdSuccess: (preSaleMaintenence, action) => {
      preSaleMaintenence.getPreSaleMaintenenceById.data = action.payload;
      preSaleMaintenence.getPreSaleMaintenenceById.loading = false;
      preSaleMaintenence.getPreSaleMaintenenceById.error = null;
    },
    getPreSaleMaintenenceByIdFailure: (preSaleMaintenence, action) => {
      preSaleMaintenence.getPreSaleMaintenenceById.data = null;
      preSaleMaintenence.getPreSaleMaintenenceById.loading = false;
      preSaleMaintenence.getPreSaleMaintenenceById.error = action.payload;
    },

    // reset preSaleMaintenence details
    resetPreSaleMaintenence: (preSaleMaintenence, action) => {
      preSaleMaintenence.createPreSaleMaintenence.data = null;
      preSaleMaintenence.createPreSaleMaintenence.loading = false;
      preSaleMaintenence.createPreSaleMaintenence.error = null;

      preSaleMaintenence.updatePreSaleMaintenence.data = null;
      preSaleMaintenence.updatePreSaleMaintenence.loading = false;
      preSaleMaintenence.updatePreSaleMaintenence.error = null;

      preSaleMaintenence.deletePreSaleMaintenence.data = null;
      preSaleMaintenence.deletePreSaleMaintenence.loading = false;
      preSaleMaintenence.deletePreSaleMaintenence.error = null;

      preSaleMaintenence.getPreSaleMaintenenceById.data = null;
      preSaleMaintenence.getPreSaleMaintenenceById.loading = false;
      preSaleMaintenence.getPreSaleMaintenenceById.error = null;
    },
  },
});

function addNewPreSaleMaintenenceSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewPreSaleMaintenenceSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewPreSaleMaintenenceSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchPreSaleMaintenenceListRequest,
  fetchPreSaleMaintenenceListSuccess,
  fetchPreSaleMaintenenceListFailure,

  createPreSaleMaintenenceRequest,
  createPreSaleMaintenenceSuccess,
  createPreSaleMaintenenceFailure,

  updatePreSaleMaintenenceRequest,
  updatePreSaleMaintenenceSuccess,
  updatePreSaleMaintenenceFailure,

  deletePreSaleMaintenenceRequest,
  deletePreSaleMaintenenceSuccess,
  deletePreSaleMaintenenceFailure,

  getPreSaleMaintenenceByIdRequest,
  getPreSaleMaintenenceByIdSuccess,
  getPreSaleMaintenenceByIdFailure,

  resetPreSaleMaintenence,
} = preSaleMaintenenceSlice.actions;

export const getPreSaleMaintenenceListState = (store) =>
  store?.preSaleMaintenence?.preSaleMaintenenceList;
export const getPreSaleMaintenenceCreateState = (store) =>
  store?.preSaleMaintenence?.createPreSaleMaintenence;
export const getPreSaleMaintenenceUpdateState = (store) =>
  store?.preSaleMaintenence?.updatePreSaleMaintenence;
export const getPreSaleMaintenenceDeleteState = (store) =>
  store?.preSaleMaintenence?.deletePreSaleMaintenence;
export const getPreSaleMaintenenceGetByIdState = (store) =>
  store?.preSaleMaintenence?.getPreSaleMaintenenceById;
export default preSaleMaintenenceSlice.reducer;
