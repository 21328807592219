import { put, takeLatest } from "redux-saga/effects";
import {
    fetchQuestionsListRequest,
    fetchQuestionsListSuccess,
    fetchQuestionsListFailure,
    createQuestionsRequest,
    createQuestionsSuccess,
    createQuestionsFailure,
    updateQuestionsRequest,
    updateQuestionsSuccess,
    updateQuestionsFailure,
    fetchMachineTypeListRequest,
    fetchMachineTypeListSuccess,
    fetchMachineTypeListFailure,
} from "redux/reducers/questions";

import {
    requestQuestionsList,
    requestCreateQuestions,
    requestUpdateQuestions,
    getMachineTypeList
} from "services/questions"

export function* watchQuestionsSaga() {
    yield takeLatest(fetchQuestionsListRequest, fetchQuestionsList);
    yield takeLatest(createQuestionsRequest, createQuestion);
    yield takeLatest(updateQuestionsRequest, updateQuestion);
    yield takeLatest(fetchMachineTypeListRequest, getMachineTypeListData);
}

function* fetchQuestionsList({ payload }) {
    try {
        const response = yield requestQuestionsList(payload);
        yield put(fetchQuestionsListSuccess(response));
    } catch (error) {
        yield put(
            fetchQuestionsListFailure("Failed to get list of FEf")
        )
    }
}

function* createQuestion({ payload }) {
    try {
        //service add
        const response = yield requestCreateQuestions(payload);
        yield put(createQuestionsSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message ||
            "Failed to create Questions";
        yield put(createQuestionsFailure(message));
    }
}

function* updateQuestion({ payload }) {
    try {
        //service update
        const response = yield requestUpdateQuestions(payload);
        yield put(updateQuestionsSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message ||
            "Failed to update Questions";
        yield put(updateQuestionsFailure(message));
    }
}

function* getMachineTypeListData({ payload }) {
    try {
      const response = yield getMachineTypeList(payload);
      yield put(fetchMachineTypeListSuccess(response));
    } catch (error) {
      yield put(
        fetchMachineTypeListFailure("Failed to get list of machine type Name")
      );
    }
  }