import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deleteDocument: { loading: false, data: null, error: null },
};

export const DocumentSlice = createSlice({
  name: "document",
  initialState,
  reducers: {
    // delete document
    deleteDocumentRequest: (document, action) => {
      document.deleteDocument.loading = true;
      document.deleteDocument.error = null;
    },
    deleteDocumentSuccess: (document, action) => {
      document.deleteDocument.loading = false;
      document.deleteDocument.error = null;
      document.deleteDocument.data = action.payload;
    },
    deleteDocumentFailure: (document, action) => {
      document.deleteDocument.data = null;
      document.deleteDocument.loading = false;
      document.deleteDocument.error = action.payload;
    },

    //Reset features state
    resetDocument: (document, action) => {
      document.deleteDocument.data = null;
      document.deleteDocument.loading = false;
      document.deleteDocument.error = null;
    },
  },
});

export const {
  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentFailure,

  resetDocument,
} = DocumentSlice.actions;

export const getDeleteDocumentState = (store) =>
  store?.document?.deleteDocument;
export default DocumentSlice.reducer;
