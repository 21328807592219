export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "auth_token";
export const LOGGEDIN = "loggedIN";
export const USER = "user";
export const TIMEZONE = "timezone";
export const SUBSCRIBER_ID = "subscriberId";
