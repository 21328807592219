import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    discussionList: { loading: false, data: null, error: null },
    createDiscussion: { loading: false, data: null, error: null },
    updateDiscussion: { loading: false, data: null, error: null },
    deleteDiscussion: { loading: false, data: null, error: null },
    getDiscussionById: { loading: false, data: null, error: null }
}

export const discussionSlice = createSlice({
    name: "discussion",
    initialState,
    reducers: {
        fetchDiscussionListRequest: (discussion, action) => {
            discussion.discussionList.loading = true;
            discussion.discussionList.error = null;
        },
        fetchDiscussionListSuccess: (discussion, action) => {
            discussion.discussionList.data = action.payload;
            discussion.discussionList.loading = false;
            discussion.discussionList.error = null;
        },
        fetchDiscussionListFailure: (discussion, action) => {
            discussion.discussionList.data = null;
            discussion.discussionList.loading = false;
            discussion.discussionList.error = action.payload;
        },

        //create Discussion
        createDiscussionRequest: (discussion, action) => {
            discussion.createDiscussion.loading = true;
            discussion.createDiscussion.error = null;
        },
        createDiscussionSuccess: (discussion, action) => {
            discussion.createDiscussion.data = action.payload;
            discussion.createDiscussion.loading = false;
            discussion.createDiscussion.error = null;
            discussion.discussionList = addNewDiscussionSuccess(action.payload, {
                ...discussion.discussionList,
            })
        },
        createDiscussionFailure: (discussion, action) => {
            discussion.createDiscussion.data = null;
            discussion.createDiscussion.loading = false;
            discussion.createDiscussion.error = action.payload;
        },

        //update discussion details
        updateDiscussionRequest: (discussion, action) => {
            discussion.updateDiscussion.loading = true;
            discussion.updateDiscussion.error = null;
        },
        updateDiscussionSuccess: (discussion, action) => {
            discussion.updateDiscussion.data = action.payload;
            discussion.updateDiscussion.loading = false;
            discussion.updateDiscussion.error = null;
            discussion.discussionList = updateNewDiscussionSuccess(
                action.payload,
                discussion.discussionList
            );
        },
        updateDiscussionFailure: (discussion, action) => {
            discussion.updateDiscussion.data = null;
            discussion.updateDiscussion.loading = false;
            discussion.updateDiscussion.error = action.payload;
        },

        // delete discussion
        deleteDiscussionRequest: (discussion, action) => {
            discussion.deleteDiscussion.loading = true;
            discussion.deleteDiscussion.error = null;
        },
        deleteDiscussionSuccess: (discussion, action) => {
            discussion.deleteDiscussion.data = action.payload;
            discussion.deleteDiscussion.loading = false;
            discussion.deleteDiscussion.error = null;
            discussion.discussionList = deleteNewDiscussionSuccess(action.payload, {
                ...discussion.discussionList,
            })
        },
        deleteDiscussionFailure: (discussion, action) => {
            discussion.deleteDiscussion.data = null;
            discussion.deleteDiscussion.loading = false;
            discussion.deleteDiscussion.error = action.payload;
        },

        // getById discussion details
        getDiscussionByIdRequest: (discussion, action) => {
            discussion.getDiscussionById.loading = true;
            discussion.getDiscussionById.error = null;
        },
        getDiscussionByIdSuccess: (discussion, action) => {
            discussion.getDiscussionById.data = action.payload;
            discussion.getDiscussionById.loading = false;
            discussion.getDiscussionById.error = null;
        },
        getDiscussionByIdFailure: (discussion, action) => {
            discussion.getDiscussionById.data = null;
            discussion.getDiscussionById.loading = false;
            discussion.getDiscussionById.error = action.payload;
        },

        // reset discussion details
        resetDiscussion: (discussion, action) => {
            discussion.createDiscussion.data = null;
            discussion.createDiscussion.loading = false;
            discussion.createDiscussion.error = null;

            discussion.updateDiscussion.data = null;
            discussion.updateDiscussion.loading = false;
            discussion.updateDiscussion.error = null;

            discussion.deleteDiscussion.data = null;
            discussion.deleteDiscussion.loading = false;
            discussion.deleteDiscussion.error = null;
        }
    }
})

function addNewDiscussionSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewDiscussionSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewDiscussionSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchDiscussionListRequest,
    fetchDiscussionListSuccess,
    fetchDiscussionListFailure,

    createDiscussionRequest,
    createDiscussionSuccess,
    createDiscussionFailure,

    updateDiscussionRequest,
    updateDiscussionSuccess,
    updateDiscussionFailure,

    deleteDiscussionRequest,
    deleteDiscussionSuccess,
    deleteDiscussionFailure,

    getDiscussionByIdRequest,
    getDiscussionByIdSuccess,
    getDiscussionByIdFailure,

    resetDiscussion,
} = discussionSlice.actions;

export const getDiscussionListState = (store) =>
    store?.discussion?.discussionList;
export const getDiscussionCreateState = (store) =>
    store?.discussion?.createDiscussion;
export const getDiscussionUpdateState = (store) =>
    store?.discussion?.updateDiscussion;
export const getDiscussionDeleteState = (store) =>
    store?.discussion?.deleteDiscussion;
export const getDiscussionGetByIdState = (store) =>
    store?.discussion?.getDiscussionById;
export default discussionSlice.reducer;