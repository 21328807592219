import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  actionTicketList: { loading: false, data: null, error: null },
  createActionTicket: { loading: false, data: null, error: null },
  updateActionTicket: { loading: false, data: null, error: null },
  deleteActionTicket: { loading: false, data: null, error: null },
  getActionTicketById: { loading: false, data: null, error: null },
};

export const actionTicketSlice = createSlice({
  name: "actionTicket",
  initialState,
  reducers: {
    fetchActionTicketListRequest: (actionTicket, action) => {
      actionTicket.actionTicketList.loading = true;
      actionTicket.actionTicketList.error = null;
    },
    fetchActionTicketListSuccess: (actionTicket, action) => {
      actionTicket.actionTicketList.data = action.payload;
      actionTicket.actionTicketList.loading = false;
      actionTicket.actionTicketList.error = null;
    },
    fetchActionTicketListFailure: (actionTicket, action) => {
      actionTicket.actionTicketList.data = null;
      actionTicket.actionTicketList.loading = false;
      actionTicket.actionTicketList.error = action.payload;
    },

    //create ActionTicket
    createActionTicketRequest: (actionTicket, action) => {
      actionTicket.createActionTicket.loading = true;
      actionTicket.createActionTicket.error = null;
    },
    createActionTicketSuccess: (actionTicket, action) => {
      actionTicket.createActionTicket.data = action.payload;
      actionTicket.createActionTicket.loading = false;
      actionTicket.createActionTicket.error = null;
      actionTicket.actionTicketList = addNewActionTicketSuccess(
        action.payload,
        {
          ...actionTicket.actionTicketList,
        }
      );
    },
    createActionTicketFailure: (actionTicket, action) => {
      actionTicket.createActionTicket.data = null;
      actionTicket.createActionTicket.loading = false;
      actionTicket.createActionTicket.error = action.payload;
    },

    //update actionTicket details
    updateActionTicketRequest: (actionTicket, action) => {
      actionTicket.updateActionTicket.loading = true;
      actionTicket.updateActionTicket.error = null;
    },
    updateActionTicketSuccess: (actionTicket, action) => {
      actionTicket.updateActionTicket.data = action.payload;
      actionTicket.updateActionTicket.loading = false;
      actionTicket.updateActionTicket.error = null;
      actionTicket.actionTicketList = updateNewActionTicketSuccess(
        action.payload,
        actionTicket.actionTicketList
      );
    },
    updateActionTicketFailure: (actionTicket, action) => {
      actionTicket.updateActionTicket.data = null;
      actionTicket.updateActionTicket.loading = false;
      actionTicket.updateActionTicket.error = action.payload;
    },

    // delete actionTicket
    deleteActionTicketRequest: (actionTicket, action) => {
      actionTicket.deleteActionTicket.loading = true;
      actionTicket.deleteActionTicket.error = null;
    },
    deleteActionTicketSuccess: (actionTicket, action) => {
      actionTicket.deleteActionTicket.data = action.payload;
      actionTicket.deleteActionTicket.loading = false;
      actionTicket.deleteActionTicket.error = null;
      actionTicket.actionTicketList = deleteNewActionTicketSuccess(
        action.payload,
        {
          ...actionTicket.actionTicketList,
        }
      );
    },
    deleteActionTicketFailure: (actionTicket, action) => {
      actionTicket.deleteActionTicket.data = null;
      actionTicket.deleteActionTicket.loading = false;
      actionTicket.deleteActionTicket.error = action.payload;
    },

    // getById actionTicket details
    getActionTicketByIdRequest: (actionTicket, action) => {
      actionTicket.getActionTicketById.loading = true;
      actionTicket.getActionTicketById.error = null;
    },
    getActionTicketByIdSuccess: (actionTicket, action) => {
      actionTicket.getActionTicketById.data = action.payload;
      actionTicket.getActionTicketById.loading = false;
      actionTicket.getActionTicketById.error = null;
    },
    getActionTicketByIdFailure: (actionTicket, action) => {
      actionTicket.getActionTicketById.data = null;
      actionTicket.getActionTicketById.loading = false;
      actionTicket.getActionTicketById.error = action.payload;
    },

    // reset actionTicket details
    resetActionTicket: (actionTicket, action) => {
      actionTicket.createActionTicket.data = null;
      actionTicket.createActionTicket.loading = false;
      actionTicket.createActionTicket.error = null;

      actionTicket.updateActionTicket.data = null;
      actionTicket.updateActionTicket.loading = false;
      actionTicket.updateActionTicket.error = null;

      actionTicket.deleteActionTicket.data = null;
      actionTicket.deleteActionTicket.loading = false;
      actionTicket.deleteActionTicket.error = null;
    },
  },
});

function addNewActionTicketSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewActionTicketSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewActionTicketSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchActionTicketListRequest,
  fetchActionTicketListSuccess,
  fetchActionTicketListFailure,

  createActionTicketRequest,
  createActionTicketSuccess,
  createActionTicketFailure,

  updateActionTicketRequest,
  updateActionTicketSuccess,
  updateActionTicketFailure,

  deleteActionTicketRequest,
  deleteActionTicketSuccess,
  deleteActionTicketFailure,

  getActionTicketByIdRequest,
  getActionTicketByIdSuccess,
  getActionTicketByIdFailure,

  resetActionTicket,
} = actionTicketSlice.actions;

export const getActionTicketListState = (store) =>
  store?.actionTicket?.actionTicketList;
export const getActionTicketCreateState = (store) =>
  store?.actionTicket?.createActionTicket;
export const getActionTicketUpdateState = (store) =>
  store?.actionTicket?.updateActionTicket;
export const getActionTicketDeleteState = (store) =>
  store?.actionTicket?.deleteActionTicket;
export const getActionTicketGetByIdState = (store) =>
  store?.actionTicket?.getActionTicketById;
export default actionTicketSlice.reducer;
