import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deviceList: { loading: false, data: null, error: null }
}

export const deviceSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        fetchDeviceListRequest: (device, action) => {
            device.deviceList.loading = true;
            device.deviceList.error = null;
        },
        fetchDeviceListSuccess: (device, action) => {
            device.deviceList.data = action.payload;
            device.deviceList.loading = false;
            device.deviceList.error = null
        },
        fetchDeviceListFailure: (device, action) => {
            device.deviceList.data = null;
            device.deviceList.loading = false;
            device.deviceList.error = action.payload;
        }
    }
})

export const {
    fetchDeviceListRequest,
    fetchDeviceListSuccess,
    fetchDeviceListFailure
} = deviceSlice.actions

export const getDeviceListState = (store) =>
    store?.device?.deviceList;
export default deviceSlice.reducer;