import { put, takeLatest } from "redux-saga/effects";

import {
  requestSensorProductList,
  requestCreateSensorProduct,
  requestUpdateSensorProduct,
  requestDeleteSensorProduct,
  requestSensorProductDataById,
  requestManufacturerDropDownList,
} from "../../../services/sensorProduct";
import {
  fetchSensorProductListRequest,
  fetchSensorProductListSuccess,
  fetchSensorProductListFailure,
  createSensorProductRequest,
  createSensorProductSuccess,
  createSensorProductFailure,
  updateSensorProductRequest,
  updateSensorProductSuccess,
  updateSensorProductFailure,
  deleteSensorProductRequest,
  deleteSensorProductSuccess,
  deleteSensorProductFailure,
  getSensorProductDetailsRequest,
  getSensorProductDetailsSuccess,
  getSensorProductDetailsFailure,
  fetchManufacturerListRequest,
  fetchManufacturerListSuccess,
  fetchManufacturerListFailure,
} from "../../reducers/sensorProduct";

export function* watchSensorProductSaga() {
  yield takeLatest(fetchSensorProductListRequest, fetchSensorProductList);
  yield takeLatest(createSensorProductRequest, createSensorProduct);
  yield takeLatest(updateSensorProductRequest, updateSensorProduct);
  yield takeLatest(deleteSensorProductRequest, deleteSensorProduct);
  yield takeLatest(getSensorProductDetailsRequest, getSensorProductGetByIdData);
  yield takeLatest(fetchManufacturerListRequest, getManufacturerListData);
}

function* fetchSensorProductList({ payload }) {
  try {
    const response = yield requestSensorProductList(payload);
    yield put(fetchSensorProductListSuccess(response));
  } catch (error) {
    yield put(
      fetchSensorProductListFailure("Failed to get list of sensor products")
    );
  }
}

function* createSensorProduct({ payload }) {
  try {
    //service add
    const response = yield requestCreateSensorProduct(payload);
    yield put(createSensorProductSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create sensor product";
    yield put(createSensorProductFailure(message));
  }
}

function* updateSensorProduct({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSensorProduct(payload);
    yield put(updateSensorProductSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update SensorProduct";
    yield put(updateSensorProductFailure(message));
  }
}

function* deleteSensorProduct({ payload }) {
  try {
    const response = yield requestDeleteSensorProduct(payload);
    yield put(deleteSensorProductSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete SensorProduct";
    yield put(deleteSensorProductFailure(message));
  }
}

function* getSensorProductGetByIdData({ payload }) {
  try {
    const response = yield requestSensorProductDataById(payload);
    yield put(getSensorProductDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get SensorProduct details";
    yield put(getSensorProductDetailsFailure(message));
  }
}

function* getManufacturerListData({ payload }) {
  try {
    const response = yield requestManufacturerDropDownList(payload);
    yield put(fetchManufacturerListSuccess(response));
  } catch (error) {
    yield put(
      fetchManufacturerListFailure("Failed to get list of Manufacturer Name")
    );
  }
}
