import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    organizationList: { loading: false, data: null, error: null },
    createOrganization: { loading: false, data: null, error: null },
    updateOrganization: { loading: false, data: null, error: null },
    deleteOrganization: { loading: false, data: null, error: null },
    getOrganizationById: { loading: false, data: null, error: null }
}

export const organizationSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
        fetchOrganizationListRequest: (organization, action) => {
            organization.organizationList.loading = true;
            organization.organizationList.error = null;
        },
        fetchOrganizationListSuccess: (organization, action) => {
            organization.organizationList.data = action.payload;
            organization.organizationList.loading = false;
            organization.organizationList.error = null
        },
        fetchOrganizationListFailure: (organization, action) => {
            organization.organizationList.data = null;
            organization.organizationList.loading = false;
            organization.organizationList.error = action.payload;
        },

        //create organization
        createOrganizationRequest: (organization, action) => {
            organization.createOrganization.loading = true;
            organization.createOrganization.error = null;
        },
        createOrganizationSuccess: (organization, action) => {
            organization.createOrganization.data = action.payload;
            organization.createOrganization.loading = false;
            organization.createOrganization.error = null;
            organization.organizationList = addNewOrganizationSuccess(action.payload, {
                ...organization.organizationList,
            });
        },
        createOrganizationFailure: (organization, action) => {
            organization.createOrganization.data = null;
            organization.createOrganization.loading = false;
            organization.createOrganization.error = action.payload;
        },

        //update organization details
        updateOrganizationRequest: (organization, action) => {
            organization.updateOrganization.loading = true;
            organization.updateOrganization.error = null;
        },
        updateOrganizationSuccess: (organization, action) => {
            organization.updateOrganization.data = action.payload;
            organization.updateOrganization.loading = false;
            organization.updateOrganization.error = null;
            organization.organizationList = updateNewOrganizationSuccess(
                action.payload,
                organization.organizationList
            );
        },
        updateOrganizationFailure: (organization, action) => {
            organization.updateOrganization.data = null;
            organization.updateOrganization.loading = false;
            organization.updateOrganization.error = action.payload;
        },

        // delete organization
        deleteOrganizationRequest: (organization, action) => {
            organization.deleteOrganization.loading = true;
            organization.deleteOrganization.error = null;
        },
        deleteOrganizationSuccess: (organization, action) => {
            organization.deleteOrganization.data = action.payload;
            organization.deleteOrganization.loading = false;
            organization.deleteOrganization.error = null;
            organization.organizationList = deleteNewOrganizationSuccess(action.payload, {
                ...organization.organizationList,
            })
        },
        deleteOrganizationFailure: (organization, action) => {
            organization.deleteOrganization.data = null;
            organization.deleteOrganization.loading = false;
            organization.deleteOrganization.error = action.payload;
        },

        // getById organization details
        getOrganizationByIdRequest: (organization, action) => {
            organization.getOrganizationById.loading = true;
            organization.getOrganizationById.error = null;
        },
        getOrganizationByIdSuccess: (organization, action) => {
            organization.getOrganizationById.data = action.payload;
            organization.getOrganizationById.loading = false;
            organization.getOrganizationById.error = null;
        },
        getOrganizationByIdFailure: (organization, action) => {
            organization.getOrganizationById.data = null;
            organization.getOrganizationById.loading = false;
            organization.getOrganizationById.error = action.payload;
        },

        // reset organization details
        resetOrganization: (organization, action) => {
            organization.createOrganization.data = null;
            organization.createOrganization.loading = false;
            organization.createOrganization.error = null;

            organization.updateOrganization.data = null;
            organization.updateOrganization.loading = false;
            organization.updateOrganization.error = null;

            organization.deleteOrganization.data = null;
            organization.deleteOrganization.loading = false;
            organization.deleteOrganization.error = null;
        }
    }
})

function addNewOrganizationSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewOrganizationSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewOrganizationSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchOrganizationListRequest,
    fetchOrganizationListSuccess,
    fetchOrganizationListFailure,

    createOrganizationRequest,
    createOrganizationSuccess,
    createOrganizationFailure,

    updateOrganizationRequest,
    updateOrganizationSuccess,
    updateOrganizationFailure,

    deleteOrganizationRequest,
    deleteOrganizationSuccess,
    deleteOrganizationFailure,

    getOrganizationByIdRequest,
    getOrganizationByIdSuccess,
    getOrganizationByIdFailure,

    resetOrganization,
} = organizationSlice.actions

export const getOrganizationListState = (store) =>
    store?.organization?.organizationList;
export const getOrganizationCreateState = (store) =>
    store?.organization?.createOrganization;
export const getOrganizationUpdateState = (store) =>
    store?.organization?.updateOrganization;
export const getOrganizationDeleteState = (store) =>
    store?.organization?.deleteOrganization;
export const getOrganizationGetByIdState = (store) =>
    store?.organization?.getOrganizationById;
export default organizationSlice.reducer;