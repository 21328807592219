import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestmanageRoleList(params) {
  return fetch.get(`${BASE_URL}/api/app/role`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}
export function requestCreateRole(data) {
  return fetch.post(`${BASE_URL}/api/app/role`, data);
}
export function requestupdateRole(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/role/${data._id}`, body);
}
export function requestGetByIdmanageRole(data) {
  return fetch.get(`${BASE_URL}/api/app/role/getById/${data.id}`);
}

export function requestAddFeatureRole(data) {
  const body = { ...data };

  return fetch.post(`${BASE_URL}/api/app/role/update-role/${body.id}`, body);
}

export function requestDeleteRole(data) {
  return fetch.delete(`${BASE_URL}/api/app/role/${data}`);
}
