import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    unitList: { loading: false, data: null, error: null },
    createUnit: { loading: false, data: null, error: null },
    updateUnit: { loading: false, data: null, error: null },
    deleteUnit: { loading: false, data: null, error: null },
    getUnitById: { loading: false, data: null, error: null }
}

export const unitSlice = createSlice({
    name: "unit",
    initialState,
    reducers: {
        fetchUnitListRequest: (unit, action) => {
            unit.unitList.loading = true;
            unit.unitList.error = null;
        },
        fetchUnitListSuccess: (unit, action) => {
            unit.unitList.data = action.payload;
            unit.unitList.loading = false;
            unit.unitList.error = null;
        },
        fetchUnitListFailure: (unit, action) => {
            unit.unitList.data = null;
            unit.unitList.loading = false;
            unit.unitList.error = action.payload;
        },

        //create unit
        createUnitRequest: (unit, action) => {
            unit.createUnit.loading = true;
            unit.createUnit.error = null;
        },
        createUnitSuccess: (unit, action) => {
            unit.createUnit.data = action.payload;
            unit.createUnit.loading = false;
            unit.createUnit.error = null;
        },
        createUnitFailure: (unit, action) => {
            unit.createUnit.data = null;
            unit.createUnit.loading = false;
            unit.createUnit.error = action.payload;
        },

        //update unit details
        updateUnitRequest: (unit, action) => {
            unit.updateUnit.loading = true;
            unit.updateUnit.error = null;
        },
        updateUnitSuccess: (unit, action) => {
            unit.updateUnit.data = action.payload;
            unit.updateUnit.loading = false;
            unit.updateUnit.error = null;
        },
        updateUnitFailure: (unit, action) => {
            unit.updateUnit.data = null;
            unit.updateUnit.loading = false;
            unit.updateUnit.error = action.payload;
        },

        // delete unit
        deleteUnitRequest: (unit, action) => {
            unit.deleteUnit.loading = true;
            unit.deleteUnit.error = null;
        },
        deleteUnitSuccess: (unit, action) => {
            unit.deleteUnit.data = action.payload;
            unit.deleteUnit.loading = false;
            unit.deleteUnit.error = null;
            unit.unitList = deleteNewUnitSuccess(action.payload, {
                ...unit.unitList,
            })
        },
        deleteUnitFailure: (unit, action) => {
            unit.deleteUnit.data = null;
            unit.deleteUnit.loading = false;
            unit.deleteUnit.error = action.payload;
        },

        // getById unit details
        getUnitByIdRequest: (unit, action) => {
            unit.getUnitById.loading = true;
            unit.getUnitById.error = null;
        },
        getUnitByIdSuccess: (unit, action) => {
            unit.getUnitById.data = action.payload;
            unit.getUnitById.loading = false;
            unit.getUnitById.error = null;
        },
        getUnitByIdFailure: (unit, action) => {
            unit.getUnitById.data = null;
            unit.getUnitById.loading = false;
            unit.getUnitById.error = action.payload;
        },

        // reset unit details
        resetUnit: (unit, action) => {
            unit.createUnit.data = null;
            unit.createUnit.loading = false;
            unit.createUnit.error = null;

            unit.updateUnit.data = null;
            unit.updateUnit.loading = false;
            unit.updateUnit.error = null;

            unit.deleteUnit.data = null;
            unit.deleteUnit.loading = false;
            unit.deleteUnit.error = null;
        }
    }
})

function updateNewUnitSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewUnitSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchUnitListRequest,
    fetchUnitListSuccess,
    fetchUnitListFailure,

    createUnitRequest,
    createUnitSuccess,
    createUnitFailure,

    updateUnitRequest,
    updateUnitSuccess,
    updateUnitFailure,

    deleteUnitRequest,
    deleteUnitSuccess,
    deleteUnitFailure,

    getUnitByIdRequest,
    getUnitByIdSuccess,
    getUnitByIdFailure,

    resetUnit,
} = unitSlice.actions;

export const getUnitListState = (store) =>
    store?.unit?.unitList;
export const getUnitCreateState = (store) =>
    store?.unit?.createUnit;
export const getUnitUpdateState = (store) =>
    store?.unit?.updateUnit;
export const getUnitDeleteState = (store) =>
    store?.unit?.deleteUnit;
export const getUnitGetByIdState = (store) =>
    store?.unit?.getUnitById;
export default unitSlice.reducer;