import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  systemTicketList: { loading: false, data: null, error: null },
  updateSystemTicket: { loading: false, data: null, error: null },
  updateAcknowledgeSystemTicket: { loading: false, data: null, error: null },
};

export const systemticketSlice = createSlice({
  name: "systemticket",
  initialState,
  reducers: {
    fetchsystemTicketListRequest: (systemticket, action) => {
      systemticket.systemTicketList.loading = true;
      systemticket.systemTicketList.error = null;
    },
    fetchsystemTicketListSuccess: (systemticket, action) => {
      systemticket.systemTicketList.data = action.payload;
      systemticket.systemTicketList.loading = false;
      systemticket.systemTicketList.error = null;
    },
    fetchsystemTicketListFailure: (systemticket, action) => {
      systemticket.systemTicketList.data = null;
      systemticket.systemTicketList.loading = false;
      systemticket.systemTicketList.error = action.payload;
    },
    updatesystemTicketRequest: (systemticket, action) => {
      systemticket.updateSystemTicket.loading = true;
      systemticket.updateSystemTicket.error = null;
    },
    updatesystemTicketSuccess: (systemticket, action) => {
      systemticket.updateSystemTicket.data = action.payload;
      systemticket.updateSystemTicket.loading = false;
      systemticket.updateSystemTicket.error = null;
      systemticket.systemTicketList = updatesystemTicketListSuccess(action.payload, {
        ...systemticket.systemTicketList,
      });
    },
    updatesystemTicketFailure: (systemticket, action) => {
      systemticket.updateSystemTicket.data = null;
      systemticket.updateSystemTicket.loading = false;
      systemticket.updateSystemTicket.error = action.payload;
    },
    updateAcknowledgesystemTicketRequest: (systemticket, action) => {
      systemticket.updateAcknowledgeSystemTicket.loading = true;
      systemticket.updateAcknowledgeSystemTicket.error = null;
    },
    updateAcknowledgesystemTicketSuccess: (systemticket, action) => {
      systemticket.updateAcknowledgeSystemTicket.data = action.payload;
      systemticket.updateAcknowledgeSystemTicket.loading = false;
      systemticket.updateAcknowledgeSystemTicket.error = null;
      systemticket.systemTicketList = updateAcknowledgesystemTicketListSuccess(action.payload, {
        ...systemticket.systemTicketList,
      });
    },
    updateAcknowledgesystemTicketFailure: (systemticket, action) => {
      systemticket.updateAcknowledgeSystemTicket.data = null;
      systemticket.updateAcknowledgeSystemTicket.loading = false;
      systemticket.updateAcknowledgeSystemTicket.error = action.payload;
    },
  },
});

function updatesystemTicketListSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });
  return state;
}

function updateAcknowledgesystemTicketListSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });
  return state;
}

export const {
  fetchsystemTicketListRequest,
  fetchsystemTicketListSuccess,
  fetchsystemTicketListFailure,

  updatesystemTicketRequest,
  updatesystemTicketSuccess,
  updatesystemTicketFailure,

  updateAcknowledgesystemTicketRequest,
  updateAcknowledgesystemTicketSuccess,
  updateAcknowledgesystemTicketFailure,
} = systemticketSlice.actions;

export const getsystemTicketListState = (store) =>
  store?.systemticket?.systemTicketList;
export const getsystemTicketUpdateState = (store) =>
 store?.systemticket?.updateSystemTicket;
 export const getsystemTicketAcknowledgeUpdateState = (store) =>
 store?.systemticket?.updateAcknowledgeSystemTicket;
export default systemticketSlice.reducer;