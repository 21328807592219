import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestCqrsDataList(params) {
  if (params === undefined) {
    return fetch.post(`${BASE_URL}/api/app/dashboard-charts/`);
  } else {
    return fetch.post(`${BASE_URL}/api/app/dashboard-charts/`, params);
  }
}