import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    expenseList: { loading: false, data: null, error: null },
    createExpense: { loading: false, data: null, error: null },
    updateExpense: { loading: false, data: null, error: null },
    deleteExpense: { loading: false, data: null, error: null },
    getExpenseById: { loading: false, data: null, error: null }
}

export const expenseSlice = createSlice({
    name: "expense",
    initialState,
    reducers: {
        fetchExpenseListRequest: (expense, action) => {
            expense.expenseList.loading = true;
            expense.expenseList.error = null;
        },
        fetchExpenseListSuccess: (expense, action) => {
            expense.expenseList.data = action.payload;
            expense.expenseList.loading = false;
            expense.expenseList.error = null;
        },
        fetchExpenseListFailure: (expense, action) => {
            expense.expenseList.data = null;
            expense.expenseList.loading = false;
            expense.expenseList.error = action.payload;
        },

        //create Expense
        createExpenseRequest: (expense, action) => {
            expense.createExpense.loading = true;
            expense.createExpense.error = null;
        },
        createExpenseSuccess: (expense, action) => {
            expense.createExpense.data = action.payload;
            expense.createExpense.loading = false;
            expense.createExpense.error = null;
            expense.expenseList = addNewExpenseSuccess(action.payload, {
                ...expense.expenseList,
            })
        },
        createExpenseFailure: (expense, action) => {
            expense.createExpense.data = null;
            expense.createExpense.loading = false;
            expense.createExpense.error = action.payload;
        },

        //update expense details
        updateExpenseRequest: (expense, action) => {
            expense.updateExpense.loading = true;
            expense.updateExpense.error = null;
        },
        updateExpenseSuccess: (expense, action) => {
            expense.updateExpense.data = action.payload;
            expense.updateExpense.loading = false;
            expense.updateExpense.error = null;
            expense.expenseList = updateNewExpenseSuccess(
                action.payload,
                expense.expenseList
            );
        },
        updateExpenseFailure: (expense, action) => {
            expense.updateExpense.data = null;
            expense.updateExpense.loading = false;
            expense.updateExpense.error = action.payload;
        },

        // delete expense
        deleteExpenseRequest: (expense, action) => {
            expense.deleteExpense.loading = true;
            expense.deleteExpense.error = null;
        },
        deleteExpenseSuccess: (expense, action) => {
            expense.deleteExpense.data = action.payload;
            expense.deleteExpense.loading = false;
            expense.deleteExpense.error = null;
            expense.expenseList = deleteNewExpenseSuccess(action.payload, {
                ...expense.expenseList,
            })
        },
        deleteExpenseFailure: (expense, action) => {
            expense.deleteExpense.data = null;
            expense.deleteExpense.loading = false;
            expense.deleteExpense.error = action.payload;
        },

        // getById expense details
        getExpenseByIdRequest: (expense, action) => {
            expense.getExpenseById.loading = true;
            expense.getExpenseById.error = null;
        },
        getExpenseByIdSuccess: (expense, action) => {
            expense.getExpenseById.data = action.payload;
            expense.getExpenseById.loading = false;
            expense.getExpenseById.error = null;
        },
        getExpenseByIdFailure: (expense, action) => {
            expense.getExpenseById.data = null;
            expense.getExpenseById.loading = false;
            expense.getExpenseById.error = action.payload;
        },

        // reset expense details
        resetExpense: (expense, action) => {
            expense.createExpense.data = null;
            expense.createExpense.loading = false;
            expense.createExpense.error = null;

            expense.updateExpense.data = null;
            expense.updateExpense.loading = false;
            expense.updateExpense.error = null;

            expense.deleteExpense.data = null;
            expense.deleteExpense.loading = false;
            expense.deleteExpense.error = null;
        }
    }
})

function addNewExpenseSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewExpenseSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewExpenseSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchExpenseListRequest,
    fetchExpenseListSuccess,
    fetchExpenseListFailure,

    createExpenseRequest,
    createExpenseSuccess,
    createExpenseFailure,

    updateExpenseRequest,
    updateExpenseSuccess,
    updateExpenseFailure,

    deleteExpenseRequest,
    deleteExpenseSuccess,
    deleteExpenseFailure,

    getExpenseByIdRequest,
    getExpenseByIdSuccess,
    getExpenseByIdFailure,

    resetExpense,
} = expenseSlice.actions;

export const getExpenseListState = (store) =>
    store?.expense?.expenseList;
export const getExpenseCreateState = (store) =>
    store?.expense?.createExpense;
export const getExpenseUpdateState = (store) =>
    store?.expense?.updateExpense;
export const getExpenseDeleteState = (store) =>
    store?.expense?.deleteExpense;
export const getExpenseGetByIdState = (store) =>
    store?.expense?.getExpenseById;
export default expenseSlice.reducer;