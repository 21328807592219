import { put, takeLatest } from "redux-saga/effects";

import {
  requestFeatureList,
  requestCreateFeature,
  requestUpdateFeature,
  requestDeleteFeature,
  requestFeatureDataById,
} from "../../../services/feature";
import {
  fetchFeatureListRequest,
  fetchFeatureListSuccess,
  fetchFeatureListFailure,
  createManageFeatureRequest,
  createManageFeatureSuccess,
  createManageFeatureFailure,
  updateManageFeatureRequest,
  updateManageFeatureSuccess,
  updateManageFeatureFailure,
  deleteManageFeatureRequest,
  deleteManageFeatureSuccess,
  deleteManageFeatureFailure,
  getFeatureDetailsRequest,
  getFeatureDetailsSuccess,
  getFeatureDetailsFailure,
} from "../../reducers/feature";

export function* watchFeatureSaga() {
  yield takeLatest(fetchFeatureListRequest, fetchFeatureList);
  yield takeLatest(createManageFeatureRequest, createFeature);
  yield takeLatest(updateManageFeatureRequest, updateFeature);
  yield takeLatest(deleteManageFeatureRequest, deleteFeature);
  yield takeLatest(getFeatureDetailsRequest, getFeatureGetByIdData);
}

function* fetchFeatureList({ payload }) {
  try {
    const response = yield requestFeatureList(payload);
    yield put(fetchFeatureListSuccess(response));
  } catch (error) {
    yield put(fetchFeatureListFailure("Failed to get list of Features"));
  }
}

function* createFeature({ payload }) {
  try {
    //service add
    const response = yield requestCreateFeature(payload);
    yield put(createManageFeatureSuccess(response.data));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to create Feature";
    if (error?.response?.data?.data?.code === 11000) {
      yield put(createManageFeatureFailure("Feature Code Already In use."));
    } else {
      yield put(createManageFeatureFailure(message));
    }
  }
}

function* updateFeature({ payload }) {
  try {
    //service update
    const response = yield requestUpdateFeature(payload);
    yield put(updateManageFeatureSuccess(response.data));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to update Feature";
    yield put(updateManageFeatureFailure(message));
  }
}

function* deleteFeature({ payload }) {
  try {
    const response = yield requestDeleteFeature(payload);
    yield put(deleteManageFeatureSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete Feature";
    yield put(deleteManageFeatureFailure(message));
  }
}

function* getFeatureGetByIdData({ payload }) {
  try {
    const response = yield requestFeatureDataById(payload);
    yield put(getFeatureDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get Feature details";
    yield put(getFeatureDetailsFailure(message));
  }
}
