import {
  FileDoneOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  DotChartOutlined,
  FundOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { Dashboard, Users } from "assets/svg/sideNavigation/navigationicon";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: Dashboard,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-user",
    path: `${APP_PREFIX_PATH}/manage-user`,
    title: "Manage User",
    icon: Users,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-role",
    path: `${APP_PREFIX_PATH}/manage-role`,
    title: "Manage Role",
    icon: Users,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-privilege",
    path: `${APP_PREFIX_PATH}/manage-privilege`,
    title: "Manage Privilege",
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "manage-feature",
    path: `${APP_PREFIX_PATH}/manage-feature`,
    title: "Manage Feature",
    icon: FileDoneOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sensor-product",
    path: `${APP_PREFIX_PATH}/sensor-product`,
    title: "Sensor Product",
    icon: ClusterOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sensor-instance",
    path: `${APP_PREFIX_PATH}/sensor-instance`,
    title: "Sensor Instance",
    icon: ClusterOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sensor-data",
    path: `${APP_PREFIX_PATH}/sensor-data`,
    title: "Sensor Data",
    icon: DatabaseOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "sensor-analytics",
    path: `${APP_PREFIX_PATH}/sensor-analytics`,
    title: "Sensor Analytics",
    icon: DotChartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "cqrs",
    path: `${APP_PREFIX_PATH}/cqrs`,
    title: "CQRS",
    icon: FundOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
