import { put, takeLatest } from "redux-saga/effects";
import {
    fetchSensorInstanceUnitListFailure,
    fetchSensorInstanceUnitListRequest,
    fetchSensorInstanceUnitListSuccess
} from "redux/reducers/sensorInstanceUnit";
import {
    requestSensorInstanceUnitList
} from "services/sensorInstanceUnit";

export function* watchSensorInstanceUnitSaga() {
    yield takeLatest(fetchSensorInstanceUnitListRequest, fetchSensorInstanceUnitList);
}

function* fetchSensorInstanceUnitList({ payload }) {
    try {
        const response = yield requestSensorInstanceUnitList(payload);
        yield put(fetchSensorInstanceUnitListSuccess(response));
    } catch (error) {
        yield put(
            fetchSensorInstanceUnitListFailure("Failed to get list of units")
        )
    }
}