import { put, takeLatest } from "redux-saga/effects";

import { requestCqrsDataList } from "../../../services/cqrsData";
import {
  fetchCqrsDataListRequest,
  fetchCqrsDataListSuccess,
  fetchCqrsDataListFailure,
} from "../../reducers/cqrsData";

export function* watchCqrsDataSaga() {
  yield takeLatest(fetchCqrsDataListRequest, fetchCqrsDataList);
}

/**
 *
 * @param {*} param0
 */
function* fetchCqrsDataList({ payload }) {
  try {
    const response = yield requestCqrsDataList(payload);
    yield put(fetchCqrsDataListSuccess(response));
  } catch (error) {
    yield put(fetchCqrsDataListFailure("Failed to get list of Cqrs"));
  }
}