//dispaly notification
import soundFile from "../assets/notification.mp3";
export function showNotification(e) {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    dispalyNotification(e);
    // …
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        dispalyNotification(e);
        // …
      } else {
        alert("Please Grant notification permission");
      }
      //dont do anything
    });
  }
}

function dispalyNotification({
  title,
  message,
  icon = "https://via.placeholder.com/50x50",
}) {
  new Notification(title, { body: message, icon: icon });
  new Audio(soundFile).play();
}
