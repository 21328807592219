import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestChecklistList(data) {
    return fetch.patch(`${BASE_URL}/api/app/question-answer/editChecklistQuestions`, data);
}

export function requestCreateChecklist(data) {
    return fetch.post(`${BASE_URL}/api/app/question-answer`, data)
}

export function requestUpdateChecklist(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/question-answer/${data._id}`, body);
}

export function requestDeleteChecklist(data) {
    return fetch.delete(`${BASE_URL}/api/app/question-answer/${data}`);
}

export function requestChecklistDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/question-answer/getById/${data._id}`);
}
