import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    fireExtinguisherList: { loading: false, data: null, error: null },
    createFireExtinguisher: { loading: false, data: null, error: null },
    updateFireExtinguisher: { loading: false, data: null, error: null },
    deleteFireExtinguisher: { loading: false, data: null, error: null },
    getFireExtinguisherById: { loading: false, data: null, error: null },
};

export const FireExtinguisherSlice = createSlice({
    name: "fireExtinguisher",
    initialState,
    reducers: {
        fetchFireExtinguisherListRequest: (fireExtinguisher, action) => {
            fireExtinguisher.fireExtinguisherList.loading = true;
            fireExtinguisher.fireExtinguisherList.error = null;
        },
        fetchFireExtinguisherListSuccess: (fireExtinguisher, action) => {
            fireExtinguisher.fireExtinguisherList.data = action.payload;
            fireExtinguisher.fireExtinguisherList.loading = false;
            fireExtinguisher.fireExtinguisherList.error = null;
        },
        fetchFireExtinguisherListFailure: (fireExtinguisher, action) => {
            fireExtinguisher.fireExtinguisherList.data = null;
            fireExtinguisher.fireExtinguisherList.loading = false;
            fireExtinguisher.fireExtinguisherList.error = action.payload;
        },

        // create fireExtinguisher
        createFireExtinguisherRequest: (fireExtinguisher, action) => {
            fireExtinguisher.createFireExtinguisher.loading = true;
            fireExtinguisher.createFireExtinguisher.error = null;
        },
        createFireExtinguisherSuccess: (fireExtinguisher, action) => {
            fireExtinguisher.createFireExtinguisher.data = action.payload;
            fireExtinguisher.createFireExtinguisher.loading = false;
            fireExtinguisher.createFireExtinguisher.error = null;
            fireExtinguisher.fireExtinguisherList = addNewExtinguisherSuccess(
                action.payload,
                {
                    ...fireExtinguisher.fireExtinguisherList,
                }
            );
        },
        createFireExtinguisherFailure: (fireExtinguisher, action) => {
            fireExtinguisher.createFireExtinguisher.data = null;
            fireExtinguisher.createFireExtinguisher.loading = false;
            fireExtinguisher.createFireExtinguisher.error = action.payload;
        },

        // update fireExtinguisher details
        updateFireExtinguisherRequest: (fireExtinguisher, action) => {
            fireExtinguisher.updateFireExtinguisher.loading = true;
            fireExtinguisher.updateFireExtinguisher.error = null;
        },
        updateFireExtinguisherSuccess: (fireExtinguisher, action) => {
            fireExtinguisher.updateFireExtinguisher.data = action.payload;
            fireExtinguisher.updateFireExtinguisher.loading = false;
            fireExtinguisher.updateFireExtinguisher.error = null;
            fireExtinguisher.fireExtinguisherList = updateExtinguisherSuccess(
                action.payload,
                fireExtinguisher.fireExtinguisherList
            );
        },
        updateFireExtinguisherFailure: (fireExtinguisher, action) => {
            fireExtinguisher.updateFireExtinguisher.data = null;
            fireExtinguisher.updateFireExtinguisher.loading = false;
            fireExtinguisher.updateFireExtinguisher.error = action.payload;
        },

        // delete fireExtinguisher
        deleteFireExtinguisherRequest: (fireExtinguisher, action) => {
            fireExtinguisher.deleteFireExtinguisher.loading = true;
            fireExtinguisher.deleteFireExtinguisher.error = null;
        },
        deleteFireExtinguisherSuccess: (fireExtinguisher, action) => {
            fireExtinguisher.deleteFireExtinguisher.loading = false;
            fireExtinguisher.deleteFireExtinguisher.error = null;
            fireExtinguisher.deleteFireExtinguisher.data = action.payload;
            fireExtinguisher.fireExtinguisherList = deleteExtinguisher(action.payload, {
                ...fireExtinguisher.fireExtinguisherList,
            });
        },
        deleteFireExtinguisherFailure: (fireExtinguisher, action) => {
            fireExtinguisher.deleteFireExtinguisher.data = null;
            fireExtinguisher.deleteFireExtinguisher.loading = false;
            fireExtinguisher.deleteFireExtinguisher.error = action.payload;
        },

        // get by id fireExtinguisher details
        getFireExtinguisherDetailsRequest: (fireExtinguisher, action) => {
            fireExtinguisher.getFireExtinguisherById.loading = true;
            fireExtinguisher.getFireExtinguisherById.error = null;
        },
        getFireExtinguisherDetailsSuccess: (fireExtinguisher, action) => {
            fireExtinguisher.getFireExtinguisherById.loading = false;
            fireExtinguisher.getFireExtinguisherById.error = null;
            fireExtinguisher.getFireExtinguisherById.data = action.payload;
        },
        getFireExtinguisherDetailsFailure: (fireExtinguisher, action) => {
            fireExtinguisher.getFireExtinguisherById.data = null;
            fireExtinguisher.getFireExtinguisherById.loading = false;
            fireExtinguisher.getFireExtinguisherById.error = action.payload;
        },

        //Reset fireExtinguisher state
        resetFireExtinguisher: (fireExtinguisher, action) => {
            fireExtinguisher.createFireExtinguisher.data = null;
            fireExtinguisher.createFireExtinguisher.loading = false;
            fireExtinguisher.createFireExtinguisher.error = null;

            fireExtinguisher.updateFireExtinguisher.data = null;
            fireExtinguisher.updateFireExtinguisher.loading = false;
            fireExtinguisher.updateFireExtinguisher.error = null;

            fireExtinguisher.deleteFireExtinguisher.data = null;
            fireExtinguisher.deleteFireExtinguisher.loading = false;
            fireExtinguisher.deleteFireExtinguisher.error = null;
        },
    },
});

// addNewExtinguisher function
function addNewExtinguisherSuccess(dataToUpdate, state) {
    state.data.data = [dataToUpdate, ...state.data.data];
    state.data.total++;
    return state;
}

// updateExtingusher function
function updateExtinguisherSuccess(dataToUpdate, state) {
    state.data.data = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });

    return state;
}

// deleteExtinguisher function
function deleteExtinguisher(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
    return state;
}

export const {
    fetchFireExtinguisherListRequest,
    fetchFireExtinguisherListSuccess,
    fetchFireExtinguisherListFailure,

    createFireExtinguisherRequest,
    createFireExtinguisherSuccess,
    createFireExtinguisherFailure,

    updateFireExtinguisherRequest,
    updateFireExtinguisherSuccess,
    updateFireExtinguisherFailure,

    deleteFireExtinguisherRequest,
    deleteFireExtinguisherSuccess,
    deleteFireExtinguisherFailure,

    getFireExtinguisherDetailsRequest,
    getFireExtinguisherDetailsSuccess,
    getFireExtinguisherDetailsFailure,

    resetFireExtinguisher,
} = FireExtinguisherSlice.actions;

export const getFireExtinguisherListState = (store) =>
    store?.fireExtinguisher?.fireExtinguisherList;
export const getFireExtinguisherCreateState = (store) =>
    store?.fireExtinguisher?.createFireExtinguisher;
export const getUpdateFireExtinguisherState = (store) =>
    store?.fireExtinguisher?.updateFireExtinguisher;
export const getFireExtinguisherDeleteState = (store) =>
    store?.fireExtinguisher?.deleteFireExtinguisher;
export const getFireExtinguisherGetByIdState = (store) =>
    store?.fireExtinguisher?.getFireExtinguisherById;
export default FireExtinguisherSlice.reducer;
