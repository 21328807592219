import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestUpdateFileUpload(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/svc-ticket/${data._id}`, body);
}

export function requestFileUploadDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/svc-ticket/getById/${data.id}`);
}


