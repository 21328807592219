import { put, takeLatest } from "redux-saga/effects";
import {
    fetchAssetListRequest,
    fetchAssetListSuccess,
    fetchAssetListFailure
} from "redux/reducers/asset"
import {
    requestAssetList
} from "services/asset"

export function* watchAssetSaga() {
    yield takeLatest(fetchAssetListRequest, fetchAssetList);
}

function* fetchAssetList({ payload }) {
    try {
        const response = yield requestAssetList(payload);
        yield put(fetchAssetListSuccess(response));
    } catch (error) {
        yield put(
            fetchAssetListFailure("Failed to get list of asset")
        )
    }
}