import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSensorInstanceList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/sensor-instance`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/sensor-instance?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateSensorInstance(data) {
  return fetch.post(`${BASE_URL}/api/app/sensor-instance`, data);
}

export function requestUpdateSensorInstance(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/sensor-instance/${data._id}`, body);
}

export function requestDeleteSensorInstance(data) {
  return fetch.delete(`${BASE_URL}/api/app/sensor-instance/${data}`);
}

export function requestSensorInstanceDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/sensor-instance/getById/${data.id}`);
}

// IMEI No dropdown list
export function requestIMEINoDropDownList(params) {
  return fetch.get(`${BASE_URL}/api/app/sensor-instance/getImeiNo`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}
