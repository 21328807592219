import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestTicketList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/ticket`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/ticket?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateTicket(data) {
    return fetch.post(`${BASE_URL}/api/app/ticket`, data)
}

export function requestUpdateTicket(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/ticket/${data._id}`, body);
}

export function requestDeleteTicket(data) {
    return fetch.delete(`${BASE_URL}/api/app/ticket/${data}`);
}

export function requestTicketDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/ticket/getById/${data._id}`);
}