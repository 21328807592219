import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sensorInstanceUnitList: { loading: false, data: null, error: null }
}

export const sensorInstanceUnitSlice = createSlice({
    name: "sensorInstanceUnit",
    initialState,
    reducers: {
        fetchSensorInstanceUnitListRequest: (sensorInstanceUnit, action) => {
            sensorInstanceUnit.sensorInstanceUnitList.loading = true;
            sensorInstanceUnit.sensorInstanceUnitList.error = null;
        },
        fetchSensorInstanceUnitListSuccess: (sensorInstanceUnit, action) => {
            sensorInstanceUnit.sensorInstanceUnitList.data = action.payload;
            sensorInstanceUnit.sensorInstanceUnitList.loading = false;
            sensorInstanceUnit.sensorInstanceUnitList.error = null
        },
        fetchSensorInstanceUnitListFailure: (sensorInstanceUnit, action) => {
            sensorInstanceUnit.sensorInstanceUnitList.data = null;
            sensorInstanceUnit.sensorInstanceUnitList.loading = false;
            sensorInstanceUnit.sensorInstanceUnitList.error = action.payload;
        }
    }
})

export const {
    fetchSensorInstanceUnitListRequest,
    fetchSensorInstanceUnitListSuccess,
    fetchSensorInstanceUnitListFailure
} = sensorInstanceUnitSlice.actions

export const getSensorInstanceUnitListState = (store) =>
    store?.sensorInstanceUnit?.sensorInstanceUnitList;
export default sensorInstanceUnitSlice.reducer;