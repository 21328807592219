import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  svcMachineTypeList: { loading: false, data: null, error: null },
  createSvcMachineType: { loading: false, data: null, error: null },
  updateSvcMachineType: { loading: false, data: null, error: null },
  deleteSvcMachineType: { loading: false, data: null, error: null },
  getSvcMachineTypeById: { loading: false, data: null, error: null },
  getManufacturerList: { loading: false, data: null, error: null },
};

export const SvcMachineTypeSlice = createSlice({
  name: "svcMachineType",
  initialState,
  reducers: {
    fetchSvcMachineTypeListRequest: (svcMachineType, action) => {
      svcMachineType.svcMachineTypeList.loading = true;
      svcMachineType.svcMachineTypeList.error = null;
    },
    fetchSvcMachineTypeListSuccess: (svcMachineType, action) => {
      svcMachineType.svcMachineTypeList.data = action.payload;
      svcMachineType.svcMachineTypeList.loading = false;
      svcMachineType.svcMachineTypeList.error = null;
    },
    fetchSvcMachineTypeListFailure: (svcMachineType, action) => {
      svcMachineType.svcMachineTypeList.data = null;
      svcMachineType.svcMachineTypeList.loading = false;
      svcMachineType.svcMachineTypeList.error = action.payload;
    },

    // create svcMachineType
    createSvcMachineTypeRequest: (svcMachineType, action) => {
      svcMachineType.createSvcMachineType.loading = true;
      svcMachineType.createSvcMachineType.error = null;
    },
    createSvcMachineTypeSuccess: (svcMachineType, action) => {
      svcMachineType.createSvcMachineType.data = action.payload;
      svcMachineType.createSvcMachineType.loading = false;
      svcMachineType.createSvcMachineType.error = null;
      svcMachineType.svcMachineTypeList = addNewSvcMachineTypeSuccess(
        action.payload,
        {
          ...svcMachineType.svcMachineTypeList,
        }
      );
    },
    createSvcMachineTypeFailure: (svcMachineType, action) => {
      svcMachineType.createSvcMachineType.data = null;
      svcMachineType.createSvcMachineType.loading = false;
      svcMachineType.createSvcMachineType.error = action.payload;
    },

    // update svcMachineType details
    updateSvcMachineTypeRequest: (svcMachineType, action) => {
      svcMachineType.updateSvcMachineType.loading = true;
      svcMachineType.updateSvcMachineType.error = null;
    },
    updateSvcMachineTypeSuccess: (svcMachineType, action) => {
      svcMachineType.updateSvcMachineType.data = action.payload;
      svcMachineType.updateSvcMachineType.loading = false;
      svcMachineType.updateSvcMachineType.error = null;
      svcMachineType.svcMachineTypeList = updateFunctionSvcMachineTypeSuccess(
        action.payload,
        svcMachineType.svcMachineTypeList
      );
    },
    updateSvcMachineTypeFailure: (svcMachineType, action) => {
      svcMachineType.updateSvcMachineType.data = null;
      svcMachineType.updateSvcMachineType.loading = false;
      svcMachineType.updateSvcMachineType.error = action.payload;
    },

    // delete svcMachineType
    deleteSvcMachineTypeRequest: (svcMachineType, action) => {
      svcMachineType.deleteSvcMachineType.loading = true;
      svcMachineType.deleteSvcMachineType.error = null;
    },
    deleteSvcMachineTypeSuccess: (svcMachineType, action) => {
      svcMachineType.deleteSvcMachineType.loading = false;
      svcMachineType.deleteSvcMachineType.error = null;
      svcMachineType.deleteSvcMachineType.data = action.payload;
      svcMachineType.svcMachineTypeList = deleteSvcMachineType(action.payload, {
        ...svcMachineType.svcMachineTypeList,
      });
    },
    deleteSvcMachineTypeFailure: (svcMachineType, action) => {
      svcMachineType.deleteSvcMachineType.data = null;
      svcMachineType.deleteSvcMachineType.loading = false;
      svcMachineType.deleteSvcMachineType.error = action.payload;
    },

    // get by id svcMachineType details
    getSvcMachineTypeDetailsRequest: (svcMachineType, action) => {
      svcMachineType.getSvcMachineTypeById.loading = true;
      svcMachineType.getSvcMachineTypeById.error = null;
    },
    getSvcMachineTypeDetailsSuccess: (svcMachineType, action) => {
      svcMachineType.getSvcMachineTypeById.loading = false;
      svcMachineType.getSvcMachineTypeById.error = null;
      svcMachineType.getSvcMachineTypeById.data = action.payload;
    },
    getSvcMachineTypeDetailsFailure: (svcMachineType, action) => {
      svcMachineType.getSvcMachineTypeById.data = null;
      svcMachineType.getSvcMachineTypeById.loading = false;
      svcMachineType.getSvcMachineTypeById.error = action.payload;
    },

    fetchManufacturerListRequest: (state, action) => {
      state.getManufacturerList.loading = true;
      state.getManufacturerList.error = null;
    },
    fetchManufacturerListSuccess: (state, action) => {
      state.getManufacturerList.data = action.payload;
      state.getManufacturerList.loading = false;
      state.getManufacturerList.error = null;
    },
    fetchManufacturerListFailure: (state, action) => {
      state.getManufacturerList.data = null;
      state.getManufacturerList.loading = false;
      state.getManufacturerList.error = action.payload;
    },

    //Reset svcMachineType state
    resetSvcMachineType: (svcMachineType, action) => {
      svcMachineType.createSvcMachineType.data = null;
      svcMachineType.createSvcMachineType.loading = false;
      svcMachineType.createSvcMachineType.error = null;

      svcMachineType.updateSvcMachineType.data = null;
      svcMachineType.updateSvcMachineType.loading = false;
      svcMachineType.updateSvcMachineType.error = null;

      svcMachineType.deleteSvcMachineType.data = null;
      svcMachineType.deleteSvcMachineType.loading = false;
      svcMachineType.deleteSvcMachineType.error = null;
    },
  },
});

// addNewSvcMachineType function
function addNewSvcMachineTypeSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// updateSvcMachineType function
function updateFunctionSvcMachineTypeSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deleteSvcMachineType function
function deleteSvcMachineType(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchSvcMachineTypeListRequest,
  fetchSvcMachineTypeListSuccess,
  fetchSvcMachineTypeListFailure,

  createSvcMachineTypeRequest,
  createSvcMachineTypeSuccess,
  createSvcMachineTypeFailure,

  updateSvcMachineTypeRequest,
  updateSvcMachineTypeSuccess,
  updateSvcMachineTypeFailure,

  deleteSvcMachineTypeRequest,
  deleteSvcMachineTypeSuccess,
  deleteSvcMachineTypeFailure,

  getSvcMachineTypeDetailsRequest,
  getSvcMachineTypeDetailsSuccess,
  getSvcMachineTypeDetailsFailure,

  fetchManufacturerListRequest,
  fetchManufacturerListSuccess,
  fetchManufacturerListFailure,

  resetSvcMachineType,
} = SvcMachineTypeSlice.actions;

export const getSvcMachineTypeListState = (store) =>
  store?.svcMachineType?.svcMachineTypeList;
export const getSvcMachineTypeCreateState = (store) =>
  store?.svcMachineType?.createSvcMachineType;
export const getUpdateSvcMachineTypeState = (store) =>
  store?.svcMachineType?.updateSvcMachineType;
export const getSvcMachineTypeDeleteState = (store) =>
  store?.svcMachineType?.deleteSvcMachineType;
export const getSvcMachineTypeGetByIdState = (store) =>
  store?.svcMachineType?.getSvcMachineTypeById;
export const getManufacturerListState = (store) =>
  store?.svcMachineType?.getManufacturerList;
export default SvcMachineTypeSlice.reducer;
