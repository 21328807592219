import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sensorInstanceList: { loading: false, data: null, error: null },
  createSensorInstance: { loading: false, data: null, error: null },
  updateSensorInstance: { loading: false, data: null, error: null },
  deleteSensorInstance: { loading: false, data: null, error: null },
  getSensorInstanceById: { loading: false, data: null, error: null },
  getIMEINoList: { loading: false, data: null, error: null },
};

export const SensorInstanceSlice = createSlice({
  name: "sensorInstance",
  initialState,
  reducers: {
    fetchSensorInstanceListRequest: (sensorInstance, action) => {
      sensorInstance.sensorInstanceList.loading = true;
      sensorInstance.sensorInstanceList.error = null;
    },
    fetchSensorInstanceListSuccess: (sensorInstance, action) => {
      sensorInstance.sensorInstanceList.data = action.payload;
      sensorInstance.sensorInstanceList.loading = false;
      sensorInstance.sensorInstanceList.error = null;
    },
    fetchSensorInstanceListFailure: (sensorInstance, action) => {
      sensorInstance.sensorInstanceList.data = null;
      sensorInstance.sensorInstanceList.loading = false;
      sensorInstance.sensorInstanceList.error = action.payload;
    },

    // create sensorInstance
    createSensorInstanceRequest: (sensorInstance, action) => {
      sensorInstance.createSensorInstance.loading = true;
      sensorInstance.createSensorInstance.error = null;
    },
    createSensorInstanceSuccess: (sensorInstance, action) => {
      sensorInstance.createSensorInstance.data = action.payload;
      sensorInstance.createSensorInstance.loading = false;
      sensorInstance.createSensorInstance.error = null;
      sensorInstance.sensorInstanceList = addNewInstanceSuccess(
        action.payload,
        {
          ...sensorInstance.sensorInstanceList,
        }
      );
    },
    createSensorInstanceFailure: (sensorInstance, action) => {
      sensorInstance.createSensorInstance.data = null;
      sensorInstance.createSensorInstance.loading = false;
      sensorInstance.createSensorInstance.error = action.payload;
    },

    // update sensorInstance details
    updateSensorInstanceRequest: (sensorInstance, action) => {
      sensorInstance.updateSensorInstance.loading = true;
      sensorInstance.updateSensorInstance.error = null;
    },
    updateSensorInstanceSuccess: (sensorInstance, action) => {
      sensorInstance.updateSensorInstance.data = action.payload;
      sensorInstance.updateSensorInstance.loading = false;
      sensorInstance.updateSensorInstance.error = null;
      sensorInstance.sensorInstanceList = updateInstanceSuccess(
        action.payload,
        sensorInstance.sensorInstanceList
      );
    },
    updateSensorInstanceFailure: (sensorInstance, action) => {
      sensorInstance.updateSensorInstance.data = null;
      sensorInstance.updateSensorInstance.loading = false;
      sensorInstance.updateSensorInstance.error = action.payload;
    },

    // delete sensorInstance
    deleteSensorInstanceRequest: (sensorInstance, action) => {
      sensorInstance.deleteSensorInstance.loading = true;
      sensorInstance.deleteSensorInstance.error = null;
    },
    deleteSensorInstanceSuccess: (sensorInstance, action) => {
      sensorInstance.deleteSensorInstance.loading = false;
      sensorInstance.deleteSensorInstance.error = null;
      sensorInstance.deleteSensorInstance.data = action.payload;
      sensorInstance.sensorInstanceList = deleteInstance(action.payload, {
        ...sensorInstance.sensorInstanceList,
      });
    },
    deleteSensorInstanceFailure: (sensorInstance, action) => {
      sensorInstance.deleteSensorInstance.data = null;
      sensorInstance.deleteSensorInstance.loading = false;
      sensorInstance.deleteSensorInstance.error = action.payload;
    },

    // get by id sensorInstance details
    getSensorInstanceDetailsRequest: (sensorInstance, action) => {
      sensorInstance.getSensorInstanceById.loading = true;
      sensorInstance.getSensorInstanceById.error = null;
    },
    getSensorInstanceDetailsSuccess: (sensorInstance, action) => {
      sensorInstance.getSensorInstanceById.loading = false;
      sensorInstance.getSensorInstanceById.error = null;
      sensorInstance.getSensorInstanceById.data = action.payload;
    },
    getSensorInstanceDetailsFailure: (sensorInstance, action) => {
      sensorInstance.getSensorInstanceById.data = null;
      sensorInstance.getSensorInstanceById.loading = false;
      sensorInstance.getSensorInstanceById.error = action.payload;
    },

    fetchIMEINoListRequest: (state, action) => {
      state.getIMEINoList.loading = true;
      state.getIMEINoList.error = null;
    },
    fetchIMEINoListSuccess: (state, action) => {
      state.getIMEINoList.data = action.payload;
      state.getIMEINoList.loading = false;
      state.getIMEINoList.error = null;
    },
    fetchIMEINoListFailure: (state, action) => {
      state.getIMEINoList.data = null;
      state.getIMEINoList.loading = false;
      state.getIMEINoList.error = action.payload;
    },

    //Reset sensorInstance state
    resetSensorInstance: (sensorInstance, action) => {
      sensorInstance.createSensorInstance.data = null;
      sensorInstance.createSensorInstance.loading = false;
      sensorInstance.createSensorInstance.error = null;

      sensorInstance.updateSensorInstance.data = null;
      sensorInstance.updateSensorInstance.loading = false;
      sensorInstance.updateSensorInstance.error = null;

      sensorInstance.deleteSensorInstance.data = null;
      sensorInstance.deleteSensorInstance.loading = false;
      sensorInstance.deleteSensorInstance.error = null;
    },
  },
});

// addNewPrivilege function
function addNewInstanceSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// updatePrivilege function
function updateInstanceSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deletePrivilege function
function deleteInstance(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchSensorInstanceListRequest,
  fetchSensorInstanceListSuccess,
  fetchSensorInstanceListFailure,

  createSensorInstanceRequest,
  createSensorInstanceSuccess,
  createSensorInstanceFailure,

  updateSensorInstanceRequest,
  updateSensorInstanceSuccess,
  updateSensorInstanceFailure,

  deleteSensorInstanceRequest,
  deleteSensorInstanceSuccess,
  deleteSensorInstanceFailure,

  getSensorInstanceDetailsRequest,
  getSensorInstanceDetailsSuccess,
  getSensorInstanceDetailsFailure,

  fetchIMEINoListRequest,
  fetchIMEINoListSuccess,
  fetchIMEINoListFailure,

  resetSensorInstance,
} = SensorInstanceSlice.actions;

export const getSensorInstanceListState = (store) =>
  store?.sensorInstance?.sensorInstanceList;
export const getSensorInstanceCreateState = (store) =>
  store?.sensorInstance?.createSensorInstance;
export const getUpdateSensorInstanceState = (store) =>
  store?.sensorInstance?.updateSensorInstance;
export const getSensorInstanceDeleteState = (store) =>
  store?.sensorInstance?.deleteSensorInstance;
export const getSensorInstanceGetByIdState = (store) =>
  store?.sensorInstance?.getSensorInstanceById;
export const getIMEINoListState = (store) =>
  store?.sensorInstance?.getIMEINoList;
export default SensorInstanceSlice.reducer;
