const dev = {
   API_ENDPOINT_URL: "https://mon.firetweet.in:1000",
	// API_ENDPOINT_URL: "http://localhost:4000",
};

const prod = {
  API_ENDPOINT_URL: "https://mon.firetweet.in:1000",
};

const test = {
  API_ENDPOINT_URL: "http://localhost:4000",
};

const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();

// API Key : replace YOUR_API_KEY with your API key (Swarada_Jetatech API Key: AIzaSyCPya3iPsduXbD0AmBEdKuZwt9KF4y2TfQ)
// Samir sir Key : AIzaSyBtUra3vnXi1LceIAqUFhwzI7f2IyR_IbA
export const googleMapURL = "https://maps.googleapis.com/maps/api/js?key=AIzaSyCPya3iPsduXbD0AmBEdKuZwt9KF4y2TfQ&v=3.exp&libraries=geometry,drawing,places"
