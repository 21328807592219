import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import {
  PlusCircleOutlined,
  FileDoneOutlined,
  ClusterOutlined,
  DatabaseOutlined,
  DotChartOutlined,
  FundOutlined,
  FireOutlined,
  UnorderedListOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import { Dashboard, Users } from "assets/svg/sideNavigation/navigationicon";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

function FetchIconByKey(key) {
  switch (key) {
    case "dashboard":
      return Dashboard;
    case "users":
      return Users;
    case "featurePrivilege":
      return FileDoneOutlined;
    case "sensorProductInstance":
      return ClusterOutlined;
    case "sensorData":
      return DatabaseOutlined;
    case "sensorAnalytics":
      return DotChartOutlined;
    case "sensorCqrs":
      return FundOutlined;
    case "FireOutlined":
      return FireOutlined;
    case "UnorderedListOutlined":
      return UnorderedListOutlined;
    case "CustomerServiceOutlined":
      return CustomerServiceOutlined;
    default:
      return PlusCircleOutlined;
  }
}

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;

  /**
   * @type [Array<SideMenuItem>]
   */
  const [sideMenuItems, setSideMenuItems] = useState([]);

  useEffect(() => {
    /**
     * @type Array<SideMenuItem>
     */
    let list = [...(props?.newSideMenuItems || [])]
      .filter((item) => {
        if (!item?.submenu?.length && !item.path?.trim()) return false;
        return true;
      })
      .sort((a, b) => a.order - b.order);
    const obj = [
      {
        submenu: list,
        key: "extra",
        breadcrumb: false,
        icon: "dashboard",
        path: navigationConfig[0].path,
        name: "",
      },
    ];
    setSideMenuItems(obj);
  }, [props]);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {sideMenuItems.map((menu) =>
        menu.submenu?.length > 0 ? (
          <Menu.ItemGroup key={menu.key || menu.code}>
            {menu?.submenu?.map((subMenuFirst) =>
              subMenuFirst.submenu?.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={FetchIconByKey(subMenuFirst.icon)} />
                    ) : null
                  }
                  key={subMenuFirst.key || subMenuFirst.code}
                  title={setLocale(localization, subMenuFirst.name)}
                >
                  {subMenuFirst?.submenu?.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key || subMenuSecond.code}>
                      {subMenuSecond.icon ? (
                        <Icon type={FetchIconByKey(subMenuFirst.icon)} />
                      ) : null}
                      <span>{setLocale(localization, subMenuSecond.name)}</span>
                      <Link
                        onClick={() => closeMobileNav()}
                        to={subMenuSecond.path || "/app/home"}
                      />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key || subMenuFirst.code}>
                  {subMenuFirst.icon ? (
                    <Icon type={FetchIconByKey(subMenuFirst.icon)} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.name)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path || "/app/home"}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key || menu.code}>
            {menu.icon ? <Icon type={FetchIconByKey(menu.icon)} /> : null}
            <span>{menu?.name}</span>
            {menu.path ? (
              <Link
                onClick={() => closeMobileNav()}
                to={menu.path || "/app/home"}
              />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
