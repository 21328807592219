import { put, takeLatest } from "redux-saga/effects";
import {
    fetchChecklistListRequest,
    fetchChecklistListSuccess,
    fetchChecklistListFailure,
    createChecklistRequest,
    createChecklistSuccess,
    createChecklistFailure,
    updateChecklistRequest,
    updateChecklistSuccess,
    updateChecklistFailure,
    deleteChecklistRequest,
    deleteChecklistSuccess,
    deleteChecklistFailure,
    getChecklistByIdRequest,
    getChecklistByIdSuccess,
    getChecklistByIdFailure,
} from "redux/reducers/checklist";

import {
    requestChecklistList,
    requestCreateChecklist,
    requestUpdateChecklist,
    requestDeleteChecklist,
    requestChecklistDataById,
} from "services/checklist"

export function* watchChecklistSaga() {
    yield takeLatest(fetchChecklistListRequest, fetchChecklistList);
    yield takeLatest(createChecklistRequest, createChecklist);
    yield takeLatest(updateChecklistRequest, updateChecklist);
    yield takeLatest(deleteChecklistRequest, deleteChecklist);
    yield takeLatest(getChecklistByIdRequest, getChecklistById);
}

function* fetchChecklistList({ payload }) {
    try {
        const response = yield requestChecklistList(payload);
        yield put(fetchChecklistListSuccess(response));
    } catch (error) {
        yield put(
            fetchChecklistListFailure("Failed to get list of checlist")
        )

    }
}


function* createChecklist({ payload }) {
    try {
        // service add
        const response = yield requestCreateChecklist(payload);
        yield put(createChecklistSuccess(response.data));
    } catch (error) {
        yield put(createChecklistFailure("Failed to create checklist"));
    }
}

function* updateChecklist({ payload }) {
    try {
        const response = yield requestUpdateChecklist(payload);
        yield put(updateChecklistSuccess(response.data));
    } catch (error) {
        yield put(updateChecklistFailure("Failed to update checklist"));
    }
}

function* deleteChecklist({ payload }) {
    try {
        const response = yield requestDeleteChecklist(payload);
        yield put(deleteChecklistSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete checklist";
        yield put(deleteChecklistFailure(message));
    }
}

function* getChecklistById({ payload }) {
    try {
        const response = yield requestChecklistDataById(payload);
        yield put(getChecklistByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get checklist details";
        yield put(getChecklistByIdFailure(message));
    }
}