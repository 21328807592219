import React, { lazy, Suspense, useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { getPermissionListState } from "redux/reducers/permissions";
import ErrorBoundary from "components/shared-components/ErrorBoundary";

export const AppViews = () => {
  const [permission, setPermissions] = useState(
    JSON.parse(localStorage.getItem("permissions"))
  );
  const data = useSelector(getPermissionListState);
  useEffect(() => {
    setPermissions(JSON.parse(localStorage.getItem("permissions")));
  }, [data]);
  let checkPermissions = (checkValue) => {
    if (permission?.some((e) => e.code === checkValue)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading cover="content" />}>
        <Switch>
          <Route
            path={`${APP_PREFIX_PATH}/home`}
            component={lazy(() => import(`./dashboard`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/device-location-map`}
            component={lazy(() => import(`./location`))}
          />

          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-user`}
            component={lazy(() => import(`./manage-user`))}
            hasAccesssToRoute={checkPermissions("MANAGE_USER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-role`}
            component={lazy(() => import(`./manage-role`))}
            hasAccesssToRoute={checkPermissions("MANAGE_ROLE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-feature`}
            component={lazy(() => import(`./manage-feature`))}
            hasAccesssToRoute={checkPermissions("MANAGE_FEATURE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-privilege`}
            component={lazy(() => import(`./manage-privilege`))}
            hasAccesssToRoute={checkPermissions("MANAGE_PRIVILEGES")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/sensor-product`}
            component={lazy(() => import(`./sensor-product`))}
            hasAccesssToRoute={checkPermissions("SENSOR_PRODUCT")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/sensor-instance`}
            component={lazy(() => import(`./sensor-instance`))}
            hasAccesssToRoute={checkPermissions("SENSOR_INSTANCE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/sensor-data`}
            component={lazy(() => import(`./sensor-data`))}
            hasAccesssToRoute={checkPermissions("SENSOR_DATA")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/sensor-analytics`}
            component={lazy(() => import(`./sensor-analytics`))}
            hasAccesssToRoute={checkPermissions("SENSOR_ANALYTICS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/cqrs`}
            component={lazy(() => import(`./cqrs`))}
            hasAccesssToRoute={checkPermissions("CQRS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/feactivity`}
            component={lazy(() => import(`./feactivity`))}
            hasAccesssToRoute={checkPermissions("FE_ACTIVITY")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket`}
            component={lazy(() => import(`./ticket`))}
            hasAccesssToRoute={checkPermissions("TICKET_LIST")}
          ></GuardedRoute>
          <Route
            path={`${APP_PREFIX_PATH}/notifications`}
            component={lazy(() => import(`./notifications`))}
          />
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/service-provider`}
            component={lazy(() => import(`./service-provider`))}
            hasAccesssToRoute={checkPermissions("SERVICE_PROVIDER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-subscriber`}
            component={lazy(() => import(`./manage-subscriber`))}
            hasAccesssToRoute={
              localStorage.getItem("role") !== "USER" &&
              checkPermissions("MANAGE_SUBSCRIBER")
            }
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/site-manage`}
            component={lazy(() => import(`./site-manage`))}
            hasAccesssToRoute={checkPermissions("SITE_MANAGE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/extinguisher-list`}
            component={lazy(() => import(`./extinguisher-list`))}
            hasAccesssToRoute={checkPermissions("FIRE_EXTINGUISHER")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/system-ticket`}
            component={lazy(() => import(`./system-ticket`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dashboard`}
            component={lazy(() => import(`./dashboard`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/sign`}
            component={lazy(() => import(`./sign`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/dynamic-dashboard`}
            component={lazy(() => import(`./dynamic-board`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/serve-dashboard`}
            component={lazy(() => import(`./serve-dashboard`))}
            hasAccesssToRoute={checkPermissions("SERVE_DASHBAORD")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/resource-view`}
            component={lazy(() => import(`./resourceview`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/qr-code-encoder`}
            component={lazy(() => import(`./qr-code-encoder`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/client`}
            component={lazy(() => import(`./client`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>

          <GuardedRoute
            path={`${APP_PREFIX_PATH}/preventive-maintenance`}
            component={lazy(() => import(`./preventive-maintenance`))}
            hasAccesssToRoute={checkPermissions(
              "PREVENTIVE_MAINTENANCE_TICKET"
            )}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/preSaleMaintenance`}
            component={lazy(() => import(`./preSaleMaintenance`))}
            hasAccesssToRoute={checkPermissions("PRESALE_MAINTENANCE_TICKET")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/installation-commissioning`}
            component={lazy(() => import(`./installation-commissioning`))}
            hasAccesssToRoute={checkPermissions(
              "INSTALLATION_AND_COMMISSIONING"
            )}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/breakdownMaintenence`}
            component={lazy(() => import(`./breakdownMaintenence`))}
            hasAccesssToRoute={checkPermissions("BREAKDOWN_MAINTENENCE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/Expenses`}
            component={lazy(() => import(`./Expenses`))}
            hasAccesssToRoute={checkPermissions("SVC_EXPENSES")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/svc-machine-type`}
            component={lazy(() => import(`./svc-machine-type`))}
            hasAccesssToRoute={checkPermissions("SVC_MACHINE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/svc-machine-instance`}
            component={lazy(() => import(`./svc-machine-instance`))}
            hasAccesssToRoute={checkPermissions("SVC_MACHINE_INSTANCE")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/manage-spare-part`}
            component={lazy(() => import(`./manage-spare-part`))}
            hasAccesssToRoute={checkPermissions("MANAGE_SPARE_PART")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-report-list`}
            component={lazy(() => import(`./ticket-report-list`))}
            hasAccesssToRoute={checkPermissions("TICKET_REPORT_LIST")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-assigned-to-me`}
            component={lazy(() => import(`./ticket-assigned-to-me`))}
            hasAccesssToRoute={checkPermissions("TICKET_ASSIGNED_TO_ME")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/home-button`}
            component={lazy(() => import(`./home-button`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/questions`}
            component={lazy(() => import(`./questions`))}
            hasAccesssToRoute={checkPermissions("QUESTIONS")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/actionTicket`}
            component={lazy(() => import(`./actionTicket`))}
            hasAccesssToRoute={checkPermissions("ACTION_")}
          ></GuardedRoute>
          <GuardedRoute
            path={`${APP_PREFIX_PATH}/ticket-dashbaord`}
            component={lazy(() => import(`./ticket-dashboard`))}
            hasAccesssToRoute={true}
          ></GuardedRoute>
          <Route
            path={`${APP_PREFIX_PATH}/my-open-tickets`}
            component={lazy(() => import(`./ticket-report-list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/my-urgent-tickets`}
            component={lazy(() => import(`./ticket-report-list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/my-resource-view`}
            component={lazy(() => import(`./resourceview`))}
          />
          <Redirect
            from={`${APP_PREFIX_PATH}`}
            to={`${APP_PREFIX_PATH}/home`}
          />
        </Switch>
      </Suspense>
    </ErrorBoundary>
  );
};

const GuardedRoute = ({ hasAccesssToRoute, ...rest }) =>
  hasAccesssToRoute ? (
    <Route {...rest} />
  ) : (
    <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
  );
export default React.memo(AppViews);
