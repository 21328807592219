import { put, takeLatest } from "redux-saga/effects";

import {
  requestSvcMachineInstanceList,
  requestCreateSvcMachineInstance,
  requestUpdateSvcMachineInstance,
  requestDeleteSvcMachineInstance,
  requestSvcMachineInstanceDataById,
} from "../../../services/svcMachineInstance";
import {
  fetchSvcMachineInstanceListRequest,
  fetchSvcMachineInstanceListSuccess,
  fetchSvcMachineInstanceListFailure,
  createSvcMachineInstanceRequest,
  createSvcMachineInstanceSuccess,
  createSvcMachineInstanceFailure,
  updateSvcMachineInstanceRequest,
  updateSvcMachineInstanceSuccess,
  updateSvcMachineInstanceFailure,
  deleteSvcMachineInstanceRequest,
  deleteSvcMachineInstanceSuccess,
  deleteSvcMachineInstanceFailure,
  getSvcMachineInstanceDetailsRequest,
  getSvcMachineInstanceDetailsSuccess,
  getSvcMachineInstanceDetailsFailure,
} from "../../reducers/svcMachineInstance";

export function* watchSvcMachineInstanceSaga() {
  yield takeLatest(
    fetchSvcMachineInstanceListRequest,
    fetchSvcMachineInstanceList
  );
  yield takeLatest(createSvcMachineInstanceRequest, createSvcMachineInstance);
  yield takeLatest(updateSvcMachineInstanceRequest, updateSvcMachineInstance);
  yield takeLatest(deleteSvcMachineInstanceRequest, deleteSvcMachineInstance);
  yield takeLatest(
    getSvcMachineInstanceDetailsRequest,
    getSvcMachineInstanceGetByIdData
  );
}

function* fetchSvcMachineInstanceList({ payload }) {
  try {
    const response = yield requestSvcMachineInstanceList(payload);
    yield put(fetchSvcMachineInstanceListSuccess(response));
  } catch (error) {
    yield put(
      fetchSvcMachineInstanceListFailure(
        "Failed to get list of Svc Machine Instance"
      )
    );
  }
}

function* createSvcMachineInstance({ payload }) {
  try {
    //service add
    const response = yield requestCreateSvcMachineInstance(payload);
    yield put(createSvcMachineInstanceSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to create Svc Machine Instance";
    yield put(createSvcMachineInstanceFailure(message));
  }
}

function* updateSvcMachineInstance({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSvcMachineInstance(payload);
    yield put(updateSvcMachineInstanceSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?._message ||
      "Failed to update Svc Machine Instance";
    if (error?.response?.data?.data?.code === 11000) {
      let objectIntoString = JSON.stringify(
        error?.response?.data?.data?.keyValue
      );
      let removeSpecialChar = objectIntoString.replace(/[""{}]/g, "");
      let finalValue = removeSpecialChar.split(":")[0];
      yield put(
        updateSvcMachineInstanceFailure(
          "Duplicate " +
            finalValue +
            " Field. " +
            " Unique Number is Required !"
        )
      );
    } else {
      yield put(updateSvcMachineInstanceFailure(message));
    }
  }
}

function* deleteSvcMachineInstance({ payload }) {
  try {
    const response = yield requestDeleteSvcMachineInstance(payload);
    yield put(deleteSvcMachineInstanceSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to Delete Svc Machine Instance";
    yield put(deleteSvcMachineInstanceFailure(message));
  }
}

function* getSvcMachineInstanceGetByIdData({ payload }) {
  try {
    const response = yield requestSvcMachineInstanceDataById(payload);
    yield put(getSvcMachineInstanceDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Svc Machine Instance details";
    yield put(getSvcMachineInstanceDetailsFailure(message));
  }
}
