import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  preventiveMaintenanceList: { loading: false, data: null, error: null },
  createPreventiveMaintenance: { loading: false, data: null, error: null },
  updatePreventiveMaintenance: { loading: false, data: null, error: null },
  deletePreventiveMaintenance: { loading: false, data: null, error: null },
  getPreventiveMaintenanceById: { loading: false, data: null, error: null },
};

export const preventiveMaintenanceSlice = createSlice({
  name: "preventiveMaintenance",
  initialState,
  reducers: {
    fetchPreventiveMaintenanceListRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.preventiveMaintenanceList.loading = true;
      preventiveMaintenance.preventiveMaintenanceList.error = null;
    },
    fetchPreventiveMaintenanceListSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.preventiveMaintenanceList.data = action.payload;
      preventiveMaintenance.preventiveMaintenanceList.loading = false;
      preventiveMaintenance.preventiveMaintenanceList.error = null;
    },
    fetchPreventiveMaintenanceListFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.preventiveMaintenanceList.data = null;
      preventiveMaintenance.preventiveMaintenanceList.loading = false;
      preventiveMaintenance.preventiveMaintenanceList.error = action.payload;
    },

    //create PreventiveMaintenance
    createPreventiveMaintenanceRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.createPreventiveMaintenance.loading = true;
      preventiveMaintenance.createPreventiveMaintenance.error = null;
    },
    createPreventiveMaintenanceSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.createPreventiveMaintenance.data = action.payload;
      preventiveMaintenance.createPreventiveMaintenance.loading = false;
      preventiveMaintenance.createPreventiveMaintenance.error = null;
      preventiveMaintenance.preventiveMaintenanceList =
        addNewPreventiveMaintenanceSuccess(action.payload, {
          ...preventiveMaintenance.preventiveMaintenanceList,
        });
    },
    createPreventiveMaintenanceFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.createPreventiveMaintenance.data = null;
      preventiveMaintenance.createPreventiveMaintenance.loading = false;
      preventiveMaintenance.createPreventiveMaintenance.error = action.payload;
    },

    //update preventiveMaintenance details
    updatePreventiveMaintenanceRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.updatePreventiveMaintenance.loading = true;
      preventiveMaintenance.updatePreventiveMaintenance.error = null;
    },
    updatePreventiveMaintenanceSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.updatePreventiveMaintenance.data = action.payload;
      preventiveMaintenance.updatePreventiveMaintenance.loading = false;
      preventiveMaintenance.updatePreventiveMaintenance.error = null;
      preventiveMaintenance.preventiveMaintenanceList =
        updateNewPreventiveMaintenanceSuccess(
          action.payload,
          preventiveMaintenance.preventiveMaintenanceList
        );
    },
    updatePreventiveMaintenanceFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.updatePreventiveMaintenance.data = null;
      preventiveMaintenance.updatePreventiveMaintenance.loading = false;
      preventiveMaintenance.updatePreventiveMaintenance.error = action.payload;
    },

    // delete preventiveMaintenance
    deletePreventiveMaintenanceRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.deletePreventiveMaintenance.loading = true;
      preventiveMaintenance.deletePreventiveMaintenance.error = null;
    },
    deletePreventiveMaintenanceSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.deletePreventiveMaintenance.data = action.payload;
      preventiveMaintenance.deletePreventiveMaintenance.loading = false;
      preventiveMaintenance.deletePreventiveMaintenance.error = null;
      preventiveMaintenance.preventiveMaintenanceList =
        deleteNewPreventiveMaintenanceSuccess(action.payload, {
          ...preventiveMaintenance.preventiveMaintenanceList,
        });
    },
    deletePreventiveMaintenanceFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.deletePreventiveMaintenance.data = null;
      preventiveMaintenance.deletePreventiveMaintenance.loading = false;
      preventiveMaintenance.deletePreventiveMaintenance.error = action.payload;
    },

    // getById preventiveMaintenance details
    getPreventiveMaintenanceByIdRequest: (preventiveMaintenance, action) => {
      preventiveMaintenance.getPreventiveMaintenanceById.loading = true;
      preventiveMaintenance.getPreventiveMaintenanceById.error = null;
    },
    getPreventiveMaintenanceByIdSuccess: (preventiveMaintenance, action) => {
      preventiveMaintenance.getPreventiveMaintenanceById.data = action.payload;
      preventiveMaintenance.getPreventiveMaintenanceById.loading = false;
      preventiveMaintenance.getPreventiveMaintenanceById.error = null;
    },
    getPreventiveMaintenanceByIdFailure: (preventiveMaintenance, action) => {
      preventiveMaintenance.getPreventiveMaintenanceById.data = null;
      preventiveMaintenance.getPreventiveMaintenanceById.loading = false;
      preventiveMaintenance.getPreventiveMaintenanceById.error = action.payload;
    },

    // reset preventiveMaintenance details
    resetPreventiveMaintenance: (preventiveMaintenance, action) => {
      preventiveMaintenance.createPreventiveMaintenance.data = null;
      preventiveMaintenance.createPreventiveMaintenance.loading = false;
      preventiveMaintenance.createPreventiveMaintenance.error = null;

      preventiveMaintenance.updatePreventiveMaintenance.data = null;
      preventiveMaintenance.updatePreventiveMaintenance.loading = false;
      preventiveMaintenance.updatePreventiveMaintenance.error = null;

      preventiveMaintenance.deletePreventiveMaintenance.data = null;
      preventiveMaintenance.deletePreventiveMaintenance.loading = false;
      preventiveMaintenance.deletePreventiveMaintenance.error = null;

      preventiveMaintenance.getPreventiveMaintenanceById.data = null;
      preventiveMaintenance.getPreventiveMaintenanceById.loading = false;
      preventiveMaintenance.getPreventiveMaintenanceById.error = null;
    },

    resetPreventiveMaintenanceList: (preventiveMaintenance, action) => {
      preventiveMaintenance.preventiveMaintenanceList.data = null;
      preventiveMaintenance.preventiveMaintenanceList.loading = false;
      preventiveMaintenance.preventiveMaintenanceList.error = null;
    },
  },
});

function addNewPreventiveMaintenanceSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewPreventiveMaintenanceSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewPreventiveMaintenanceSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchPreventiveMaintenanceListRequest,
  fetchPreventiveMaintenanceListSuccess,
  fetchPreventiveMaintenanceListFailure,

  createPreventiveMaintenanceRequest,
  createPreventiveMaintenanceSuccess,
  createPreventiveMaintenanceFailure,

  updatePreventiveMaintenanceRequest,
  updatePreventiveMaintenanceSuccess,
  updatePreventiveMaintenanceFailure,

  deletePreventiveMaintenanceRequest,
  deletePreventiveMaintenanceSuccess,
  deletePreventiveMaintenanceFailure,

  getPreventiveMaintenanceByIdRequest,
  getPreventiveMaintenanceByIdSuccess,
  getPreventiveMaintenanceByIdFailure,

  resetPreventiveMaintenance,
  resetPreventiveMaintenanceList,
} = preventiveMaintenanceSlice.actions;

export const getPreventiveMaintenanceListState = (store) =>
  store?.preventiveMaintenance?.preventiveMaintenanceList;
export const getPreventiveMaintenanceCreateState = (store) =>
  store?.preventiveMaintenance?.createPreventiveMaintenance;
export const getPreventiveMaintenanceUpdateState = (store) =>
  store?.preventiveMaintenance?.updatePreventiveMaintenance;
export const getPreventiveMaintenanceDeleteState = (store) =>
  store?.preventiveMaintenance?.deletePreventiveMaintenance;
export const getPreventiveMaintenanceGetByIdState = (store) =>
  store?.preventiveMaintenance?.getPreventiveMaintenanceById;
export default preventiveMaintenanceSlice.reducer;
