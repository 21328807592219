import { put, takeLatest } from "redux-saga/effects";

import {
    requestPreventiveMaintenanceList,
    requestCreatePreventiveMaintenance,
    requestUpdatePreventiveMaintenance,
    requestDeletePreventiveMaintenance,
    requestPreventiveMaintenanceDataById,
} from "services/preventiveMaintenance";

import {
    fetchPreventiveMaintenanceListRequest,
    fetchPreventiveMaintenanceListSuccess,
    fetchPreventiveMaintenanceListFailure,
    createPreventiveMaintenanceRequest,
    createPreventiveMaintenanceSuccess,
    createPreventiveMaintenanceFailure,
    updatePreventiveMaintenanceRequest,
    updatePreventiveMaintenanceSuccess,
    updatePreventiveMaintenanceFailure,
    deletePreventiveMaintenanceRequest,
    deletePreventiveMaintenanceSuccess,
    deletePreventiveMaintenanceFailure,
    getPreventiveMaintenanceByIdRequest,
    getPreventiveMaintenanceByIdSuccess,
    getPreventiveMaintenanceByIdFailure,
} from "redux/reducers/preventiveMaintenance";

export function* watchPreventiveMaintenanceSaga() {
    yield takeLatest(fetchPreventiveMaintenanceListRequest, fetchPreventiveMaintenanceList);
    yield takeLatest(createPreventiveMaintenanceRequest, createPreventiveMaintenance);
    yield takeLatest(updatePreventiveMaintenanceRequest, updatePreventiveMaintenance);
    yield takeLatest(deletePreventiveMaintenanceRequest, deletePreventiveMaintenance);
    yield takeLatest(getPreventiveMaintenanceByIdRequest, getPreventiveMaintenanceById);
}

function* fetchPreventiveMaintenanceList({ payload }) {
    try {
        const response = yield requestPreventiveMaintenanceList(payload);
        yield put(fetchPreventiveMaintenanceListSuccess(response));
    } catch (error) {
        yield put(
            fetchPreventiveMaintenanceListFailure("Failed to get list of preventiveMaintenance")
        );
    }
}

function* createPreventiveMaintenance({ payload }) {
    try {
        // service add
        const response = yield requestCreatePreventiveMaintenance(payload);
        yield put(createPreventiveMaintenanceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create preventiveMaintenance";
        yield put(createPreventiveMaintenanceFailure(message));
    }
}

function* updatePreventiveMaintenance({ payload }) {
    try {
        const response = yield requestUpdatePreventiveMaintenance(payload);
        yield put(updatePreventiveMaintenanceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update preventiveMaintenance";
        yield put(updatePreventiveMaintenanceFailure(message));
    }
}

function* deletePreventiveMaintenance({ payload }) {
    try {
        const response = yield requestDeletePreventiveMaintenance(payload);
        yield put(deletePreventiveMaintenanceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete preventiveMaintenance";
        yield put(deletePreventiveMaintenanceFailure(message));
    }
}

function* getPreventiveMaintenanceById({ payload }) {
    try {
        const response = yield requestPreventiveMaintenanceDataById(payload);
        yield put(getPreventiveMaintenanceByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get preventiveMaintenance details";
        yield put(getPreventiveMaintenanceByIdFailure(message));
    }
}