import { put, takeLatest } from "redux-saga/effects";

import {
    requestPreSaleMaintenenceList,
    requestCreatePreSaleMaintenence,
    requestUpdatePreSaleMaintenence,
    requestDeletePreSaleMaintenence,
    requestPreSaleMaintenenceDataById,
} from "services/preSaleMaintenence";

import {
    fetchPreSaleMaintenenceListRequest,
    fetchPreSaleMaintenenceListSuccess,
    fetchPreSaleMaintenenceListFailure,
    createPreSaleMaintenenceRequest,
    createPreSaleMaintenenceSuccess,
    createPreSaleMaintenenceFailure,
    updatePreSaleMaintenenceRequest,
    updatePreSaleMaintenenceSuccess,
    updatePreSaleMaintenenceFailure,
    deletePreSaleMaintenenceRequest,
    deletePreSaleMaintenenceSuccess,
    deletePreSaleMaintenenceFailure,
    getPreSaleMaintenenceByIdRequest,
    getPreSaleMaintenenceByIdSuccess,
    getPreSaleMaintenenceByIdFailure,
} from "redux/reducers/preSaleMaintenence";

export function* watchPreSaleMaintenenceSaga() {
    yield takeLatest(fetchPreSaleMaintenenceListRequest, fetchPreSaleMaintenenceList);
    yield takeLatest(createPreSaleMaintenenceRequest, createPreSaleMaintenence);
    yield takeLatest(updatePreSaleMaintenenceRequest, updatePreSaleMaintenence);
    yield takeLatest(deletePreSaleMaintenenceRequest, deletePreSaleMaintenence);
    yield takeLatest(getPreSaleMaintenenceByIdRequest, getPreSaleMaintenenceById);
}

function* fetchPreSaleMaintenenceList({ payload }) {
    try {
        const response = yield requestPreSaleMaintenenceList(payload);
        yield put(fetchPreSaleMaintenenceListSuccess(response));
    } catch (error) {
        yield put(
            fetchPreSaleMaintenenceListFailure("Failed to get list of preSaleMaintenence")
        );
    }
}

function* createPreSaleMaintenence({ payload }) {
    try {
        // service add
        const response = yield requestCreatePreSaleMaintenence(payload);
        yield put(createPreSaleMaintenenceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create preSaleMaintenence";
        yield put(createPreSaleMaintenenceFailure(message));
    }
}

function* updatePreSaleMaintenence({ payload }) {
    try {
        const response = yield requestUpdatePreSaleMaintenence(payload);
        yield put(updatePreSaleMaintenenceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update preSaleMaintenence";
        yield put(updatePreSaleMaintenenceFailure(message));
    }
}

function* deletePreSaleMaintenence({ payload }) {
    try {
        const response = yield requestDeletePreSaleMaintenence(payload);
        yield put(deletePreSaleMaintenenceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete preSaleMaintenence";
        yield put(deletePreSaleMaintenenceFailure(message));
    }
}

function* getPreSaleMaintenenceById({ payload }) {
    try {
        const response = yield requestPreSaleMaintenenceDataById(payload);
        yield put(getPreSaleMaintenenceByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get preSaleMaintenence details";
        yield put(getPreSaleMaintenenceByIdFailure(message));
    }
}