import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    actionNoteList: { loading: false, data: null, error: null },
    createActionNote: { loading: false, data: null, error: null },
    updateActionNote: { loading: false, data: null, error: null },
    deleteActionNote: { loading: false, data: null, error: null },
    getActionNoteById: { loading: false, data: null, error: null }
}

export const actionNoteSlice = createSlice({
    name: "actionNote",
    initialState,
    reducers: {
        fetchActionNoteListRequest: (actionNote, action) => {
            actionNote.actionNoteList.loading = true;
            actionNote.actionNoteList.error = null;
        },
        fetchActionNoteListSuccess: (actionNote, action) => {
            actionNote.actionNoteList.data = action.payload;
            actionNote.actionNoteList.loading = false;
            actionNote.actionNoteList.error = null;
        },
        fetchActionNoteListFailure: (actionNote, action) => {
            actionNote.actionNoteList.data = null;
            actionNote.actionNoteList.loading = false;
            actionNote.actionNoteList.error = action.payload;
        },

        //create ActionNote
        createActionNoteRequest: (actionNote, action) => {
            actionNote.createActionNote.loading = true;
            actionNote.createActionNote.error = null;
        },
        createActionNoteSuccess: (actionNote, action) => {
            actionNote.createActionNote.data = action.payload;
            actionNote.createActionNote.loading = false;
            actionNote.createActionNote.error = null;
            actionNote.actionNoteList = addNewActionNoteSuccess(action.payload, {
                ...actionNote.actionNoteList,
            })
        },
        createActionNoteFailure: (actionNote, action) => {
            actionNote.createActionNote.data = null;
            actionNote.createActionNote.loading = false;
            actionNote.createActionNote.error = action.payload;
        },

        //update actionNote details
        updateActionNoteRequest: (actionNote, action) => {
            actionNote.updateActionNote.loading = true;
            actionNote.updateActionNote.error = null;
        },
        updateActionNoteSuccess: (actionNote, action) => {
            actionNote.updateActionNote.data = action.payload;
            actionNote.updateActionNote.loading = false;
            actionNote.updateActionNote.error = null;
            actionNote.actionNoteList = updateNewActionNoteSuccess(
                action.payload,
                actionNote.actionNoteList
            );
        },
        updateActionNoteFailure: (actionNote, action) => {
            actionNote.updateActionNote.data = null;
            actionNote.updateActionNote.loading = false;
            actionNote.updateActionNote.error = action.payload;
        },

        // delete actionNote
        deleteActionNoteRequest: (actionNote, action) => {
            actionNote.deleteActionNote.loading = true;
            actionNote.deleteActionNote.error = null;
        },
        deleteActionNoteSuccess: (actionNote, action) => {
            actionNote.deleteActionNote.data = action.payload;
            actionNote.deleteActionNote.loading = false;
            actionNote.deleteActionNote.error = null;
            actionNote.actionNoteList = deleteNewActionNoteSuccess(action.payload, {
                ...actionNote.actionNoteList,
            })
        },
        deleteActionNoteFailure: (actionNote, action) => {
            actionNote.deleteActionNote.data = null;
            actionNote.deleteActionNote.loading = false;
            actionNote.deleteActionNote.error = action.payload;
        },

        // getById actionNote details
        getActionNoteByIdRequest: (actionNote, action) => {
            actionNote.getActionNoteById.loading = true;
            actionNote.getActionNoteById.error = null;
        },
        getActionNoteByIdSuccess: (actionNote, action) => {
            actionNote.getActionNoteById.data = action.payload;
            actionNote.getActionNoteById.loading = false;
            actionNote.getActionNoteById.error = null;
        },
        getActionNoteByIdFailure: (actionNote, action) => {
            actionNote.getActionNoteById.data = null;
            actionNote.getActionNoteById.loading = false;
            actionNote.getActionNoteById.error = action.payload;
        },

        // reset actionNote details
        resetActionNote: (actionNote, action) => {
            actionNote.createActionNote.data = null;
            actionNote.createActionNote.loading = false;
            actionNote.createActionNote.error = null;

            actionNote.updateActionNote.data = null;
            actionNote.updateActionNote.loading = false;
            actionNote.updateActionNote.error = null;

            actionNote.deleteActionNote.data = null;
            actionNote.deleteActionNote.loading = false;
            actionNote.deleteActionNote.error = null;
        }
    }
})

function addNewActionNoteSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewActionNoteSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewActionNoteSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchActionNoteListRequest,
    fetchActionNoteListSuccess,
    fetchActionNoteListFailure,

    createActionNoteRequest,
    createActionNoteSuccess,
    createActionNoteFailure,

    updateActionNoteRequest,
    updateActionNoteSuccess,
    updateActionNoteFailure,

    deleteActionNoteRequest,
    deleteActionNoteSuccess,
    deleteActionNoteFailure,

    getActionNoteByIdRequest,
    getActionNoteByIdSuccess,
    getActionNoteByIdFailure,

    resetActionNote,
} = actionNoteSlice.actions;

export const getActionNoteListState = (store) =>
    store?.actionNote?.actionNoteList;
export const getActionNoteCreateState = (store) =>
    store?.actionNote?.createActionNote;
export const getActionNoteUpdateState = (store) =>
    store?.actionNote?.updateActionNote;
export const getActionNoteDeleteState = (store) =>
    store?.actionNote?.deleteActionNote;
export const getActionNoteGetByIdState = (store) =>
    store?.actionNote?.getActionNoteById;
export default actionNoteSlice.reducer;