import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestLocationList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/location`, {
            params: {limit: Number.MAX_SAFE_INTEGER},
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/location?${params}`, {
            params: {limit: Number.MAX_SAFE_INTEGER},
        });
    }
}