import { put, takeLatest } from "redux-saga/effects";
import {
    fetchPlantListFailure,
    fetchPlantListRequest,
    fetchPlantListSuccess,
    createPlantRequest,
    createPlantSuccess,
    createPlantFailure,
    updatePlantRequest,
    updatePlantSuccess,
    updatePlantFailure,
    deletePlantRequest,
    deletePlantSuccess,
    deletePlantFailure,
    getPlantByIdRequest,
    getPlantByIdSuccess,
    getPlantByIdFailure,
} from "redux/reducers/plant";
import {
    requestOrganizationList,
    requestCreateOrganization,
    requestUpdateOrganization,
    requestDeleteOrganization,
    requestOrganizationDataById,
} from "services/organization";

export function* watchPlantSaga() {
    yield takeLatest(fetchPlantListRequest, fetchPlantList);
    yield takeLatest(createPlantRequest, createPlant);
    yield takeLatest(updatePlantRequest, updatePlant);
    yield takeLatest(deletePlantRequest, deletePlant);
    yield takeLatest(getPlantByIdRequest, getPlantById);
}

function* fetchPlantList({ payload }) {
    try {
        const response = yield requestOrganizationList(payload);
        yield put(fetchPlantListSuccess(response));
    } catch (error) {
        yield put(
            fetchPlantListFailure("Failed to get Plant")
        )
    }
}

function* createPlant({ payload }) {
    try {
        // service add
        const response = yield requestCreateOrganization(payload);
        yield put(createPlantSuccess(response.data));
    } catch (error) {
        yield put(createPlantFailure("Failed to create plant"));
    }
}

function* updatePlant({ payload }) {
    try {
        const response = yield requestUpdateOrganization(payload);
        yield put(updatePlantSuccess(response.data));
    } catch (error) {
        yield put(updatePlantFailure("Failed to update plant"));
    }
}
function* deletePlant({ payload }) {
    try {
        const response = yield requestDeleteOrganization(payload);
        yield put(deletePlantSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete Plant";
        yield put(deletePlantFailure(message));
    }
}

function* getPlantById({ payload }) {
    try {
        const response = yield requestOrganizationDataById(payload);
        yield put(getPlantByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get plant details";
        yield put(getPlantByIdFailure(message));
    }
}