import { createSlice } from "@reduxjs/toolkit";

const initialState = {

    updateFileUpload: { loading: false, data: null, error: null },
    getFileUploadById: { loading: false, data: null, error: null }
}

export const fileUploadSlice = createSlice({
    name: "fileUpload",
    initialState,
    reducers: {
        //update fileUpload details
        updateFileUploadRequest: (fileUpload, action) => {
            fileUpload.updateFileUpload.loading = true;
            fileUpload.updateFileUpload.error = null;
        },
        updateFileUploadSuccess: (fileUpload, action) => {
            fileUpload.updateFileUpload.data = action.payload;
            fileUpload.updateFileUpload.loading = false;
            fileUpload.updateFileUpload.error = null;
        },
        updateFileUploadFailure: (fileUpload, action) => {
            fileUpload.updateFileUpload.data = null;
            fileUpload.updateFileUpload.loading = false;
            fileUpload.updateFileUpload.error = action.payload;
        },

        // getById fileUpload details
        getFileUploadByIdRequest: (fileUpload, action) => {
            fileUpload.getFileUploadById.loading = true;
            fileUpload.getFileUploadById.error = null;
        },
        getFileUploadByIdSuccess: (fileUpload, action) => {
            fileUpload.getFileUploadById.data = action.payload;
            fileUpload.getFileUploadById.loading = false;
            fileUpload.getFileUploadById.error = null;
        },
        getFileUploadByIdFailure: (fileUpload, action) => {
            fileUpload.getFileUploadById.data = null;
            fileUpload.getFileUploadById.loading = false;
            fileUpload.getFileUploadById.error = action.payload;
        },

        // reset fileUpload details
        resetFileUpload: (fileUpload, action) => {

            fileUpload.updateFileUpload.data = null;
            fileUpload.updateFileUpload.loading = false;
            fileUpload.updateFileUpload.error = null;

            // fileUpload.getFileUploadById.data = null;
            // fileUpload.getFileUploadById.loading = false;
            // fileUpload.getFileUploadById.error = null;
        }
    }
})

export const {

    updateFileUploadRequest,
    updateFileUploadSuccess,
    updateFileUploadFailure,

    getFileUploadByIdRequest,
    getFileUploadByIdSuccess,
    getFileUploadByIdFailure,

    resetFileUpload,
} = fileUploadSlice.actions;

export const getFileUploadUpdateState = (store) =>
    store?.fileUpload?.updateFileUpload;
export const getFileUploadGetByIdState = (store) =>
    store?.fileUpload?.getFileUploadById;
export default fileUploadSlice.reducer;