import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    clientList: { loading: false, data: null, error: null },
    createClient: { loading: false, data: null, error: null },
    updateClient: { loading: false, data: null, error: null },
    clientGetById: { loading: false, data: null, error: null },
}

export const clientSlice = createSlice({
    name: "client",
    initialState,
    reducers: {
        fetchClientListRequest: (client, action) => {
            client.clientList.loading = true;
            client.clientList.error = null;
        },
        fetchClientListSuccess: (client, action) => {
            client.clientList.data = action.payload;
            client.clientList.loading = false;
            client.clientList.error = null
        },
        fetchClientListFailure: (client, action) => {
            client.clientList.data = null;
            client.clientList.loading = false;
            client.clientList.error = action.payload;
        },
        createClientRequest: (client, action) => {
            client.createClient.loading = true;
            client.createClient.error = null;
        },
        createClientSuccess: (client, action) => {
            client.createClient.data = action.payload;
            client.createClient.loading = false;
            client.createClient.error = null;
            client.clientList = addNewClientSuccess(action.payload, {
                ...client.clientList,
            });
        },
        createClientFailure: (client, action) => {
            client.createClient.data = null;
            client.createClient.loading = false;
            client.createClient.error = action.payload;
        },

        // update client
        updateClientRequest: (client, action) => {
            client.updateClient.loading = true;
            client.updateClient.error = null;
        },
        updateClientSuccess: (client, action) => {
            client.updateClient.data = action.payload;
            client.updateClient.loading = false;
            client.updateClient.error = null;
            client.clientList = updateClientListSuccess(action.payload, {
                ...client.clientList,
            });
        },
        updateClientFailure: (client, action) => {
            client.updateClient.data = null;
            client.updateClient.loading = false;
            client.updateClient.error = action.payload;
        },

        // client get by id
        clientGetByIdRequest: (client, action) => {
            client.clientGetById.loading = true;
            client.clientGetById.error = null;
        },
        clientGetByIdSuccess: (client, action) => {
            client.clientGetById.data = action.payload;
            client.clientGetById.loading = false;
            client.clientGetById.error = null;
        },
        clientGetByIdFailure: (client, action) => {
            client.clientGetById.data = null;
            client.clientGetById.loading = false;
            client.clientGetById.error = action.payload;
        },

        // reset create, update
        resetClientData: (client, action) => {
            client.createClient.data = null;
            client.createClient.loading = false;
            client.createClient.error = null;

            client.updateClient.data = null;
            client.updateClient.loading = false;
            client.updateClient.error = null;
        },
    }
});

function addNewClientSuccess(dataToUpdate, state) {
    state.data.data = [dataToUpdate, ...state.data.data];
    state.data.total++;
    return state;
}

function updateClientListSuccess(dataToUpdate, state) {
    state.data.data = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return state;
}

export const {
    fetchClientListRequest,
    fetchClientListSuccess,
    fetchClientListFailure,

    createClientRequest,
    createClientSuccess,
    createClientFailure,

    updateClientRequest,
    updateClientSuccess,
    updateClientFailure,

    clientGetByIdRequest,
    clientGetByIdSuccess,
    clientGetByIdFailure,

    resetClientData,
} = clientSlice.actions

export const getClientListState = (store) => store.client?.clientList;
export const getClientCreateState = (store) => store.client?.createClient;
export const getClientUpdateState = (store) => store.client?.updateClient;
export const getByIdStateClient = (store) => store.client?.clientGetById;
export default clientSlice.reducer;