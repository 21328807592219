import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestOrganizationList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/asset/list`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/asset/list?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}

export function requestCreateOrganization(data) {
    return fetch.post(`${BASE_URL}/api/app/asset`, data)
}

export function requestUpdateOrganization(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/asset/${data._id}`, body);
}

export function requestDeleteOrganization(data) {
    return fetch.delete(`${BASE_URL}/api/app/asset/${data}`);
}

export function requestOrganizationDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/asset/getById/${data.id}`);
}