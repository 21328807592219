import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import Flex from "components/shared-components/Flex";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationRequest,
  getNotificationsState,
} from "redux/reducers/notification";
import { requestUpdateNotificationList } from "services/notifications";
import { Link } from "react-router-dom";
import moment from "moment";
import { showNotification } from "utils/notifications";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "Alert":
      return <WarningOutlined />;
    case "Fault":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};
const refreshInterval = 15 * 60 * 1000;

const getNotificationBody = (list) => {
  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item
          className="list-clickable"
          onClick={async () => {
            await requestUpdateNotificationList({
              _id: [item._id],
              status: "Read",
            });
          }}
        >
          <Flex alignItems="center">
            <div className="pr-3">
              {item.img ? (
                <Avatar src={`/img/avatars/${item.img}`} />
              ) : (
                <Avatar
                  className={`ant-avatar-${item.type} ${item.type}`}
                  icon={getIcon(item.type)}
                  style={{ lineHeight: 0.6, fontSize: "30px" }}
                />
              )}
            </div>
            <div className="mr-3">
              <span className="text-gray-light font-weight-bold">
                {item.desc}
              </span>
            </div>
            <small className="ml-auto">{item.time}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};

export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchNotificationRequest(`status[eq]=Unread`));
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchNotificationRequest(`status[eq]=Unread`));
    }, refreshInterval);
    return () => clearInterval(interval);
  }, []);

  const notification = useSelector(getNotificationsState);
  useEffect(() => {
    if (!notification?.data?.data.length) return setData([]);
    const data = notification?.data?.data?.map((e) => ({
      name: e?.createdBy?.name,
      desc: e?.description,
      img: "",
      icon: "",
      time: moment(e?.createdDate).format("YYYY-MM-DD HH:mm"),
      type: e?.type,
      status: e?.status,
      _id: e?._id,
    }));
    //to get differnce betwwen current date and last notification date
    function getDiffBetweenDates(startDate, endDate) {
      const diff = endDate.getTime() - startDate.getTime();
      return diff;
    }
    //differene between last
    if (
      getDiffBetweenDates(
        new Date(notification?.data?.data[0].createdDate),
        new Date()
      ) < refreshInterval
    ) {
      showNotification({
        title: "JETATECH",
        message: "You have new notification",
      });
    }
    setData(data);
  }, [notification]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button
          className="text-primary"
          type="text"
          onClick={async () => {
            await requestUpdateNotificationList({
              _id: data.map((e) => e?._id),
              status: "Read",
            });
            dispatch(fetchNotificationRequest(`status[eq]=Unread`));
          }}
          size="small"
        >
          Clear{" "}
        </Button>
        <Button
          className="text-primary"
          type="text"
          onClick={async () => {
            await requestUpdateNotificationList({
              status: "Read",
            });
            dispatch(fetchNotificationRequest(`status[eq]=Unread`));
          }}
          size="small"
        >
          Clear All
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          <Link to="/app/notifications">View all</Link>
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={notification?.data?.total}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
