export const Dashboard = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    className="anticon"
  >
    <path
      id="Icon_material-dashboard"
      data-name="Icon material-dashboard"
      d="M4.5,13.389h7.111V4.5H4.5Zm0,7.111h7.111V15.167H4.5Zm8.889,0H20.5V11.611H13.389Zm0-16V9.833H20.5V4.5Z"
      transform="translate(-4.5 -4.5)"
      fill="#6e778d"
    />
  </svg>
);

export const Users = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="21.182"
    viewBox="0 0 16 10.182"
    className="anticon"
  >
    <path
      id="Icon_material-group"
      data-name="Icon material-group"
      d="M12.409,11.864a2.182,2.182,0,1,0-2.182-2.182A2.173,2.173,0,0,0,12.409,11.864Zm-5.818,0A2.182,2.182,0,1,0,4.409,9.682,2.173,2.173,0,0,0,6.591,11.864Zm0,1.455c-1.695,0-5.091.851-5.091,2.545v1.818H11.682V15.864C11.682,14.169,8.285,13.318,6.591,13.318Zm5.818,0c-.211,0-.451.015-.705.036a3.069,3.069,0,0,1,1.433,2.509v1.818H17.5V15.864C17.5,14.169,14.1,13.318,12.409,13.318Z"
      transform="translate(-1.5 -7.5)"
      fill="#6e778d"
    />
  </svg>
);
