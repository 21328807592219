import { put, takeLatest } from "redux-saga/effects";

import {
  requestPrivilegeList,
  requestCreatePrivilege,
  requestUpdatePrivilege,
  requestDeletePrivilege,
  requestPrivilegeDataById,
} from "../../../services/privileges";
import {
  fetchPrivilegeListRequest,
  fetchPrivilegeListSuccess,
  fetchPrivilegeListFailure,
  createManagePrivilegeRequest,
  createManagePrivilegeSuccess,
  createManagePrivilegeFailure,
  updateManagePrivilegeRequest,
  updateManagePrivilegeSuccess,
  updateManagePrivilegeFailure,
  deleteManagePrivilegeRequest,
  deleteManagePrivilegeSuccess,
  deleteManagePrivilegeFailure,
  getPrivilegeDetailsRequest,
  getPrivilegeDetailsSuccess,
  getPrivilegeDetailsFailure,
} from "../../reducers/privileges";

export function* watchPrivilegeSaga() {
  yield takeLatest(fetchPrivilegeListRequest, fetchPrivilegeList);
  yield takeLatest(createManagePrivilegeRequest, createPrivilege);
  yield takeLatest(updateManagePrivilegeRequest, updatePrivilege);
  yield takeLatest(deleteManagePrivilegeRequest, deletePrivilege);
  yield takeLatest(getPrivilegeDetailsRequest, getPrivilegeGetByIdData);
}

function* fetchPrivilegeList({ payload }) {
  try {
    const response = yield requestPrivilegeList(payload);
    yield put(fetchPrivilegeListSuccess(response));
  } catch (error) {
    yield put(fetchPrivilegeListFailure("Failed to get list of Privilege"));
  }
}

function* createPrivilege({ payload }) {
  try {
    //service add
    const response = yield requestCreatePrivilege(payload);
    yield put(createManagePrivilegeSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create Privilege";
    yield put(createManagePrivilegeFailure(message));
  }
}

function* updatePrivilege({ payload }) {
  try {
    //service update
    const response = yield requestUpdatePrivilege(payload);
    yield put(updateManagePrivilegeSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update Privilege";
    yield put(updateManagePrivilegeFailure(message));
  }
}

function* deletePrivilege({ payload }) {
  try {
    const response = yield requestDeletePrivilege(payload);
    yield put(deleteManagePrivilegeSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete Privilege";
    yield put(deleteManagePrivilegeFailure(message));
  }
}

function* getPrivilegeGetByIdData({ payload }) {
  try {
    const response = yield requestPrivilegeDataById(payload);
    yield put(getPrivilegeDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to get Privilege details";
    yield put(getPrivilegeDetailsFailure(message));
  }
}
