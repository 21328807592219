import { put, takeLatest } from "redux-saga/effects";

import { requestPermissions } from "../../../services/getPermissions";
import {
  fetchPermissionListRequest,
  fetchPermissionListSuccess,
  fetchPermissionListFailure,
} from "../../reducers/permissions";

export function* watchPermissionSaga() {
  yield takeLatest(fetchPermissionListRequest, fetchPermissionList);
}

/**
 *
 * @param {*} param0
 */
function* fetchPermissionList({ payload }) {
  try {
    const response = yield requestPermissions(payload);
    yield put(fetchPermissionListSuccess(response));
  } catch (error) {
    yield put(fetchPermissionListFailure("Failed to get list of Permission"));
  }
}
