import { put, takeLatest } from "redux-saga/effects";
import {
    fetchSvcExpenseListFailure,
    fetchSvcExpenseListRequest,
    fetchSvcExpenseListSuccess
} from "redux/reducers/svcExpenseList";
import {
    requestSvcExpenseList
} from "services/svcExpenseList";

export function* watchSvcExpenseSaga() {
    yield takeLatest(fetchSvcExpenseListRequest, fetchSvcExpenseList);
}

function* fetchSvcExpenseList({ payload }) {
    try {
        const response = yield requestSvcExpenseList(payload);
        yield put(fetchSvcExpenseListSuccess(response));
    } catch (error) {
        yield put(
            fetchSvcExpenseListFailure("Failed to get list of svcExpense")
        )
    }
}