import React, { useEffect, useState } from "react";
import { Menu, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";

const MyAccount = (props) => {
  const [userData, setUserData] = useState(props?.data);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (props.data !== undefined) {
      setUserData(props?.data);
    }
  }, [props]);

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const onClick = (
    <div className="nav-dropdown nav-notification">
      <div style={{ color: "#6e778d" }}>
        <ul className="p-4">
          <li>
            <span>Logged In User : {userData?.name}</span>
          </li>
          <li>
            <span>
              Role :{" "}
              {userData?.roles?.length
                ? userData?.roles?.map((value, i, arr) => {
                    if (arr.length - 1 === i) {
                      return value?.name;
                    } else {
                      return value?.name + ", ";
                    }
                  })
                : "N/A"}
            </span>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <Dropdown
        placement="bottomRight"
        overlay={onClick}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger={["click"]}
      >
        <Menu mode="horizontal">
          <Menu.Item key="notification">
            <span
              style={{
                cursor: "pointer",
              }}
            >
              <UserOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item>
        </Menu>
      </Dropdown>
    </>
  );
};
export default MyAccount;
