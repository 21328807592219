import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestQuestionsList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/questions`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/questions?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}


export function requestCreateQuestions(data) {
    return fetch.post(`${BASE_URL}/api/app/questions/`, data);
}

export function requestUpdateQuestions(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/questions/${data._id}`, body);
}

export function requestDeleteQuestions(data) {
    return fetch.delete(`${BASE_URL}/api/app/questions/${data}`);
}

export function requestQuestionDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/questions/getById/${data.id}`);
}

export function getMachineTypeList(params) {
    return fetch.get(`${BASE_URL}/api/app/questions/getMachineType`, {
        params: { limit: Number.MAX_SAFE_INTEGER },
    });
}