import React, { Component } from "react";
import { SettingOutlined, LogoutOutlined, LockOutlined } from "@ant-design/icons";
import { Drawer, Menu, Modal } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "constants/ThemeConstant";
import { logout } from "redux/reducers/login";
import MyAccount from "components/reusable-components/My-Account";
import ChangePasswordForm from "./ChangePassword";

export class NavPanel extends Component {
  state = { visible: false, modalVisible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  showModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      modalVisible: false
    });
  };

  onLogoutClick = () => {
    const { dispatch } = this.props;
    dispatch(logout());
  };

  render() {
    return (
      <>
        <Menu mode="horizontal">
          <span style={{ marginRight: "10px" }}></span>
          {/* <Menu.Item key="panel" onClick={this.showDrawer}>
            <span>
              <SettingOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item> */}
          <Menu.Item key="panel" onClick={this.showModal}>
            <span>
              <LockOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item>
          <Menu.Item key="panel" onClick={this.onLogoutClick}>
            <span>
              <LogoutOutlined className="nav-icon mr-0" />
            </span>
          </Menu.Item>
        </Menu>
        {/* <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer> */}
        <Modal
          onCancel={this.onClose}
          visible={this.state.modalVisible}
          footer={null}
          bodyStyle={{ padding: '40px' }}
          destroyOnClose
        >
          <ChangePasswordForm closeModal={this.onClose} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
