import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manageRoleList: { loading: false, data: null, error: null },
  createmanageRole: { loading: false, data: null, error: null },
  updatemanageRole: { loading: false, data: null, error: null },
  manageRoleGetById: { loading: false, data: null, error: null },
  addFeatureRole: { loading: false, data: null, error: null },
  deleteRole: { loading: false, data: null, error: null },
  deleteFeatureRole: { loading: false, data: null, error: null },
};

export const ManageRoleReducer = createSlice({
  name: "manageRole",
  initialState,
  reducers: {
    fetchmanageRoleListRequest: (manageRole, action) => {
      manageRole.manageRoleList.loading = true;
      manageRole.manageRoleList.error = null;
    },
    fetchmanageRoleListSuccess: (manageRole, action) => {
      manageRole.manageRoleList.data = action.payload;
      manageRole.manageRoleList.loading = false;
      manageRole.manageRoleList.error = null;
    },
    fetchmanageRoleListFaliure: (manageRole, action) => {
      manageRole.manageRoleList.data = null;
      manageRole.manageRoleList.loading = false;
      manageRole.manageRoleList.error = action.payload;
    },
    // For create managerole
    createmanageRoleRequest: (manageRole, action) => {
      manageRole.createmanageRole.loading = true;
      manageRole.createmanageRole.error = null;
    },
    createmanageRoleSuccess: (manageRole, action) => {
      manageRole.createmanageRole.data = action.payload;
      manageRole.createmanageRole.loading = false;
      manageRole.createmanageRole.error = null;
      // update list remainning
      manageRole.manageRoleList = addNewRoleSuccess(action.payload, {
        ...manageRole.manageRoleList,
      });
    },
    createmanageRoleFaliure: (manageRole, action) => {
      manageRole.createmanageRole.data = null;
      manageRole.createmanageRole.loading = false;
      manageRole.createmanageRole.error = action.payload;
    },
    updatemanageRoleRequest: (manageRole, action) => {
      manageRole.updatemanageRole.loading = true;
      manageRole.updatemanageRole.error = null;
    },
    updatemanageRoleSuccess: (state, action) => {
      state.updatemanageRole.data = action.payload;
      state.updatemanageRole.loading = false;
      state.updatemanageRole.error = null;
      //add update values to list
      state.manageRoleList = updateRoleSuccess(
        action.payload,
        state.manageRoleList
      );
    },
    updatemanageRoleFaliure: (manageRole, action) => {
      manageRole.updatemanageRole.data = null;
      manageRole.updatemanageRole.loading = false;
      manageRole.updatemanageRole.error = action.payload;
    },
    //manageRole GetBy id
    manageRoleGetByIdRequest: (state, action) => {
      state.manageRoleGetById.loading = true;
      state.manageRoleGetById.error = null;
    },
    manageRoleGetByIdSuccess: (state, action) => {
      state.manageRoleGetById.data = action.payload;
      state.manageRoleGetById.loading = false;
      state.manageRoleGetById.error = null;
    },
    manageRoleGetByIdFailure: (state, action) => {
      state.manageRoleGetById.data = null;
      state.manageRoleGetById.loading = false;
      state.manageRoleGetById.error = action.payload;
    },
    //Add FeatureMenu
    addFeatureRoleRequest: (manageRole, action) => {
      manageRole.addFeatureRole.loading = true;
      manageRole.addFeatureRole.error = null;
    },
    addFeatureRoleSuccess: (state, action) => {
      state.addFeatureRole.data = action.payload;
      state.addFeatureRole.loading = false;
      state.addFeatureRole.error = null;
      state.manageRoleList = updateMangeRoleDetails(
        action.payload,
        state.manageRoleList
      );
    },
    addFeatureRoleFaliure: (manageRole, action) => {
      manageRole.addFeatureRole.data = null;
      manageRole.addFeatureRole.loading = false;
      manageRole.addFeatureRole.error = action.payload;
    },

    //Delete manageRole
    deletemanageRoleRequest: (manageRole, action) => {
      manageRole.deleteRole.loading = true;
      manageRole.deleteRole.error = null;
    },
    deletemanageRoleSuccess: (manageRole, action) => {
      manageRole.deleteRole.data = action.payload;
      manageRole.deleteRole.loading = false;
      manageRole.deleteRole.error = null;
      // add data to list
      manageRole.manageRoleList = deleteRole(action.payload, {
        ...manageRole.manageRoleList,
      });
    },
    deletemanageRoleFaliure: (manageRole, action) => {
      manageRole.deleteRole.data = null;
      manageRole.deleteRole.loading = false;
      manageRole.deleteRole.error = action.payload;
    },

    //delete FeatureMenu
    deleteFeatureRoleRequest: (manageRole, action) => {
      manageRole.deleteFeatureRole.loading = true;
      manageRole.deleteFeatureRole.error = null;
    },
    deleteFeatureRoleSuccess: (state, action) => {
      state.deleteFeatureRole.data = action.payload;
      state.deleteFeatureRole.loading = false;
      state.deleteFeatureRole.error = null;
      state.manageRoleList = updateMangeRoleDetails(
        action.payload,
        state.manageRoleList
      );
    },
    deleteFeatureRoleFaliure: (manageRole, action) => {
      manageRole.deleteFeatureRole.data = null;
      manageRole.deleteFeatureRole.loading = false;
      manageRole.deleteFeatureRole.error = action.payload;
    },

    //Reset role state
    resetmanageRole: (state, action) => {
      state.createmanageRole.data = null;
      state.createmanageRole.loading = false;
      state.createmanageRole.error = null;

      state.updatemanageRole.data = null;
      state.updatemanageRole.loading = false;
      state.updatemanageRole.error = null;

      state.addFeatureRole.data = null;
      state.addFeatureRole.loading = false;
      state.addFeatureRole.error = null;

      state.deleteRole.data = null;
      state.deleteRole.loading = false;
      state.deleteRole.error = null;

      state.deleteFeatureRole.data = null;
      state.deleteFeatureRole.loading = false;
      state.deleteFeatureRole.error = null;
    },
  },
});
function addNewRoleSuccess(dataToUpdate, state) {
  state.data.data = [...state.data.data, dataToUpdate];
  state.data.total++;
  return state;
}
function updateRoleSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}
function updateMangeRoleDetails(dataToUpdate, state) {
  let value = JSON.parse(JSON.stringify(state.data.data, undefined, 1));
  let filteredObject = value.map((e) => e._id);
  let obj = filteredObject.indexOf(dataToUpdate.response.data._id);
  if (obj !== -1) {
    value[obj] = dataToUpdate.response.data;
  }
  let finalValue = { loading: false, error: false, data: { data: value } };
  return finalValue;
}
function deleteRole(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchmanageRoleListRequest,
  fetchmanageRoleListSuccess,
  fetchmanageRoleListFaliure,

  createmanageRoleRequest,
  createmanageRoleSuccess,
  createmanageRoleFaliure,

  updatemanageRoleRequest,
  updatemanageRoleSuccess,
  updatemanageRoleFaliure,

  manageRoleGetByIdRequest,
  manageRoleGetByIdSuccess,
  manageRoleGetByIdFailure,

  addFeatureRoleRequest,
  addFeatureRoleSuccess,
  addFeatureRoleFaliure,

  deletemanageRoleRequest,
  deletemanageRoleSuccess,
  deletemanageRoleFaliure,

  deleteFeatureRoleRequest,
  deleteFeatureRoleSuccess,
  deleteFeatureRoleFaliure,

  resetmanageRole,
} = ManageRoleReducer.actions;

export const getmanageRoleListState = (store) =>
  store.manageRole?.manageRoleList;
export const getCreateManageRoleState = (store) =>
  store.manageRole?.createmanageRole;
export const getUpdateManageRoleState = (store) =>
  store.manageRole?.updatemanageRole;
export const getByIdStateManageRole = (store) =>
  store.manageRole?.manageRoleGetById;
export const getmanageRoleDeleteState = (store) => store.manageRole?.deleteRole;
export const getManageRoleFeatureState = (store) =>
  store.manageRole?.addFeatureRole;
export const getManageRoleDeleteFeatureState = (store) =>
  store.manageRole?.deleteFeatureRole;
export default ManageRoleReducer.reducer;
