import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    maintenanceClientList: { loading: false, data: null, error: null }
}

export const maintenanceClientSlice = createSlice({
    name: "maintenanceClient",
    initialState,
    reducers: {
        fetchMaintenanceClientListRequest: (maintenanceClient, action) => {
            maintenanceClient.maintenanceClientList.loading = true;
            maintenanceClient.maintenanceClientList.error = null;
        },
        fetchMaintenanceClientListSuccess: (maintenanceClient, action) => {
            maintenanceClient.maintenanceClientList.data = action.payload;
            maintenanceClient.maintenanceClientList.loading = false;
            maintenanceClient.maintenanceClientList.error = null
        },
        fetchMaintenanceClientListFailure: (maintenanceClient, action) => {
            maintenanceClient.maintenanceClientList.data = null;
            maintenanceClient.maintenanceClientList.loading = false;
            maintenanceClient.maintenanceClientList.error = action.payload;
        }
    }
})

export const {
    fetchMaintenanceClientListRequest,
    fetchMaintenanceClientListSuccess,
    fetchMaintenanceClientListFailure
} = maintenanceClientSlice.actions

export const getMaintenanceClientListState = (store) =>
    store?.maintenanceClient?.maintenanceClientList;
export default maintenanceClientSlice.reducer;