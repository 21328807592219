import { put, takeLatest } from "redux-saga/effects";

import {
  requestSparePartList,
  requestCreateSparePart,
  requestUpdateSparePart,
  requestDeleteSparePart,
  requestSparePartDataById,
  requestAssociationOfMachinePartList,
} from "../../../services/manageSparePart";
import {
  fetchSparePartListRequest,
  fetchSparePartListSuccess,
  fetchSparePartListFailure,
  createSparePartRequest,
  createSparePartSuccess,
  createSparePartFailure,
  updateSparePartRequest,
  updateSparePartSuccess,
  updateSparePartFailure,
  deleteSparePartRequest,
  deleteSparePartSuccess,
  deleteSparePartFailure,
  getSparePartDetailsRequest,
  getSparePartDetailsSuccess,
  getSparePartDetailsFailure,
  getAssociationOfMachinePartDetailsRequest,
  getAssociationOfMachinePartDetailsSuccess,
  getAssociationOfMachinePartDetailsFailure,
} from "../../reducers/manageSparePart";

export function* watchSparePartSaga() {
  yield takeLatest(fetchSparePartListRequest, fetchSparePartList);
  yield takeLatest(createSparePartRequest, createSparePart);
  yield takeLatest(updateSparePartRequest, updateSparePart);
  yield takeLatest(deleteSparePartRequest, deleteSparePart);
  yield takeLatest(getSparePartDetailsRequest, getSparePartGetByIdData);
  yield takeLatest(
    getAssociationOfMachinePartDetailsRequest,
    getAssociationOfMachinePartDetailsList
  );
}

function* fetchSparePartList({ payload }) {
  try {
    const response = yield requestSparePartList(payload);
    yield put(fetchSparePartListSuccess(response));
  } catch (error) {
    yield put(fetchSparePartListFailure("Failed to get list of Spare Part"));
  }
}

function* createSparePart({ payload }) {
  try {
    //service add
    const response = yield requestCreateSparePart(payload);
    yield put(createSparePartSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create Spare Part";
    yield put(createSparePartFailure(message));
  }
}

function* updateSparePart({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSparePart(payload);
    yield put(updateSparePartSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to update Spare Part";
    yield put(updateSparePartFailure(message));
  }
}

function* deleteSparePart({ payload }) {
  try {
    const response = yield requestDeleteSparePart(payload);
    yield put(deleteSparePartSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete Spare Part";
    yield put(deleteSparePartFailure(message));
  }
}

function* getSparePartGetByIdData({ payload }) {
  try {
    const response = yield requestSparePartDataById(payload);
    yield put(getSparePartDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Spare Part details";
    yield put(getSparePartDetailsFailure(message));
  }
}

function* getAssociationOfMachinePartDetailsList({ payload }) {
  try {
    const response = yield requestAssociationOfMachinePartList(payload);
    yield put(getAssociationOfMachinePartDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get association of machine part list";
    yield put(getAssociationOfMachinePartDetailsFailure(message));
  }
}
