import { put, takeLatest } from "redux-saga/effects";
import { getFileUploadByIdFailure, getFileUploadByIdRequest, getFileUploadByIdSuccess, updateFileUploadFailure, updateFileUploadRequest, updateFileUploadSuccess } from "redux/reducers/fileUpload";
import { requestFileUploadDataById, requestUpdateFileUpload } from "services/fileUpload";


export function* watchFileUploadSaga() {
    yield takeLatest(updateFileUploadRequest, updateFileUpload);
    yield takeLatest(getFileUploadByIdRequest, getFileUploadById);
}

function* updateFileUpload({ payload }) {
    try {
        const response = yield requestUpdateFileUpload(payload);
        yield put(updateFileUploadSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update fileUpload";
        yield put(updateFileUploadFailure(message));
    }
}

function* getFileUploadById({ payload }) {
    try {
        const response = yield requestFileUploadDataById(payload);
        yield put(getFileUploadByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get fileUpload details";
        yield put(getFileUploadByIdFailure(message));
    }
}