import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  privilegeList: { loading: false, data: null, error: null },
  createManagePrivilege: { loading: false, data: null, error: null },
  updateManagePrivilege: { loading: false, data: null, error: null },
  deleteManagePrivilege: { loading: false, data: null, error: null },
  getPrivilegeDetailsById: { loading: false, data: null, error: null },
};

export const FeatureSlice = createSlice({
  name: "privilege",
  initialState,
  reducers: {
    fetchPrivilegeListRequest: (privilege, action) => {
      privilege.privilegeList.loading = true;
      privilege.privilegeList.error = null;
    },
    fetchPrivilegeListSuccess: (privilege, action) => {
      privilege.privilegeList.data = action.payload;
      privilege.privilegeList.loading = false;
      privilege.privilegeList.error = null;
    },
    fetchPrivilegeListFailure: (privilege, action) => {
      privilege.privilegeList.data = null;
      privilege.privilegeList.loading = false;
      privilege.privilegeList.error = action.payload;
    },

    // create privilege
    createManagePrivilegeRequest: (privilege, action) => {
      privilege.createManagePrivilege.loading = true;
      privilege.createManagePrivilege.error = null;
    },
    createManagePrivilegeSuccess: (privilege, action) => {
      privilege.createManagePrivilege.data = action.payload;
      privilege.createManagePrivilege.loading = false;
      privilege.createManagePrivilege.error = null;
      privilege.privilegeList = addNewPrivilegeSuccess(action.payload, {
        ...privilege.privilegeList,
      });
    },
    createManagePrivilegeFailure: (privilege, action) => {
      privilege.createManagePrivilege.data = null;
      privilege.createManagePrivilege.loading = false;
      privilege.createManagePrivilege.error = action.payload;
    },

    // update privilege details
    updateManagePrivilegeRequest: (privilege, action) => {
      privilege.updateManagePrivilege.loading = true;
      privilege.updateManagePrivilege.error = null;
    },
    updateManagePrivilegeSuccess: (privilege, action) => {
      privilege.updateManagePrivilege.data = action.payload;
      privilege.updateManagePrivilege.loading = false;
      privilege.updateManagePrivilege.error = null;
      privilege.privilegeList = updatePrivilegeSuccess(
        action.payload,
        privilege.privilegeList
      );
    },
    updateManagePrivilegeFailure: (privilege, action) => {
      privilege.updateManagePrivilege.data = null;
      privilege.updateManagePrivilege.loading = false;
      privilege.updateManagePrivilege.error = action.payload;
    },

    // delete privilege
    deleteManagePrivilegeRequest: (privilege, action) => {
      privilege.deleteManagePrivilege.loading = true;
      privilege.deleteManagePrivilege.error = null;
    },
    deleteManagePrivilegeSuccess: (privilege, action) => {
      privilege.deleteManagePrivilege.loading = false;
      privilege.deleteManagePrivilege.error = null;
      privilege.deleteManagePrivilege.data = action.payload;
      privilege.privilegeList = deletePrivilege(action.payload, {
        ...privilege.privilegeList,
      });
    },
    deleteManagePrivilegeFailure: (privilege, action) => {
      privilege.deleteManagePrivilege.data = null;
      privilege.deleteManagePrivilege.loading = false;
      privilege.deleteManagePrivilege.error = action.payload;
    },

    // get by id privilege details
    getPrivilegeDetailsRequest: (privilege, action) => {
      privilege.getPrivilegeDetailsById.loading = true;
      privilege.getPrivilegeDetailsById.error = null;
    },
    getPrivilegeDetailsSuccess: (privilege, action) => {
      privilege.getPrivilegeDetailsById.loading = false;
      privilege.getPrivilegeDetailsById.error = null;
      privilege.getPrivilegeDetailsById.data = action.payload;
    },
    getPrivilegeDetailsFailure: (privilege, action) => {
      privilege.getPrivilegeDetailsById.data = null;
      privilege.getPrivilegeDetailsById.loading = false;
      privilege.getPrivilegeDetailsById.error = action.payload;
    },

    //Reset privilege state
    resetManagePrivilege: (privilege, action) => {
      privilege.createManagePrivilege.data = null;
      privilege.createManagePrivilege.loading = false;
      privilege.createManagePrivilege.error = null;

      privilege.updateManagePrivilege.data = null;
      privilege.updateManagePrivilege.loading = false;
      privilege.updateManagePrivilege.error = null;

      privilege.deleteManagePrivilege.data = null;
      privilege.deleteManagePrivilege.loading = false;
      privilege.deleteManagePrivilege.error = null;
    },
  },
});

// addNewPrivilege function
function addNewPrivilegeSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// updatePrivilege function
function updatePrivilegeSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deletePrivilege function
function deletePrivilege(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchPrivilegeListRequest,
  fetchPrivilegeListSuccess,
  fetchPrivilegeListFailure,

  createManagePrivilegeRequest,
  createManagePrivilegeSuccess,
  createManagePrivilegeFailure,

  updateManagePrivilegeRequest,
  updateManagePrivilegeSuccess,
  updateManagePrivilegeFailure,

  deleteManagePrivilegeRequest,
  deleteManagePrivilegeSuccess,
  deleteManagePrivilegeFailure,

  getPrivilegeDetailsRequest,
  getPrivilegeDetailsSuccess,
  getPrivilegeDetailsFailure,

  resetManagePrivilege,
} = FeatureSlice.actions;

export const getPrivilegeListState = (store) => store?.privilege?.privilegeList;
export const getManagePrivilegeCreateState = (store) =>
  store?.privilege?.createManagePrivilege;
export const getUpdatePrivilegeState = (store) =>
  store?.privilege?.updateManagePrivilege;
export const getPrivilegeDeleteState = (store) =>
  store?.privilege?.deleteManagePrivilege;
export const getPrivilegeGetByIdState = (store) =>
  store?.privilege?.getPrivilegeDetailsById;
export default FeatureSlice.reducer;
