import { put, takeLatest } from "redux-saga/effects";

import {
  requestTicketList,
  requestUpdateSystemTicket,
  requestUpdateAcknowledgeSystemTicket
} from "services/systemTicket";
import {
  fetchsystemTicketListRequest,
  fetchsystemTicketListSuccess,
  fetchsystemTicketListFailure,
  updatesystemTicketRequest,
  updatesystemTicketSuccess,
  updatesystemTicketFailure,
  updateAcknowledgesystemTicketRequest,
  updateAcknowledgesystemTicketSuccess,
  updateAcknowledgesystemTicketFailure
} from "redux/reducers/systemTicket";

export function* watchSystemTicketSaga() {
  yield takeLatest(fetchsystemTicketListRequest, fetchSystemTicketList);
  yield takeLatest(updatesystemTicketRequest, updateSystemTicket);
  yield takeLatest(updateAcknowledgesystemTicketRequest, updateAcknowledgeSystemTicket);
}

function* fetchSystemTicketList({ payload }) {
  try {
    const response = yield requestTicketList(payload);
    yield put(fetchsystemTicketListSuccess(response));
  } catch (error) {
    yield put(
      fetchsystemTicketListFailure("Failed to get list of system ticket")
    );
  }
}

function* updateSystemTicket({ payload }) {
  try {
      const response = yield requestUpdateSystemTicket(payload);
      yield put(updatesystemTicketSuccess(response.data))
  } catch(error) {
      yield put (updatesystemTicketFailure("Failed to acknowledge ticket"))
  }
}

function* updateAcknowledgeSystemTicket({ payload }) {
  try {
      const response = yield requestUpdateAcknowledgeSystemTicket(payload);
      yield put(updateAcknowledgesystemTicketSuccess(response.data))
  } catch(error) {
      yield put (updateAcknowledgesystemTicketFailure("Failed to closed ticket"))
  }
}