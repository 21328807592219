import { put, takeLatest } from "redux-saga/effects";

import {
    requestInstallationCommissioningList,
    requestCreateInstallationCommissioning,
    requestUpdateInstallationCommissioning,
    requestDeleteInstallationCommissioning,
    requestInstallationCommissioningDataById,
} from "services/installationCommissioning";

import {
    fetchInstallationCommissioningListRequest,
    fetchInstallationCommissioningListSuccess,
    fetchInstallationCommissioningListFailure,
    createInstallationCommissioningRequest,
    createInstallationCommissioningSuccess,
    createInstallationCommissioningFailure,
    updateInstallationCommissioningRequest,
    updateInstallationCommissioningSuccess,
    updateInstallationCommissioningFailure,
    deleteInstallationCommissioningRequest,
    deleteInstallationCommissioningSuccess,
    deleteInstallationCommissioningFailure,
    getInstallationCommissioningByIdRequest,
    getInstallationCommissioningByIdSuccess,
    getInstallationCommissioningByIdFailure,
} from "redux/reducers/installationCommissioning";

export function* watchInstallationCommissioningSaga() {
    yield takeLatest(fetchInstallationCommissioningListRequest, fetchInstallationCommissioningList);
    yield takeLatest(createInstallationCommissioningRequest, createInstallationCommissioning);
    yield takeLatest(updateInstallationCommissioningRequest, updateInstallationCommissioning);
    yield takeLatest(deleteInstallationCommissioningRequest, deleteInstallationCommissioning);
    yield takeLatest(getInstallationCommissioningByIdRequest, getInstallationCommissioningById);
}

function* fetchInstallationCommissioningList({ payload }) {
    try {
        const response = yield requestInstallationCommissioningList(payload);
        yield put(fetchInstallationCommissioningListSuccess(response));
    } catch (error) {
        yield put(
            fetchInstallationCommissioningListFailure("Failed to get list of installationCommissioning")
        );
    }
}

function* createInstallationCommissioning({ payload }) {
    try {
        // service add
        const response = yield requestCreateInstallationCommissioning(payload);
        yield put(createInstallationCommissioningSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create installationCommissioning";
        yield put(createInstallationCommissioningFailure(message));
    }
}

function* updateInstallationCommissioning({ payload }) {
    try {
        const response = yield requestUpdateInstallationCommissioning(payload);
        yield put(updateInstallationCommissioningSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update installationCommissioning";
        yield put(updateInstallationCommissioningFailure(message));
    }
}

function* deleteInstallationCommissioning({ payload }) {
    try {
        const response = yield requestDeleteInstallationCommissioning(payload);
        yield put(deleteInstallationCommissioningSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete installationCommissioning";
        yield put(deleteInstallationCommissioningFailure(message));
    }
}

function* getInstallationCommissioningById({ payload }) {
    try {
        const response = yield requestInstallationCommissioningDataById(payload);
        yield put(getInstallationCommissioningByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get installationCommissioning details";
        yield put(getInstallationCommissioningByIdFailure(message));
    }
}