import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    questionsList: { loading: false, data: null, error: null },
    getMachineTypeList: { loading: false, data: null, error: null },
    createQuestions: { loading: false, data: null, error: null },
    updateQuestions: { loading: false, data: null, error: null },
    // deletePreventiveMaintenance: { loading: false, data: null, error: null },
    // getPreventiveMaintenanceById: { loading: false, data: null, error: null }
}

export const questionsSlice = createSlice({
    name: "questions",
    initialState,
    reducers: {
        //list question
        fetchQuestionsListRequest: (questions, action) => {
            questions.questionsList.loading = true;
            questions.questionsList.error = null;
        },
        fetchQuestionsListSuccess: (questions, action) => {
            questions.questionsList.data = action.payload;
            questions.questionsList.loading = false;
            questions.questionsList.error = null;
        },
        fetchQuestionsListFailure: (questions, action) => {
            questions.questionsList.data = null;
            questions.questionsList.loading = false;
            questions.questionsList.error = action.payload;
        },

        // create question
        createQuestionsRequest: (questions, action) => {
            questions.createQuestions.loading = true;
            questions.createQuestions.error = null;
        },
        createQuestionsSuccess: (questions, action) => {
            questions.createQuestions.data = action.payload;
            questions.createQuestions.loading = false;
            questions.createQuestions.error = null;
        },
        createQuestionsFailure: (questions, action) => {
            questions.createQuestions.data = null;
            questions.createQuestions.loading = false;
            questions.createQuestions.error = action.payload;
        },

        // update question details
        updateQuestionsRequest: (questions, action) => {
            questions.updateQuestions.loading = true;
            questions.updateQuestions.error = null;
        },
        updateQuestionsSuccess: (questions, action) => {
            questions.updateQuestions.data = action.payload;
            questions.updateQuestion.loading = false;
            questions.updateQuestion.error = null;
        },
        updateQuestionFailure: (questions, action) => {
            questions.updateQuestions.data = null;
            questions.updateQuestions.loading = false;
            questions.updateQuestions.error = action.payload;
        },

        // reset Questions
        resetQuestionsDetails: (questions, action) => {
            questions.createQuestions.data = null;
            questions.createQuestions.loading = false;
            questions.createQuestions.error = null;

            questions.updateQuestions.data = null;
            questions.updateQuestions.loading = false;
            questions.updateQuestions.error = null;

            questions.getQuestionsDetailsById.data = null;
            questions.getQuestionsDetailsById.loading = false;
            questions.getQuestionsDetailsById.error = null;
        },

        //get dropdown data for machine type 
        fetchMachineTypeListRequest: (questions, action) => {
            questions.getMachineTypeList.loading = true;
            questions.getMachineTypeList.error = null;
        },
        fetchMachineTypeListSuccess: (questions, action) => {
            questions.getMachineTypeList.data = action.payload;
            questions.getMachineTypeList.loading = false;
            questions.getMachineTypeList.error = null;
        },
        fetchMachineTypeListFailure: (questions, action) => {
            questions.getMachineTypeList.data = null;
            questions.getMachineTypeList.loading = false;
            questions.getMachineTypeList.error = action.payload;
        },
    }
})

export const {
    fetchQuestionsListRequest,
    fetchQuestionsListSuccess,
    fetchQuestionsListFailure,

    createQuestionsRequest,
    createQuestionsSuccess,
    createQuestionsFailure,

    updateQuestionsRequest,
    updateQuestionsSuccess,
    updateQuestionsFailure,

    fetchMachineTypeListRequest,
    fetchMachineTypeListSuccess,
    fetchMachineTypeListFailure,

    resetQuestionsDetails

} = questionsSlice.actions

export const getQuestionsListState = (store) =>
    store?.questions?.questionsList;
export const getQuestionCreateState = (store) =>
    store?.questions?.createQuestions;
export const getUpdateSparePartState = (store) =>
    store?.questions?.updateQuestions;
// export const getSparePartDeleteState = (store) =>
//     store?.questions?.deleteSparePart;
// export const getSparePartGetByIdState = (store) =>
//     store?.questions?.getSparePartById;
export const getMachineTypeListState = (store) =>
    store?.questions?.getMachineTypeList;
export default questionsSlice.reducer