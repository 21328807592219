import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  featureList: { loading: false, data: null, error: null },
  createManageFeature: { loading: false, data: null, error: null },
  updateManageFeature: { loading: false, data: null, error: null },
  deleteManageFeature: { loading: false, data: null, error: null },
  getFeatureDetailsById: { loading: false, data: null, error: null },
};

export const FeatureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    fetchFeatureListRequest: (feature, action) => {
      feature.featureList.loading = true;
      feature.featureList.error = null;
    },
    fetchFeatureListSuccess: (feature, action) => {
      feature.featureList.data = action.payload;
      feature.featureList.loading = false;
      feature.featureList.error = null;
    },
    fetchFeatureListFailure: (feature, action) => {
      feature.featureList.data = null;
      feature.featureList.loading = false;
      feature.featureList.error = action.payload;
    },

    // create feature
    createManageFeatureRequest: (feature, action) => {
      feature.createManageFeature.loading = true;
      feature.createManageFeature.error = null;
    },
    createManageFeatureSuccess: (feature, action) => {
      feature.createManageFeature.data = action.payload;
      feature.createManageFeature.loading = false;
      feature.createManageFeature.error = null;
      feature.featureList = addNewFeatureSuccess(action.payload, {
        ...feature.featureList,
      });
    },
    createManageFeatureFailure: (feature, action) => {
      feature.createManageFeature.data = null;
      feature.createManageFeature.loading = false;
      feature.createManageFeature.error = action.payload;
    },

    // update feature details
    updateManageFeatureRequest: (feature, action) => {
      feature.updateManageFeature.loading = true;
      feature.updateManageFeature.error = null;
    },
    updateManageFeatureSuccess: (feature, action) => {
      feature.updateManageFeature.data = action.payload;
      feature.updateManageFeature.loading = false;
      feature.updateManageFeature.error = null;
      feature.featureList = updateFeatureSuccess(
        action.payload,
        feature.featureList
      );
    },
    updateManageFeatureFailure: (feature, action) => {
      feature.updateManageFeature.data = null;
      feature.updateManageFeature.loading = false;
      feature.updateManageFeature.error = action.payload;
    },

    // delete feature
    deleteManageFeatureRequest: (feature, action) => {
      feature.deleteManageFeature.loading = true;
      feature.deleteManageFeature.error = null;
    },
    deleteManageFeatureSuccess: (feature, action) => {
      feature.deleteManageFeature.loading = false;
      feature.deleteManageFeature.error = null;
      feature.deleteManageFeature.data = action.payload;
      feature.featureList = deleteFeature(action.payload, {
        ...feature.featureList,
      });
    },
    deleteManageFeatureFailure: (feature, action) => {
      feature.deleteManageFeature.data = null;
      feature.deleteManageFeature.loading = false;
      feature.deleteManageFeature.error = action.payload;
    },

    // get by id feature details
    getFeatureDetailsRequest: (feature, action) => {
      feature.getFeatureDetailsById.loading = true;
      feature.getFeatureDetailsById.error = null;
    },
    getFeatureDetailsSuccess: (feature, action) => {
      feature.getFeatureDetailsById.loading = false;
      feature.getFeatureDetailsById.error = null;
      feature.getFeatureDetailsById.data = action.payload;
    },
    getFeatureDetailsFailure: (feature, action) => {
      feature.getFeatureDetailsById.data = null;
      feature.getFeatureDetailsById.loading = false;
      feature.getFeatureDetailsById.error = action.payload;
    },

    //Reset features state
    resetManageFeature: (feature, action) => {
      feature.createManageFeature.data = null;
      feature.createManageFeature.loading = false;
      feature.createManageFeature.error = null;

      feature.updateManageFeature.data = null;
      feature.updateManageFeature.loading = false;
      feature.updateManageFeature.error = null;

      feature.deleteManageFeature.data = null;
      feature.deleteManageFeature.loading = false;
      feature.deleteManageFeature.error = null;
    },
  },
});

// addNewFeature function
function addNewFeatureSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// updateFeature function
function updateFeatureSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deleteFeature function
function deleteFeature(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchFeatureListRequest,
  fetchFeatureListSuccess,
  fetchFeatureListFailure,

  createManageFeatureRequest,
  createManageFeatureSuccess,
  createManageFeatureFailure,

  updateManageFeatureRequest,
  updateManageFeatureSuccess,
  updateManageFeatureFailure,

  deleteManageFeatureRequest,
  deleteManageFeatureSuccess,
  deleteManageFeatureFailure,

  getFeatureDetailsRequest,
  getFeatureDetailsSuccess,
  getFeatureDetailsFailure,

  resetManageFeature,
} = FeatureSlice.actions;

export const getFeatureListState = (store) => store?.feature?.featureList;
export const getManageFeatureCreateState = (store) =>
  store?.feature?.createManageFeature;
export const getUpdateFeatureState = (store) =>
  store?.feature?.updateManageFeature;
export const getFeatureDeleteState = (store) =>
  store?.feature?.deleteManageFeature;
export const getFeatureGetByIdState = (store) =>
  store?.feature?.getFeatureDetailsById;
export default FeatureSlice.reducer;
