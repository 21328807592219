import { put, takeLatest } from "redux-saga/effects";
import { fetchLocationListRequest,
         fetchLocationListSuccess,
         fetchLocationListFailure
 } from "redux/reducers/location";
 import { requestLocationList } from "services/location";

 export function* watchLocationSaga() {
    yield takeLatest(fetchLocationListRequest, fetchLocationList);
}

function* fetchLocationList({ payload }) {
    try {
        const response = yield requestLocationList(payload);
        yield put(fetchLocationListSuccess(response));
    } catch (error) {
        yield put(
            fetchLocationListFailure("Failed to get list of location")
        )
    }
}