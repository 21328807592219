import { put, takeLatest } from "redux-saga/effects";

import {
    requestBreakdownMaintenanceList,
    requestCreateBreakdownMaintenance,
    requestUpdateBreakdownMaintenance,
    requestDeleteBreakdownMaintenance,
    requestBreakdownMaintenanceDataById,
} from "services/breakdownMaintenance";

import {
    fetchBreakdownMaintenanceListRequest,
    fetchBreakdownMaintenanceListSuccess,
    fetchBreakdownMaintenanceListFailure,
    createBreakdownMaintenanceRequest,
    createBreakdownMaintenanceSuccess,
    createBreakdownMaintenanceFailure,
    updateBreakdownMaintenanceRequest,
    updateBreakdownMaintenanceSuccess,
    updateBreakdownMaintenanceFailure,
    deleteBreakdownMaintenanceRequest,
    deleteBreakdownMaintenanceSuccess,
    deleteBreakdownMaintenanceFailure,
    getBreakdownMaintenanceByIdRequest,
    getBreakdownMaintenanceByIdSuccess,
    getBreakdownMaintenanceByIdFailure,
} from "redux/reducers/breakdownMaintenance";

export function* watchBreakdownMaintenanceSaga() {
    yield takeLatest(fetchBreakdownMaintenanceListRequest, fetchBreakdownMaintenanceList);
    yield takeLatest(createBreakdownMaintenanceRequest, createBreakdownMaintenance);
    yield takeLatest(updateBreakdownMaintenanceRequest, updateBreakdownMaintenance);
    yield takeLatest(deleteBreakdownMaintenanceRequest, deleteBreakdownMaintenance);
    yield takeLatest(getBreakdownMaintenanceByIdRequest, getBreakdownMaintenanceById);
}

function* fetchBreakdownMaintenanceList({ payload }) {
    try {
        const response = yield requestBreakdownMaintenanceList(payload);
        yield put(fetchBreakdownMaintenanceListSuccess(response));
    } catch (error) {
        yield put(
            fetchBreakdownMaintenanceListFailure("Failed to get list of breakdownMaintenance")
        );
    }
}

function* createBreakdownMaintenance({ payload }) {
    try {
        // service add
        const response = yield requestCreateBreakdownMaintenance(payload);
        yield put(createBreakdownMaintenanceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create breakdownMaintenance";
        yield put(createBreakdownMaintenanceFailure(message));
    }
}

function* updateBreakdownMaintenance({ payload }) {
    try {
        const response = yield requestUpdateBreakdownMaintenance(payload);
        yield put(updateBreakdownMaintenanceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update breakdownMaintenance";
        yield put(updateBreakdownMaintenanceFailure(message));
    }
}

function* deleteBreakdownMaintenance({ payload }) {
    try {
        const response = yield requestDeleteBreakdownMaintenance(payload);
        yield put(deleteBreakdownMaintenanceSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete breakdownMaintenance";
        yield put(deleteBreakdownMaintenanceFailure(message));
    }
}

function* getBreakdownMaintenanceById({ payload }) {
    try {
        const response = yield requestBreakdownMaintenanceDataById(payload);
        yield put(getBreakdownMaintenanceByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get breakdownMaintenance details";
        yield put(getBreakdownMaintenanceByIdFailure(message));
    }
}