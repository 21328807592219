import { put, takeLatest } from "redux-saga/effects";

import {
   requestOrganizationList,
   requestCreateOrganization,
   requestUpdateOrganization,
   requestDeleteOrganization,
   requestOrganizationDataById,
} from "services/organization";


import {
   fetchManageSiteListRequest,
   fetchManageSiteListSuccess,
   fetchManageSiteListFailure,
   createManageSiteRequest,
   createManageSiteSuccess,
   createManageSiteFailure,
   updateManageSiteRequest,
   updateManageSiteSuccess,
   updateManageSiteFailure,
   deleteManageSiteRequest,
   deleteManageSiteSuccess,
   deleteManageSiteFailure,
   getManageSiteByIdRequest,
   getManageSiteByIdSuccess,
   getManageSiteByIdFailure
} from "redux/reducers/siteManage";

export function* watchManageSiteSaga() {
   yield takeLatest(fetchManageSiteListRequest, fetchManageSiteList)
   yield takeLatest(createManageSiteRequest, createManageSite)
   yield takeLatest(updateManageSiteRequest, updateManageSite)
   yield takeLatest(deleteManageSiteRequest, deleteManageSite)
   yield takeLatest(getManageSiteByIdRequest, getManageSiteById)
}

function* fetchManageSiteList({ payload }) {
   try {
      const response = yield requestOrganizationList(payload);
      yield put(fetchManageSiteListSuccess(response));
   }
   catch (error) {
      yield put(
         fetchManageSiteListFailure("Failed to get list of manage site")
      );
   }
}

function* createManageSite({ payload }) {
   try {
      // service add
      const response = yield requestCreateOrganization(payload);
      yield put(createManageSiteSuccess(response.data));
   }
   catch (error) {
      yield put(createManageSiteFailure("Failed to create manage site"));
   }
}

function* updateManageSite({ payload }) {
   try {
      const response = yield requestUpdateOrganization(payload);
      yield put(updateManageSiteSuccess(response.data));
   }
   catch (error) {
      yield put(updateManageSiteFailure("Failed to update manage site"));
   }
}

function* deleteManageSite({ payload }) {
   try {
      const response = yield requestDeleteOrganization(payload);
      yield put(deleteManageSiteSuccess(response.data));
   }
   catch (error) {
      const message =
         error?.response?.data?.data?.message || "Failed to delete manage site";
      yield put(deleteManageSiteFailure(message));
   }
}

function* getManageSiteById({ payload }) {
   try {
      const response = yield requestOrganizationDataById(payload);
      yield put(getManageSiteByIdSuccess(response));
   }
   catch (error) {
      const message =
         error?.response?.data?.data?.message || "Failed to get manage site details";
      yield put(getManageSiteByIdFailure(message));
   }
}