import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    checklistList: { loading: false, data: null, error: null },
    createChecklist: { loading: false, data: null, error: null },
    updateChecklist: { loading: false, data: null, error: null },
    deleteChecklist: { loading: false, data: null, error: null },
    getChecklistById: { loading: false, data: null, error: null }
}

export const checklistSlice = createSlice({
    name: "checklist",
    initialState,
    reducers: {
        fetchChecklistListRequest: (checklist, action) => {
            checklist.checklistList.loading = true;
            checklist.checklistList.error = null;
        },
        fetchChecklistListSuccess: (checklist, action) => {
            checklist.checklistList.data = action.payload;
            checklist.checklistList.loading = false;
            checklist.checklistList.error = null;
        },
        fetchChecklistListFailure: (checklist, action) => {
            checklist.checklistList.data = null;
            checklist.checklistList.loading = false;
            checklist.checklistList.error = action.payload;
        },

        //create Checklist
        createChecklistRequest: (checklist, action) => {
            checklist.createChecklist.loading = true;
            checklist.createChecklist.error = null;
        },
        createChecklistSuccess: (checklist, action) => {
            checklist.createChecklist.data = action.payload;
            checklist.createChecklist.loading = false;
            checklist.createChecklist.error = null;
        },
        createChecklistFailure: (checklist, action) => {
            checklist.createChecklist.data = null;
            checklist.createChecklist.loading = false;
            checklist.createChecklist.error = action.payload;
        },

        //update checklist details
        updateChecklistRequest: (checklist, action) => {
            checklist.updateChecklist.loading = true;
            checklist.updateChecklist.error = null;
        },
        updateChecklistSuccess: (checklist, action) => {
            checklist.updateChecklist.data = action.payload;
            checklist.updateChecklist.loading = false;
            checklist.updateChecklist.error = null;
        },
        updateChecklistFailure: (checklist, action) => {
            checklist.updateChecklist.data = null;
            checklist.updateChecklist.loading = false;
            checklist.updateChecklist.error = action.payload;
        },

        // delete checklist
        deleteChecklistRequest: (checklist, action) => {
            checklist.deleteChecklist.loading = true;
            checklist.deleteChecklist.error = null;
        },
        deleteChecklistSuccess: (checklist, action) => {
            checklist.deleteChecklist.data = action.payload;
            checklist.deleteChecklist.loading = false;
            checklist.deleteChecklist.error = null;
            checklist.checklistList = deleteNewChecklistSuccess(action.payload, {
                ...checklist.checklistList,
            })
        },
        deleteChecklistFailure: (checklist, action) => {
            checklist.deleteChecklist.data = null;
            checklist.deleteChecklist.loading = false;
            checklist.deleteChecklist.error = action.payload;
        },

        // getById checklist details
        getChecklistByIdRequest: (checklist, action) => {
            checklist.getChecklistById.loading = true;
            checklist.getChecklistById.error = null;
        },
        getChecklistByIdSuccess: (checklist, action) => {
            checklist.getChecklistById.data = action.payload;
            checklist.getChecklistById.loading = false;
            checklist.getChecklistById.error = null;
        },
        getChecklistByIdFailure: (checklist, action) => {
            checklist.getChecklistById.data = null;
            checklist.getChecklistById.loading = false;
            checklist.getChecklistById.error = action.payload;
        },

        // reset checklist details
        resetChecklist: (checklist, action) => {
            checklist.createChecklist.data = null;
            checklist.createChecklist.loading = false;
            checklist.createChecklist.error = null;

            checklist.updateChecklist.data = null;
            checklist.updateChecklist.loading = false;
            checklist.updateChecklist.error = null;

            checklist.deleteChecklist.data = null;
            checklist.deleteChecklist.loading = false;
            checklist.deleteChecklist.error = null;
        }
    }
})

function updateNewChecklistSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewChecklistSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchChecklistListRequest,
    fetchChecklistListSuccess,
    fetchChecklistListFailure,

    createChecklistRequest,
    createChecklistSuccess,
    createChecklistFailure,

    updateChecklistRequest,
    updateChecklistSuccess,
    updateChecklistFailure,

    deleteChecklistRequest,
    deleteChecklistSuccess,
    deleteChecklistFailure,

    getChecklistByIdRequest,
    getChecklistByIdSuccess,
    getChecklistByIdFailure,

    resetChecklist,
} = checklistSlice.actions

export const getChecklistListState = (store) =>
    store?.checklist?.checklistList;
export const getChecklistCreateState = (store) =>
    store?.checklist?.createChecklist;
export const getChecklistUpdateState = (store) =>
    store?.checklist?.updateChecklist;
export const getChecklistDeleteState = (store) =>
    store?.checklist?.deleteChecklist;
export const getChecklistGetByIdState = (store) =>
    store?.checklist?.getChecklistById;

export default checklistSlice.reducer