import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breakdownMaintenanceList: { loading: false, data: null, error: null },
  createBreakdownMaintenance: { loading: false, data: null, error: null },
  updateBreakdownMaintenance: { loading: false, data: null, error: null },
  deleteBreakdownMaintenance: { loading: false, data: null, error: null },
  getBreakdownMaintenanceById: { loading: false, data: null, error: null },
};

export const breakdownMaintenanceSlice = createSlice({
  name: "breakdownMaintenance",
  initialState,
  reducers: {
    fetchBreakdownMaintenanceListRequest: (breakdownMaintenance, action) => {
      breakdownMaintenance.breakdownMaintenanceList.loading = true;
      breakdownMaintenance.breakdownMaintenanceList.error = null;
    },
    fetchBreakdownMaintenanceListSuccess: (breakdownMaintenance, action) => {
      breakdownMaintenance.breakdownMaintenanceList.data = action.payload;
      breakdownMaintenance.breakdownMaintenanceList.loading = false;
      breakdownMaintenance.breakdownMaintenanceList.error = null;
    },
    fetchBreakdownMaintenanceListFailure: (breakdownMaintenance, action) => {
      breakdownMaintenance.breakdownMaintenanceList.data = null;
      breakdownMaintenance.breakdownMaintenanceList.loading = false;
      breakdownMaintenance.breakdownMaintenanceList.error = action.payload;
    },

    //create BreakdownMaintenance
    createBreakdownMaintenanceRequest: (breakdownMaintenance, action) => {
      breakdownMaintenance.createBreakdownMaintenance.loading = true;
      breakdownMaintenance.createBreakdownMaintenance.error = null;
    },
    createBreakdownMaintenanceSuccess: (breakdownMaintenance, action) => {
      breakdownMaintenance.createBreakdownMaintenance.data = action.payload;
      breakdownMaintenance.createBreakdownMaintenance.loading = false;
      breakdownMaintenance.createBreakdownMaintenance.error = null;
      breakdownMaintenance.breakdownMaintenanceList =
        addNewBreakdownMaintenanceSuccess(action.payload, {
          ...breakdownMaintenance.breakdownMaintenanceList,
        });
    },
    createBreakdownMaintenanceFailure: (breakdownMaintenance, action) => {
      breakdownMaintenance.createBreakdownMaintenance.data = null;
      breakdownMaintenance.createBreakdownMaintenance.loading = false;
      breakdownMaintenance.createBreakdownMaintenance.error = action.payload;
    },

    //update breakdownMaintenance details
    updateBreakdownMaintenanceRequest: (breakdownMaintenance, action) => {
      breakdownMaintenance.updateBreakdownMaintenance.loading = true;
      breakdownMaintenance.updateBreakdownMaintenance.error = null;
    },
    updateBreakdownMaintenanceSuccess: (breakdownMaintenance, action) => {
      breakdownMaintenance.updateBreakdownMaintenance.data = action.payload;
      breakdownMaintenance.updateBreakdownMaintenance.loading = false;
      breakdownMaintenance.updateBreakdownMaintenance.error = null;
      breakdownMaintenance.breakdownMaintenanceList =
        updateNewBreakdownMaintenanceSuccess(
          action.payload,
          breakdownMaintenance.breakdownMaintenanceList
        );
    },
    updateBreakdownMaintenanceFailure: (breakdownMaintenance, action) => {
      breakdownMaintenance.updateBreakdownMaintenance.data = null;
      breakdownMaintenance.updateBreakdownMaintenance.loading = false;
      breakdownMaintenance.updateBreakdownMaintenance.error = action.payload;
    },

    // delete breakdownMaintenance
    deleteBreakdownMaintenanceRequest: (breakdownMaintenance, action) => {
      breakdownMaintenance.deleteBreakdownMaintenance.loading = true;
      breakdownMaintenance.deleteBreakdownMaintenance.error = null;
    },
    deleteBreakdownMaintenanceSuccess: (breakdownMaintenance, action) => {
      breakdownMaintenance.deleteBreakdownMaintenance.data = action.payload;
      breakdownMaintenance.deleteBreakdownMaintenance.loading = false;
      breakdownMaintenance.deleteBreakdownMaintenance.error = null;
      breakdownMaintenance.breakdownMaintenanceList =
        deleteNewBreakdownMaintenanceSuccess(action.payload, {
          ...breakdownMaintenance.breakdownMaintenanceList,
        });
    },
    deleteBreakdownMaintenanceFailure: (breakdownMaintenance, action) => {
      breakdownMaintenance.deleteBreakdownMaintenance.data = null;
      breakdownMaintenance.deleteBreakdownMaintenance.loading = false;
      breakdownMaintenance.deleteBreakdownMaintenance.error = action.payload;
    },

    // getById breakdownMaintenance details
    getBreakdownMaintenanceByIdRequest: (breakdownMaintenance, action) => {
      breakdownMaintenance.getBreakdownMaintenanceById.loading = true;
      breakdownMaintenance.getBreakdownMaintenanceById.error = null;
    },
    getBreakdownMaintenanceByIdSuccess: (breakdownMaintenance, action) => {
      breakdownMaintenance.getBreakdownMaintenanceById.data = action.payload;
      breakdownMaintenance.getBreakdownMaintenanceById.loading = false;
      breakdownMaintenance.getBreakdownMaintenanceById.error = null;
    },
    getBreakdownMaintenanceByIdFailure: (breakdownMaintenance, action) => {
      breakdownMaintenance.getBreakdownMaintenanceById.data = null;
      breakdownMaintenance.getBreakdownMaintenanceById.loading = false;
      breakdownMaintenance.getBreakdownMaintenanceById.error = action.payload;
    },

    // reset breakdownMaintenance details
    resetBreakdownMaintenance: (breakdownMaintenance, action) => {
      breakdownMaintenance.createBreakdownMaintenance.data = null;
      breakdownMaintenance.createBreakdownMaintenance.loading = false;
      breakdownMaintenance.createBreakdownMaintenance.error = null;

      breakdownMaintenance.updateBreakdownMaintenance.data = null;
      breakdownMaintenance.updateBreakdownMaintenance.loading = false;
      breakdownMaintenance.updateBreakdownMaintenance.error = null;

      breakdownMaintenance.deleteBreakdownMaintenance.data = null;
      breakdownMaintenance.deleteBreakdownMaintenance.loading = false;
      breakdownMaintenance.deleteBreakdownMaintenance.error = null;

      breakdownMaintenance.getBreakdownMaintenanceById.data = null;
      breakdownMaintenance.getBreakdownMaintenanceById.loading = false;
      breakdownMaintenance.getBreakdownMaintenanceById.error = null;
    },
  },
});

function addNewBreakdownMaintenanceSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewBreakdownMaintenanceSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewBreakdownMaintenanceSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchBreakdownMaintenanceListRequest,
  fetchBreakdownMaintenanceListSuccess,
  fetchBreakdownMaintenanceListFailure,

  createBreakdownMaintenanceRequest,
  createBreakdownMaintenanceSuccess,
  createBreakdownMaintenanceFailure,

  updateBreakdownMaintenanceRequest,
  updateBreakdownMaintenanceSuccess,
  updateBreakdownMaintenanceFailure,

  deleteBreakdownMaintenanceRequest,
  deleteBreakdownMaintenanceSuccess,
  deleteBreakdownMaintenanceFailure,

  getBreakdownMaintenanceByIdRequest,
  getBreakdownMaintenanceByIdSuccess,
  getBreakdownMaintenanceByIdFailure,

  resetBreakdownMaintenance,
} = breakdownMaintenanceSlice.actions;

export const getBreakdownMaintenanceListState = (store) =>
  store?.breakdownMaintenance?.breakdownMaintenanceList;
export const getBreakdownMaintenanceCreateState = (store) =>
  store?.breakdownMaintenance?.createBreakdownMaintenance;
export const getBreakdownMaintenanceUpdateState = (store) =>
  store?.breakdownMaintenance?.updateBreakdownMaintenance;
export const getBreakdownMaintenanceDeleteState = (store) =>
  store?.breakdownMaintenance?.deleteBreakdownMaintenance;
export const getBreakdownMaintenanceGetByIdState = (store) =>
  store?.breakdownMaintenance?.getBreakdownMaintenanceById;
export default breakdownMaintenanceSlice.reducer;
