import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestFeatureList(params) {
  return fetch.get(`${BASE_URL}/api/app/feature`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}

export function requestCreateFeature(data) {
  return fetch.post(`${BASE_URL}/api/app/feature`, data);
}

export function requestUpdateFeature(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/feature/${data._id}`, body);
}

export function requestDeleteFeature(data) {
  return fetch.delete(`${BASE_URL}/api/app/feature/${data}`);
}

export function requestFeatureDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/feature/getById/${data.id}`);
}