import { put, takeLatest } from "redux-saga/effects";
import {
    requestDiscussionList,
    requestCreateDiscussion,
    requestUpdateDiscussion,
    requestDeleteDiscussion,
    requestDiscussionDataById,
} from "services/discussion";

import {
    fetchDiscussionListRequest,
    fetchDiscussionListSuccess,
    fetchDiscussionListFailure,
    createDiscussionRequest,
    createDiscussionSuccess,
    createDiscussionFailure,
    updateDiscussionRequest,
    updateDiscussionSuccess,
    updateDiscussionFailure,
    deleteDiscussionRequest,
    deleteDiscussionSuccess,
    deleteDiscussionFailure,
    getDiscussionByIdRequest,
    getDiscussionByIdSuccess,
    getDiscussionByIdFailure,
} from "redux/reducers/discussion";

export function* watchDiscussionSaga() {
    yield takeLatest(fetchDiscussionListRequest, fetchDiscussionList);
    yield takeLatest(createDiscussionRequest, createDiscussion);
    yield takeLatest(updateDiscussionRequest, updateDiscussion);
    yield takeLatest(deleteDiscussionRequest, deleteDiscussion);
    yield takeLatest(getDiscussionByIdRequest, getDiscussionById);
}

function* fetchDiscussionList({ payload }) {
    try {
        const response = yield requestDiscussionList(payload);
        yield put(fetchDiscussionListSuccess(response));
    } catch (error) {
        yield put(
            fetchDiscussionListFailure("Failed to get list of discussion")
        );
    }
}

function* createDiscussion({ payload }) {
    try {
        // service add
        const response = yield requestCreateDiscussion(payload);
        yield put(createDiscussionSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create discussion";
        yield put(createDiscussionFailure(message));
    }
}

function* updateDiscussion({ payload }) {
    try {
        const response = yield requestUpdateDiscussion(payload);
        yield put(updateDiscussionSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update discussion";
        yield put(updateDiscussionFailure(message));
    }
}

function* deleteDiscussion({ payload }) {
    try {
        const response = yield requestDeleteDiscussion(payload);
        yield put(deleteDiscussionSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete discussion";
        yield put(deleteDiscussionFailure(message));
    }
}

function* getDiscussionById({ payload }) {
    try {
        const response = yield requestDiscussionDataById(payload);
        yield put(getDiscussionByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get discussion details";
        yield put(getDiscussionByIdFailure(message));
    }
}