import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSensorProductList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/sensor-product`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/sensor-product?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateSensorProduct(data) {
  return fetch.post(`${BASE_URL}/api/app/sensor-product`, data);
}

export function requestUpdateSensorProduct(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/sensor-product/${data._id}`, body);
}

export function requestDeleteSensorProduct(data) {
  return fetch.delete(`${BASE_URL}/api/app/sensor-product/${data}`);
}

export function requestSensorProductDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/sensor-product/getById/${data.id}`);
}

// Manufacturer dropdown list
export function requestManufacturerDropDownList(params) {
  return fetch.get(`${BASE_URL}/api/app/sensor-product/getManufacturerData`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}
