import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestClientList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/client`, {
            params: {limit: Number.MAX_SAFE_INTEGER},
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/client?${params}`, {
            params: {limit: Number.MAX_SAFE_INTEGER},
        });
    }
}
export function requestCreateClient(data) {
    return fetch.post(`${BASE_URL}/api/app/client`, data);
  }
  
  export function requestUpdateClient(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/client/${data._id}`, body);
  }
   
  export function requestClientDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/client/getById/${data.id}`);
  }