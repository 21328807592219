import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSvcMachineInstanceList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/svc-machine-instances`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/svc-machine-instances?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateSvcMachineInstance(data) {
  return fetch.post(`${BASE_URL}/api/app/svc-machine-instances/`, data);
}

export function requestUpdateSvcMachineInstance(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/app/svc-machine-instances/${data._id}`,
    body
  );
}

export function requestDeleteSvcMachineInstance(data) {
  return fetch.delete(`${BASE_URL}/api/app/svc-machine-instances/${data}`);
}

export function requestSvcMachineInstanceDataById(data) {
  return fetch.get(
    `${BASE_URL}/api/app/svc-machine-instances/getById/${data.id}`
  );
}
