import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    masterConfigList: { loading: false, data: null, error: null }
}

export const masterConfigSlice = createSlice({
    name: "masterConfig",
    initialState,
    reducers: {
        fetchMasterConfigListRequest: (masterConfig, action) => {
            masterConfig.masterConfigList.loading = true;
            masterConfig.masterConfigList.error = null;
        },
        fetchMasterConfigListSuccess: (masterConfig, action) => {
            masterConfig.masterConfigList.data = action.payload;
            masterConfig.masterConfigList.loading = false;
            masterConfig.masterConfigList.error = null
        },
        fetchMasterConfigListFailure: (masterConfig, action) => {
            masterConfig.masterConfigList.data = null;
            masterConfig.masterConfigList.loading = false;
            masterConfig.masterConfigList.error = action.payload;
        }
    }
})

export const {
    fetchMasterConfigListRequest,
    fetchMasterConfigListSuccess,
    fetchMasterConfigListFailure
} = masterConfigSlice.actions

export const getMasterConfigListState = (store) =>
    store?.masterConfig?.masterConfigList;
export default masterConfigSlice.reducer;