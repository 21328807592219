import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestTicketList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/system-ticket`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/system-ticket?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}
export function requestUpdateSystemTicket(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(
    `${BASE_URL}/api/app/system-ticket/acknowledgedTicketState/${data._id}`,
    body
  );
}

export function requestUpdateAcknowledgeSystemTicket(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/system-ticket/${data._id}`, body);
}

export function requestSystemTicketKPIS(params) {
  if (params === undefined) {
    return fetch.post(`${BASE_URL}/api/app/dashboard-charts/systemTicketKpi`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.post(
      `${BASE_URL}/api/app/dashboard-charts/systemTicketKpi?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}

export function requestAssetName(params) {
  if (params === undefined) {
    return fetch.get(
      `${BASE_URL}/api/app/system-ticket/getAssetNameFromDevicePath`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/system-ticket/getAssetNameFromDevicePath?${params}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  }
}
