import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestActionNoteList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/action-note`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/action-note?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER }
        });
    }
}

export function requestCreateActionNote(data) {
    return fetch.post(`${BASE_URL}/api/app/action-note`, data)
}

export function requestUpdateActionNote(data) {
    const body = { ...data };
    delete body["_id"];
    return fetch.put(`${BASE_URL}/api/app/action-note/${data._id}`, body);
}

export function requestDeleteActionNote(data) {
    return fetch.delete(`${BASE_URL}/api/app/action-note/${data}`);
}

export function requestActionNoteDataById(data) {
    return fetch.get(`${BASE_URL}/api/app/action-note/getById/${data.id}`);
}