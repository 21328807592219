import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSvcMachineTypeList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/svc-machine-type/`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/svc-machine-type/?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateSvcMachineType(data) {
  return fetch.post(`${BASE_URL}/api/app/svc-machine-type/`, data);
}

export function requestUpdateSvcMachineType(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/svc-machine-type/${data._id}`, body);
}

export function requestDeleteSvcMachineType(data) {
  return fetch.delete(`${BASE_URL}/api/app/svc-machine-type/${data}`);
}

export function requestSvcMachineTypeDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/svc-machine-type/getById/${data.id}`);
}

// Manufacturer dropdown list
export function requestManufacturerDropDownList(params) {
  return fetch.get(
    `${BASE_URL}/api/app/svc-machine-type/getManufacturerMachineData`,
    {
      params: { limit: Number.MAX_SAFE_INTEGER },
    }
  );
}
