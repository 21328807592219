import { put, takeLatest } from "redux-saga/effects";
import {
    fetchMachineListFailure,
    fetchMachineListRequest,
    fetchMachineListSuccess
} from "redux/reducers/machine";
import {
    requestMachineList
} from "services/machine";

export function* watchMachineSaga() {
    yield takeLatest(fetchMachineListRequest, fetchMachineList);
}

function* fetchMachineList({ payload }) {
    try {
        const response = yield requestMachineList(payload);
        yield put(fetchMachineListSuccess(response));
    } catch (error) {
        yield put(
            fetchMachineListFailure("Failed to get list of machine")
        )
    }
}