import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  svcMachineInstanceList: { loading: false, data: null, error: null },
  createSvcMachineInstance: { loading: false, data: null, error: null },
  updateSvcMachineInstance: { loading: false, data: null, error: null },
  deleteSvcMachineInstance: { loading: false, data: null, error: null },
  getSvcMachineInstanceById: { loading: false, data: null, error: null },
};

export const SvcMachineInstanceSlice = createSlice({
  name: "svcMachineInstance",
  initialState,
  reducers: {
    fetchSvcMachineInstanceListRequest: (svcMachineInstance, action) => {
      svcMachineInstance.svcMachineInstanceList.loading = true;
      svcMachineInstance.svcMachineInstanceList.error = null;
    },
    fetchSvcMachineInstanceListSuccess: (svcMachineInstance, action) => {
      svcMachineInstance.svcMachineInstanceList.data = action.payload;
      svcMachineInstance.svcMachineInstanceList.loading = false;
      svcMachineInstance.svcMachineInstanceList.error = null;
    },
    fetchSvcMachineInstanceListFailure: (svcMachineInstance, action) => {
      svcMachineInstance.svcMachineInstanceList.data = null;
      svcMachineInstance.svcMachineInstanceList.loading = false;
      svcMachineInstance.svcMachineInstanceList.error = action.payload;
    },

    // create svcMachineInstance
    createSvcMachineInstanceRequest: (svcMachineInstance, action) => {
      svcMachineInstance.createSvcMachineInstance.loading = true;
      svcMachineInstance.createSvcMachineInstance.error = null;
    },
    createSvcMachineInstanceSuccess: (svcMachineInstance, action) => {
      svcMachineInstance.createSvcMachineInstance.data = action.payload;
      svcMachineInstance.createSvcMachineInstance.loading = false;
      svcMachineInstance.createSvcMachineInstance.error = null;
      svcMachineInstance.svcMachineInstanceList = addNewInstanceSuccess(
        action.payload,
        {
          ...svcMachineInstance.svcMachineInstanceList,
        }
      );
    },
    createSvcMachineInstanceFailure: (svcMachineInstance, action) => {
      svcMachineInstance.createSvcMachineInstance.data = null;
      svcMachineInstance.createSvcMachineInstance.loading = false;
      svcMachineInstance.createSvcMachineInstance.error = action.payload;
    },

    // update svcMachineInstance details
    updateSvcMachineInstanceRequest: (svcMachineInstance, action) => {
      svcMachineInstance.updateSvcMachineInstance.loading = true;
      svcMachineInstance.updateSvcMachineInstance.error = null;
    },
    updateSvcMachineInstanceSuccess: (svcMachineInstance, action) => {
      svcMachineInstance.updateSvcMachineInstance.data = action.payload;
      svcMachineInstance.updateSvcMachineInstance.loading = false;
      svcMachineInstance.updateSvcMachineInstance.error = null;
      svcMachineInstance.svcMachineInstanceList = updateInstanceSuccess(
        action.payload,
        svcMachineInstance.svcMachineInstanceList
      );
    },
    updateSvcMachineInstanceFailure: (svcMachineInstance, action) => {
      svcMachineInstance.updateSvcMachineInstance.data = null;
      svcMachineInstance.updateSvcMachineInstance.loading = false;
      svcMachineInstance.updateSvcMachineInstance.error = action.payload;
    },

    // delete svcMachineInstance
    deleteSvcMachineInstanceRequest: (svcMachineInstance, action) => {
      svcMachineInstance.deleteSvcMachineInstance.loading = true;
      svcMachineInstance.deleteSvcMachineInstance.error = null;
    },
    deleteSvcMachineInstanceSuccess: (svcMachineInstance, action) => {
      svcMachineInstance.deleteSvcMachineInstance.loading = false;
      svcMachineInstance.deleteSvcMachineInstance.error = null;
      svcMachineInstance.deleteSvcMachineInstance.data = action.payload;
      svcMachineInstance.svcMachineInstanceList = deleteInstance(
        action.payload,
        {
          ...svcMachineInstance.svcMachineInstanceList,
        }
      );
    },
    deleteSvcMachineInstanceFailure: (svcMachineInstance, action) => {
      svcMachineInstance.deleteSvcMachineInstance.data = null;
      svcMachineInstance.deleteSvcMachineInstance.loading = false;
      svcMachineInstance.deleteSvcMachineInstance.error = action.payload;
    },

    // get by id svcMachineInstance details
    getSvcMachineInstanceDetailsRequest: (svcMachineInstance, action) => {
      svcMachineInstance.getSvcMachineInstanceById.loading = true;
      svcMachineInstance.getSvcMachineInstanceById.error = null;
    },
    getSvcMachineInstanceDetailsSuccess: (svcMachineInstance, action) => {
      svcMachineInstance.getSvcMachineInstanceById.loading = false;
      svcMachineInstance.getSvcMachineInstanceById.error = null;
      svcMachineInstance.getSvcMachineInstanceById.data = action.payload;
    },
    getSvcMachineInstanceDetailsFailure: (svcMachineInstance, action) => {
      svcMachineInstance.getSvcMachineInstanceById.data = null;
      svcMachineInstance.getSvcMachineInstanceById.loading = false;
      svcMachineInstance.getSvcMachineInstanceById.error = action.payload;
    },

    //Reset svcMachineInstance state
    resetSvcMachineInstance: (svcMachineInstance, action) => {
      svcMachineInstance.createSvcMachineInstance.data = null;
      svcMachineInstance.createSvcMachineInstance.loading = false;
      svcMachineInstance.createSvcMachineInstance.error = null;

      svcMachineInstance.updateSvcMachineInstance.data = null;
      svcMachineInstance.updateSvcMachineInstance.loading = false;
      svcMachineInstance.updateSvcMachineInstance.error = null;

      svcMachineInstance.deleteSvcMachineInstance.data = null;
      svcMachineInstance.deleteSvcMachineInstance.loading = false;
      svcMachineInstance.deleteSvcMachineInstance.error = null;
    },
  },
});

// addNew instance function
function addNewInstanceSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// update instance function
function updateInstanceSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// delete instance function
function deleteInstance(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchSvcMachineInstanceListRequest,
  fetchSvcMachineInstanceListSuccess,
  fetchSvcMachineInstanceListFailure,

  createSvcMachineInstanceRequest,
  createSvcMachineInstanceSuccess,
  createSvcMachineInstanceFailure,

  updateSvcMachineInstanceRequest,
  updateSvcMachineInstanceSuccess,
  updateSvcMachineInstanceFailure,

  deleteSvcMachineInstanceRequest,
  deleteSvcMachineInstanceSuccess,
  deleteSvcMachineInstanceFailure,

  getSvcMachineInstanceDetailsRequest,
  getSvcMachineInstanceDetailsSuccess,
  getSvcMachineInstanceDetailsFailure,

  resetSvcMachineInstance,
} = SvcMachineInstanceSlice.actions;

export const getSvcMachineInstanceListState = (store) =>
  store?.svcMachineInstance?.svcMachineInstanceList;
export const getSvcMachineInstanceCreateState = (store) =>
  store?.svcMachineInstance?.createSvcMachineInstance;
export const getUpdateSvcMachineInstanceState = (store) =>
  store?.svcMachineInstance?.updateSvcMachineInstance;
export const getSvcMachineInstanceDeleteState = (store) =>
  store?.svcMachineInstance?.deleteSvcMachineInstance;
export const getSvcMachineInstanceGetByIdState = (store) =>
  store?.svcMachineInstance?.getSvcMachineInstanceById;
export default SvcMachineInstanceSlice.reducer;
