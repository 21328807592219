import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    locationList: { loading: false, data: null, error: null }
}

export const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        fetchLocationListRequest: (location, action) => {
            location.locationList.loading = true;
            location.locationList.error = null;
        },
        fetchLocationListSuccess: (location, action) => {
            location.locationList.data = action.payload;
            location.locationList.loading = false;
            location.locationList.error = null
        },
        fetchLocationListFailure: (location, action) => {
            location.locationList.data = null;
            location.locationList.loading = false;
            location.locationList.error = action.payload;
        }
    }
})

export const {
    fetchLocationListRequest,
    fetchLocationListSuccess,
    fetchLocationListFailure
} = locationSlice.actions

export const getLocationListState = (store) =>
    store?.location?.locationList;
export default locationSlice.reducer;