import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestPrivilegeList(params) {
  return fetch.get(`${BASE_URL}/api/app/privilege`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}

export function requestCreatePrivilege(data) {
  return fetch.post(`${BASE_URL}/api/app/privilege`, data);
}

export function requestUpdatePrivilege(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/privilege/${data._id}`, body);
}

export function requestDeletePrivilege(data) {
  return fetch.delete(`${BASE_URL}/api/app/privilege/${data}`);
}

export function requestPrivilegeDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/privilege/getById/${data.id}`);
}
