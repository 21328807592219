import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestSensorInstanceUnitList(params) {
    if (params === undefined) {
        return fetch.get(`${BASE_URL}/api/app/sensor-instance/getUnitListByUserAccess`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    } else {
        return fetch.get(`${BASE_URL}/api/app/sensor-instance/getUnitListByUserAccess?${params}`, {
            params: { limit: Number.MAX_SAFE_INTEGER },
        });
    }
}
