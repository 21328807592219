import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manageSubscriberList: { loading: false, data: null, error: null },
  createManageSubscriber: { loading: false, data: null, error: null },
  updateManageSubscriber: { loading: false, data: null, error: null },
  getSubscriberDetailsById: { loading: false, data: null, error: null },
  getSubscriberDetailsHeaderImages: { loading: false, data: null, error: null },
};

export const manageSubscriber = createSlice({
  name: "manageSubscriber",
  initialState,
  reducers: {
    // Subscriber list
    fetchManageSubscriberRequest: (manageSubscriber, action) => {
      manageSubscriber.manageSubscriberList.loading = true;
      manageSubscriber.manageSubscriberList.error = null;
    },
    fetchManageSubscriberSuccess: (manageSubscriber, action) => {
      manageSubscriber.manageSubscriberList.data = action.payload;
      manageSubscriber.manageSubscriberList.loading = false;
      manageSubscriber.manageSubscriberList.error = null;
    },
    fetchManageSubscriberFailure: (manageSubscriber, action) => {
      manageSubscriber.manageSubscriberList.data = null;
      manageSubscriber.manageSubscriberList.loading = false;
      manageSubscriber.manageSubscriberList.error = action.payload;
    },
    // create Subscriber
    createManageSubscriberRequest: (manageSubscriber, action) => {
      manageSubscriber.createManageSubscriber.loading = true;
      manageSubscriber.createManageSubscriber.error = null;
    },
    createManageSubscriberSuccess: (manageSubscriber, action) => {
      manageSubscriber.createManageSubscriber.data = action.payload;
      manageSubscriber.createManageSubscriber.loading = false;
      manageSubscriber.createManageSubscriber.error = null;
      manageSubscriber.manageSubscriberList = addNewSubscriberSuccess(
        action.payload,
        {
          ...manageSubscriber.manageSubscriberList,
        }
      );
    },
    createManageSubscriberFailure: (manageSubscriber, action) => {
      manageSubscriber.createManageSubscriber.data = null;
      manageSubscriber.createManageSubscriber.loading = false;
      manageSubscriber.createManageSubscriber.error = action.payload;
    },
    // update Subscriber
    updateManageSubscriberRequest: (manageSubscriber, action) => {
      manageSubscriber.updateManageSubscriber.loading = true;
      manageSubscriber.updateManageSubscriber.error = null;
    },
    updateManageSubscriberSuccess: (manageSubscriber, action) => {
      manageSubscriber.updateManageSubscriber.data = action.payload;
      manageSubscriber.updateManageSubscriber.loading = false;
      manageSubscriber.updateManageSubscriber.error = null;
    },
    updateManageSubscriberFailure: (manageSubscriber, action) => {
      manageSubscriber.updateManageSubscriber.data = null;
      manageSubscriber.updateManageSubscriber.loading = false;
      manageSubscriber.updateManageSubscriber.error = action.payload;
    },
    // get by id Subscriber details
    getSubscriberDetailsRequest: (manageSubscriber, action) => {
      manageSubscriber.getSubscriberDetailsById.loading = true;
      manageSubscriber.getSubscriberDetailsById.error = null;
    },
    getSubscriberDetailsSuccess: (manageSubscriber, action) => {
      manageSubscriber.getSubscriberDetailsById.loading = false;
      manageSubscriber.getSubscriberDetailsById.error = null;
      manageSubscriber.getSubscriberDetailsById.data = action.payload;
    },
    getSubscriberDetailsFailure: (manageSubscriber, action) => {
      manageSubscriber.getSubscriberDetailsById.data = null;
      manageSubscriber.getSubscriberDetailsById.loading = false;
      manageSubscriber.getSubscriberDetailsById.error = action.payload;
    },
    // get by id Subscriber header images details
    getSubscriberDetailsHeaderImagesRequest: (manageSubscriber, action) => {
      manageSubscriber.getSubscriberDetailsHeaderImages.loading = true;
      manageSubscriber.getSubscriberDetailsHeaderImages.error = null;
    },
    getSubscriberDetailsHeaderImagesSuccess: (manageSubscriber, action) => {
      manageSubscriber.getSubscriberDetailsHeaderImages.loading = false;
      manageSubscriber.getSubscriberDetailsHeaderImages.error = null;
      manageSubscriber.getSubscriberDetailsHeaderImages.data = action.payload;
    },
    getSubscriberDetailsHeaderImagesFailure: (manageSubscriber, action) => {
      manageSubscriber.getSubscriberDetailsHeaderImages.data = null;
      manageSubscriber.getSubscriberDetailsHeaderImages.loading = false;
      manageSubscriber.getSubscriberDetailsHeaderImages.error = action.payload;
    },

    // reset Subscriber
    getSubscriberDetailsReset: (manageSubscriber, action) => {
      manageSubscriber.createManageSubscriber.data = null;
      manageSubscriber.createManageSubscriber.loading = false;
      manageSubscriber.createManageSubscriber.error = null;

      manageSubscriber.updateManageSubscriber.data = null;
      manageSubscriber.updateManageSubscriber.loading = false;
      manageSubscriber.updateManageSubscriber.error = null;

      manageSubscriber.getSubscriberDetailsById.data = null;
      manageSubscriber.getSubscriberDetailsById.loading = false;
      manageSubscriber.getSubscriberDetailsById.error = null;
    },
  },
});

function addNewSubscriberSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

export const {
  fetchManageSubscriberRequest,
  fetchManageSubscriberSuccess,
  fetchManageSubscriberFailure,
  createManageSubscriberRequest,
  createManageSubscriberSuccess,
  createManageSubscriberFailure,
  updateManageSubscriberRequest,
  updateManageSubscriberSuccess,
  updateManageSubscriberFailure,
  getSubscriberDetailsRequest,
  getSubscriberDetailsSuccess,
  getSubscriberDetailsFailure,
  getSubscriberDetailsHeaderImagesRequest,
  getSubscriberDetailsHeaderImagesSuccess,
  getSubscriberDetailsHeaderImagesFailure,
  getSubscriberDetailsReset,
} = manageSubscriber.actions;

export const getManageSubscriberListState = (store) =>
  store.manageSubscriber?.manageSubscriberList;
export const getManageSubscriberCreateState = (store) =>
  store.manageSubscriber?.createManageSubscriber;
export const getUpdateSubscriberState = (store) =>
  store?.manageSubscriber?.updateManageSubscriber;
export const getSubscriberGetByIdState = (store) =>
  store?.manageSubscriber?.getSubscriberDetailsById;
export const getSubscriberGetHeaderImagesState = (store) =>
  store?.manageSubscriber?.getSubscriberDetailsHeaderImages;

export default manageSubscriber.reducer;
