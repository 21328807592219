import { put, takeLatest } from "redux-saga/effects";

import { requestSensorDataList } from "../../../services/sensorData";
import {
  fetchSensorDataListRequest,
  fetchSensorDataListSuccess,
  fetchSensorDataListFailure,
} from "../../reducers/sensorData";

export function* watchSensorDataSaga() {
  yield takeLatest(fetchSensorDataListRequest, fetchSensorDataList);
}

/**
 *
 * @param {*} param0
 */
function* fetchSensorDataList({ payload }) {
  try {
    const response = yield requestSensorDataList(payload);
    yield put(fetchSensorDataListSuccess(response));
  } catch (error) {
    yield put(fetchSensorDataListFailure("Failed to get list of SensorData"));
  }
}
