export const logoMap = new Map();

//store path for logo
logoMap.set("http://localhost:3000", {
  logo: "/img/logo/jetatech.png",
  favicon: "favicon",
  headerlogo: "/img/header/jetatech.png",
  loginImage: true,
});

logoMap.set("https://iot.jetatech.cloud", {
  logo: "/img/logo/jetatech.png",
  favicon: "favicon",
  headerlogo: "/img/header/jetatech.png",
  loginImage: true,
});

logoMap.set("https://serve.jetatech.cloud", {
  logo: "/img/logo/jetaserve.png",
  favicon: "favicon",
  headerlogo: "/img/header/jetaserve.png",
  loginImage: true,
});

logoMap.set("https://portal.sevoiot.com", {
  logo: "/img/header/sevo.png",
  favicon: "sevo",
  headerlogo: "/img/header/sevo.png",
  loginImage: false,
}); //evo icon req

logoMap.set("http://portal.sevoiot.com", {
  logo: "/img/header/sevo.png",
  favicon: "sevo",
  headerlogo: "/img/header/sevo.png",
  loginImage: false,
});

logoMap.set("https://portal.sevohubiot.com", {
  logo: "/img/header/sevo.png",
  favicon: "sevo",
  headerlogo: "/img/header/sevo.png",
  loginImage: false,
});

logoMap.set("http://portal.sevohubiot.com", {
  logo: "/img/header/sevo.png",
  favicon: "sevo",
  headerlogo: "/img/header/sevo.png",
  loginImage: false,
});

logoMap.set("https://mon.firetweet.in", {
  logo: "/img/logo/firetweet.png",
  favicon: "favicon",
  headerlogo: "/img/header/firetweet.png",
  loginImage: false,
});

logoMap.set("http://mon.firetweet.in", {
  logo: "/img/logo/firetweet.png",
  favicon: "favicon",
  headerlogo: "/img/header/firetweet.png",
  loginImage: false,
});

logoMap.set("http://15.206.161.186:49173", {
  logo: "/img/logo/firetweet.png",
  favicon: "favicon",
  headerlogo: "/img/header/firetweet.png",
  loginImage: true,
});
