import { put, takeLatest } from "redux-saga/effects";

import {
    requestActionNoteList,
    requestCreateActionNote,
    requestUpdateActionNote,
    requestDeleteActionNote,
    requestActionNoteDataById,
} from "services/actionNote";

import {
    fetchActionNoteListRequest,
    fetchActionNoteListSuccess,
    fetchActionNoteListFailure,
    createActionNoteRequest,
    createActionNoteSuccess,
    createActionNoteFailure,
    updateActionNoteRequest,
    updateActionNoteSuccess,
    updateActionNoteFailure,
    deleteActionNoteRequest,
    deleteActionNoteSuccess,
    deleteActionNoteFailure,
    getActionNoteByIdRequest,
    getActionNoteByIdSuccess,
    getActionNoteByIdFailure,
} from "redux/reducers/actionNote";

export function* watchActionNoteSaga() {
    yield takeLatest(fetchActionNoteListRequest, fetchActionNoteList);
    yield takeLatest(createActionNoteRequest, createActionNote);
    yield takeLatest(updateActionNoteRequest, updateActionNote);
    yield takeLatest(deleteActionNoteRequest, deleteActionNote);
    yield takeLatest(getActionNoteByIdRequest, getActionNoteById);
}

function* fetchActionNoteList({ payload }) {
    try {
        const response = yield requestActionNoteList(payload);
        yield put(fetchActionNoteListSuccess(response));
    } catch (error) {
        yield put(
            fetchActionNoteListFailure("Failed to get list of actionNote")
        );
    }
}

function* createActionNote({ payload }) {
    try {
        // service add
        const response = yield requestCreateActionNote(payload);
        yield put(createActionNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create actionNote";
        yield put(createActionNoteFailure(message));
    }
}

function* updateActionNote({ payload }) {
    try {
        const response = yield requestUpdateActionNote(payload);
        yield put(updateActionNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update actionNote";
        yield put(updateActionNoteFailure(message));
    }
}

function* deleteActionNote({ payload }) {
    try {
        const response = yield requestDeleteActionNote(payload);
        yield put(deleteActionNoteSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete actionNote";
        yield put(deleteActionNoteFailure(message));
    }
}

function* getActionNoteById({ payload }) {
    try {
        const response = yield requestActionNoteDataById(payload);
        yield put(getActionNoteByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get actionNote details";
        yield put(getActionNoteByIdFailure(message));
    }
}