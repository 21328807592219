import { put, takeLatest } from "redux-saga/effects";
import {
    fetchOrganizationListFailure,
    fetchOrganizationListRequest,
    fetchOrganizationListSuccess,
    createOrganizationRequest,
    createOrganizationSuccess,
    createOrganizationFailure,
    updateOrganizationRequest,
    updateOrganizationSuccess,
    updateOrganizationFailure,
    deleteOrganizationRequest,
    deleteOrganizationSuccess,
    deleteOrganizationFailure,
    getOrganizationByIdRequest,
    getOrganizationByIdSuccess,
    getOrganizationByIdFailure,
} from "redux/reducers/organization";
import {
    requestOrganizationList,
    requestCreateOrganization,
    requestUpdateOrganization,
    requestDeleteOrganization,
    requestOrganizationDataById,
} from "services/organization";

export function* watchOrganizationSaga() {
    yield takeLatest(fetchOrganizationListRequest, fetchOrganizationList);
    yield takeLatest(createOrganizationRequest, createOrganization);
    yield takeLatest(updateOrganizationRequest, updateOrganization);
    yield takeLatest(deleteOrganizationRequest, deleteOrganization);
    yield takeLatest(getOrganizationByIdRequest, getOrganizationById);
}

function* fetchOrganizationList({ payload }) {
    try {
        const response = yield requestOrganizationList(payload);
        yield put(fetchOrganizationListSuccess(response));
    } catch (error) {
        yield put(
            fetchOrganizationListFailure("Failed to get list of organization")
        )
    }
}

function* createOrganization({ payload }) {
    try {
        // service add
        const response = yield requestCreateOrganization(payload);
        yield put(createOrganizationSuccess(response.data));
    } catch (error) {
        yield put(createOrganizationFailure("Failed to create organization"));
    }
}

function* updateOrganization({ payload }) {
    try {
        const response = yield requestUpdateOrganization(payload);
        yield put(updateOrganizationSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update organization";
        yield put(updateOrganizationFailure(message));
    }
}

function* deleteOrganization({ payload }) {
    try {
        const response = yield requestDeleteOrganization(payload);
        yield put(deleteOrganizationSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete organization";
        yield put(deleteOrganizationFailure(message));
    }
}

function* getOrganizationById({ payload }) {
    try {
        const response = yield requestOrganizationDataById(payload);
        yield put(getOrganizationByIdSuccess(response));

    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get organization details";
        yield put(getOrganizationByIdFailure(message));
    }
}
