import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  installationCommissioningList: { loading: false, data: null, error: null },
  createInstallationCommissioning: { loading: false, data: null, error: null },
  updateInstallationCommissioning: { loading: false, data: null, error: null },
  deleteInstallationCommissioning: { loading: false, data: null, error: null },
  getInstallationCommissioningById: { loading: false, data: null, error: null },
};

export const installationCommissioningSlice = createSlice({
  name: "installationCommissioning",
  initialState,
  reducers: {
    fetchInstallationCommissioningListRequest: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.installationCommissioningList.loading = true;
      installationCommissioning.installationCommissioningList.error = null;
    },
    fetchInstallationCommissioningListSuccess: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.installationCommissioningList.data =
        action.payload;
      installationCommissioning.installationCommissioningList.loading = false;
      installationCommissioning.installationCommissioningList.error = null;
    },
    fetchInstallationCommissioningListFailure: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.installationCommissioningList.data = null;
      installationCommissioning.installationCommissioningList.loading = false;
      installationCommissioning.installationCommissioningList.error =
        action.payload;
    },

    //create InstallationCommissioning
    createInstallationCommissioningRequest: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.createInstallationCommissioning.loading = true;
      installationCommissioning.createInstallationCommissioning.error = null;
    },
    createInstallationCommissioningSuccess: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.createInstallationCommissioning.data =
        action.payload;
      installationCommissioning.createInstallationCommissioning.loading = false;
      installationCommissioning.createInstallationCommissioning.error = null;
      installationCommissioning.installationCommissioningList =
        addNewInstallationCommissioningSuccess(action.payload, {
          ...installationCommissioning.installationCommissioningList,
        });
    },
    createInstallationCommissioningFailure: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.createInstallationCommissioning.data = null;
      installationCommissioning.createInstallationCommissioning.loading = false;
      installationCommissioning.createInstallationCommissioning.error =
        action.payload;
    },

    //update installationCommissioning details
    updateInstallationCommissioningRequest: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.updateInstallationCommissioning.loading = true;
      installationCommissioning.updateInstallationCommissioning.error = null;
    },
    updateInstallationCommissioningSuccess: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.updateInstallationCommissioning.data =
        action.payload;
      installationCommissioning.updateInstallationCommissioning.loading = false;
      installationCommissioning.updateInstallationCommissioning.error = null;
      installationCommissioning.installationCommissioningList =
        updateNewInstallationCommissioningSuccess(
          action.payload,
          installationCommissioning.installationCommissioningList
        );
    },
    updateInstallationCommissioningFailure: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.updateInstallationCommissioning.data = null;
      installationCommissioning.updateInstallationCommissioning.loading = false;
      installationCommissioning.updateInstallationCommissioning.error =
        action.payload;
    },

    // delete installationCommissioning
    deleteInstallationCommissioningRequest: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.deleteInstallationCommissioning.loading = true;
      installationCommissioning.deleteInstallationCommissioning.error = null;
    },
    deleteInstallationCommissioningSuccess: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.deleteInstallationCommissioning.data =
        action.payload;
      installationCommissioning.deleteInstallationCommissioning.loading = false;
      installationCommissioning.deleteInstallationCommissioning.error = null;
      installationCommissioning.installationCommissioningList =
        deleteNewInstallationCommissioningSuccess(action.payload, {
          ...installationCommissioning.installationCommissioningList,
        });
    },
    deleteInstallationCommissioningFailure: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.deleteInstallationCommissioning.data = null;
      installationCommissioning.deleteInstallationCommissioning.loading = false;
      installationCommissioning.deleteInstallationCommissioning.error =
        action.payload;
    },

    // getById installationCommissioning details
    getInstallationCommissioningByIdRequest: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.getInstallationCommissioningById.loading = true;
      installationCommissioning.getInstallationCommissioningById.error = null;
    },
    getInstallationCommissioningByIdSuccess: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.getInstallationCommissioningById.data =
        action.payload;
      installationCommissioning.getInstallationCommissioningById.loading = false;
      installationCommissioning.getInstallationCommissioningById.error = null;
    },
    getInstallationCommissioningByIdFailure: (
      installationCommissioning,
      action
    ) => {
      installationCommissioning.getInstallationCommissioningById.data = null;
      installationCommissioning.getInstallationCommissioningById.loading = false;
      installationCommissioning.getInstallationCommissioningById.error =
        action.payload;
    },

    // reset installationCommissioning details
    resetInstallationCommissioning: (installationCommissioning, action) => {
      installationCommissioning.createInstallationCommissioning.data = null;
      installationCommissioning.createInstallationCommissioning.loading = false;
      installationCommissioning.createInstallationCommissioning.error = null;

      installationCommissioning.updateInstallationCommissioning.data = null;
      installationCommissioning.updateInstallationCommissioning.loading = false;
      installationCommissioning.updateInstallationCommissioning.error = null;

      installationCommissioning.deleteInstallationCommissioning.data = null;
      installationCommissioning.deleteInstallationCommissioning.loading = false;
      installationCommissioning.deleteInstallationCommissioning.error = null;

      installationCommissioning.getInstallationCommissioningById.data = null;
      installationCommissioning.getInstallationCommissioningById.loading = false;
      installationCommissioning.getInstallationCommissioningById.error = null;
    },
  },
});

function addNewInstallationCommissioningSuccess(dataToAdd, state) {
  state.data.data = [dataToAdd, ...state.data.data];
  state.data.total++;
  return state;
}

function updateNewInstallationCommissioningSuccess(dataToUpdate, state) {
  if (state.data?.data.length) {
    const updatedData = state.data.data.map((value) => {
      if (value._id !== dataToUpdate._id) return value;
      return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
  } else {
    return state;
  }
}

function deleteNewInstallationCommissioningSuccess(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
  return state;
}

export const {
  fetchInstallationCommissioningListRequest,
  fetchInstallationCommissioningListSuccess,
  fetchInstallationCommissioningListFailure,

  createInstallationCommissioningRequest,
  createInstallationCommissioningSuccess,
  createInstallationCommissioningFailure,

  updateInstallationCommissioningRequest,
  updateInstallationCommissioningSuccess,
  updateInstallationCommissioningFailure,

  deleteInstallationCommissioningRequest,
  deleteInstallationCommissioningSuccess,
  deleteInstallationCommissioningFailure,

  getInstallationCommissioningByIdRequest,
  getInstallationCommissioningByIdSuccess,
  getInstallationCommissioningByIdFailure,

  resetInstallationCommissioning,
} = installationCommissioningSlice.actions;

export const getInstallationCommissioningListState = (store) =>
  store?.installationCommissioning?.installationCommissioningList;
export const getInstallationCommissioningCreateState = (store) =>
  store?.installationCommissioning?.createInstallationCommissioning;
export const getInstallationCommissioningUpdateState = (store) =>
  store?.installationCommissioning?.updateInstallationCommissioning;
export const getInstallationCommissioningDeleteState = (store) =>
  store?.installationCommissioning?.deleteInstallationCommissioning;
export const getInstallationCommissioningGetByIdState = (store) =>
  store?.installationCommissioning?.getInstallationCommissioningById;
export default installationCommissioningSlice.reducer;
