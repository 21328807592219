import { put, takeLatest } from "redux-saga/effects";

import {
    requestFeActivityList,
    requestCreateFeActivity,
    requestDeleteFeActivity,
    requestFeActivityDataById,
} from "services/feActivity";

import {
    fetchFeActivityListRequest,
    fetchFeActivityListSuccess,
    fetchFeActivityListFailure,
    createFeActivityRequest,
    createFeActivitySuccess,
    createFeActivityFailure,
    deleteFeActivityRequest,
    deleteFeActivitySuccess,
    deleteFeActivityFailure,
    getFeActivityByIdRequest,
    getFeActivityByIdSuccess,
    getFeActivityByIdFailure,
} from "redux/reducers/feActivity";

export function* watchFeActivitySaga() {
    yield takeLatest(fetchFeActivityListRequest, fetchFeActivityList);
    yield takeLatest(createFeActivityRequest, createFeActivity);
    yield takeLatest(deleteFeActivityRequest, deleteFeActivity);
    yield takeLatest(getFeActivityByIdRequest, getFeActivityById);
}

function* fetchFeActivityList({ payload }) {
    try {
        const response = yield requestFeActivityList(payload);
        yield put(fetchFeActivityListSuccess(response));
    } catch (error) {
        yield put(
            fetchFeActivityListFailure("Failed to get list of Fe Activity")
        );
    }
}

function* createFeActivity({ payload }) {
    try {
        // service add
        const response = yield requestCreateFeActivity(payload);
        yield put(createFeActivitySuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create Fe Activity";
        yield put(createFeActivityFailure(message));
    }
}

function* deleteFeActivity({ payload }) {
    try {
        const response = yield requestDeleteFeActivity(payload);
        yield put(deleteFeActivitySuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete Fe Activity";
        yield put(deleteFeActivityFailure(message));
    }
}

function* getFeActivityById({ payload }) {
    try {
        const response = yield requestFeActivityDataById(payload);
        yield put(getFeActivityByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get Fe Activity details";
        yield put(getFeActivityByIdFailure(message));
    }
}