import { put, takeLatest } from "redux-saga/effects";

import { requestSensorAnalyticsList } from "../../../services/sensorAnalytics";
import {
  fetchSensorAnalyticsListRequest,
  fetchSensorAnalyticsListSuccess,
  fetchSensorAnalyticsListFailure,
} from "../../reducers/sensorAnalytics";

export function* watchSensorAnalyticsSaga() {
  yield takeLatest(fetchSensorAnalyticsListRequest, fetchSensorAnalyticsList);
}

/**
 *
 * @param {*} param0
 */
function* fetchSensorAnalyticsList({ payload }) {
  try {
    const response = yield requestSensorAnalyticsList(payload);
    yield put(fetchSensorAnalyticsListSuccess(response));
  } catch (error) {
    yield put(
      fetchSensorAnalyticsListFailure("Failed to get list of Sensor graph")
    );
  }
}
