import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    ticketList: { loading: false, data: null, error: null },
    createTicket: { loading: false, data: null, error: null },
    updateTicket: { loading: false, data: null, error: null },
    deleteTicket: { loading: false, data: null, error: null },
    getTicketById: { loading: false, data: null, error: null }
}

export const ticketSlice = createSlice({
    name: "ticket",
    initialState,
    reducers: {
        fetchTicketListRequest: (ticket, action) => {
            ticket.ticketList.loading = true;
            ticket.ticketList.error = null;
        },
        fetchTicketListSuccess: (ticket, action) => {
            ticket.ticketList.data = action.payload;
            ticket.ticketList.loading = false;
            ticket.ticketList.error = null;
        },
        fetchTicketListFailure: (ticket, action) => {
            ticket.ticketList.data = null;
            ticket.ticketList.loading = false;
            ticket.ticketList.error = action.payload;
        },

        //create Ticket
        createTicketRequest: (ticket, action) => {
            ticket.createTicket.loading = true;
            ticket.createTicket.error = null;
        },
        createTicketSuccess: (ticket, action) => {
            ticket.createTicket.data = action.payload;
            ticket.createTicket.loading = false;
            ticket.createTicket.error = null;
            ticket.ticketList = addNewTicketSuccess(action.payload, {
                ...ticket.ticketList,
            })
        },
        createTicketFailure: (ticket, action) => {
            ticket.createTicket.data = null;
            ticket.createTicket.loading = false;
            ticket.createTicket.error = action.payload;
        },

        //update ticket details
        updateTicketRequest: (ticket, action) => {
            ticket.updateTicket.loading = true;
            ticket.updateTicket.error = null;
        },
        updateTicketSuccess: (ticket, action) => {
            ticket.updateTicket.data = action.payload;
            ticket.updateTicket.loading = false;
            ticket.updateTicket.error = null;
            ticket.ticketList = updateNewTicketSuccess(
                action.payload,
                ticket.ticketList
            );
        },
        updateTicketFailure: (ticket, action) => {
            ticket.updateTicket.data = null;
            ticket.updateTicket.loading = false;
            ticket.updateTicket.error = action.payload;
        },

        // delete ticket
        deleteTicketRequest: (ticket, action) => {
            ticket.deleteTicket.loading = true;
            ticket.deleteTicket.error = null;
        },
        deleteTicketSuccess: (ticket, action) => {
            ticket.deleteTicket.data = action.payload;
            ticket.deleteTicket.loading = false;
            ticket.deleteTicket.error = null;
            ticket.ticketList = deleteNewTicketSuccess(action.payload, {
                ...ticket.ticketList,
            })
        },
        deleteTicketFailure: (ticket, action) => {
            ticket.deleteTicket.data = null;
            ticket.deleteTicket.loading = false;
            ticket.deleteTicket.error = action.payload;
        },

        // getById ticket details
        getTicketByIdRequest: (ticket, action) => {
            ticket.getTicketById.loading = true;
            ticket.getTicketById.error = null;
        },
        getTicketByIdSuccess: (ticket, action) => {
            ticket.getTicketById.data = action.payload;
            ticket.getTicketById.loading = false;
            ticket.getTicketById.error = null;
        },
        getTicketByIdFailure: (ticket, action) => {
            ticket.getTicketById.data = null;
            ticket.getTicketById.loading = false;
            ticket.getTicketById.error = action.payload;
        },

        // reset ticket details
        resetTicket: (ticket, action) => {
            ticket.createTicket.data = null;
            ticket.createTicket.loading = false;
            ticket.createTicket.error = null;

            ticket.updateTicket.data = null;
            ticket.updateTicket.loading = false;
            ticket.updateTicket.error = null;

            ticket.deleteTicket.data = null;
            ticket.deleteTicket.loading = false;
            ticket.deleteTicket.error = null;
        }
    }
})

function addNewTicketSuccess(dataToAdd, state) {
    state.data.data = [dataToAdd, ...state.data.data];
    state.data.total++;
    return state;
}

function updateNewTicketSuccess(dataToUpdate, state) {
    const updatedData = state.data.data.map((value) => {
        if (value._id !== dataToUpdate._id) return value;
        return { ...value, ...dataToUpdate };
    });
    return { ...state, data: { ...state.data, data: updatedData } };
}

function deleteNewTicketSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchTicketListRequest,
    fetchTicketListSuccess,
    fetchTicketListFailure,

    createTicketRequest,
    createTicketSuccess,
    createTicketFailure,

    updateTicketRequest,
    updateTicketSuccess,
    updateTicketFailure,

    deleteTicketRequest,
    deleteTicketSuccess,
    deleteTicketFailure,

    getTicketByIdRequest,
    getTicketByIdSuccess,
    getTicketByIdFailure,

    resetTicket,
} = ticketSlice.actions;

export const getTicketListState = (store) =>
    store?.ticket?.ticketList;
export const getTicketCreateState = (store) =>
    store?.ticket?.createTicket;
export const getTicketUpdateState = (store) =>
    store?.ticket?.updateTicket;
export const getTicketDeleteState = (store) =>
    store?.ticket?.deleteTicket;
export const getTicketGetByIdState = (store) =>
    store?.ticket?.getTicketById;
export default ticketSlice.reducer;