import { put, takeLatest } from "redux-saga/effects";
import {
    fetchDeviceListFailure,
    fetchDeviceListRequest,
    fetchDeviceListSuccess
} from "redux/reducers/device";
import {
    requestDeviceList
} from "services/device";

export function* watchDeviceSaga() {
    yield takeLatest(fetchDeviceListRequest, fetchDeviceList);
}

function* fetchDeviceList({ payload }) {
    try {
        const response = yield requestDeviceList(payload);
        yield put(fetchDeviceListSuccess(response));
    } catch (error) {
        yield put(
            fetchDeviceListFailure("Failed to get list of device")
        )
    }
}