import { put, takeLatest } from "redux-saga/effects";

import {
  requestSvcMachineTypeList,
  requestCreateSvcMachineType,
  requestUpdateSvcMachineType,
  requestDeleteSvcMachineType,
  requestSvcMachineTypeDataById,
  requestManufacturerDropDownList,
} from "../../../services/svcMachineType";
import {
  fetchSvcMachineTypeListRequest,
  fetchSvcMachineTypeListSuccess,
  fetchSvcMachineTypeListFailure,
  createSvcMachineTypeRequest,
  createSvcMachineTypeSuccess,
  createSvcMachineTypeFailure,
  updateSvcMachineTypeRequest,
  updateSvcMachineTypeSuccess,
  updateSvcMachineTypeFailure,
  deleteSvcMachineTypeRequest,
  deleteSvcMachineTypeSuccess,
  deleteSvcMachineTypeFailure,
  getSvcMachineTypeDetailsRequest,
  getSvcMachineTypeDetailsSuccess,
  getSvcMachineTypeDetailsFailure,
  fetchManufacturerListRequest,
  fetchManufacturerListSuccess,
  fetchManufacturerListFailure,
} from "../../reducers/svcMachineType";

export function* watchSvcMachineTypeSaga() {
  yield takeLatest(fetchSvcMachineTypeListRequest, fetchSvcMachineTypeList);
  yield takeLatest(createSvcMachineTypeRequest, createSvcMachineType);
  yield takeLatest(updateSvcMachineTypeRequest, updateSvcMachineType);
  yield takeLatest(deleteSvcMachineTypeRequest, deleteSvcMachineType);
  yield takeLatest(
    getSvcMachineTypeDetailsRequest,
    getSvcMachineTypeGetByIdData
  );
  yield takeLatest(fetchManufacturerListRequest, getManufacturerListData);
}

function* fetchSvcMachineTypeList({ payload }) {
  try {
    const response = yield requestSvcMachineTypeList(payload);
    yield put(fetchSvcMachineTypeListSuccess(response));
  } catch (error) {
    yield put(
      fetchSvcMachineTypeListFailure("Failed to get list of SVC Machine Type")
    );
  }
}

function* createSvcMachineType({ payload }) {
  try {
    //service add
    const response = yield requestCreateSvcMachineType(payload);
    yield put(createSvcMachineTypeSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to create Svc Machine Type";
    yield put(createSvcMachineTypeFailure(message));
  }
}

function* updateSvcMachineType({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSvcMachineType(payload);
    yield put(updateSvcMachineTypeSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to update Svc Machine Type";
    yield put(updateSvcMachineTypeFailure(message));
  }
}

function* deleteSvcMachineType({ payload }) {
  try {
    const response = yield requestDeleteSvcMachineType(payload);
    yield put(deleteSvcMachineTypeSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to Delete Svc Machine Type";
    yield put(deleteSvcMachineTypeFailure(message));
  }
}

function* getSvcMachineTypeGetByIdData({ payload }) {
  try {
    const response = yield requestSvcMachineTypeDataById(payload);
    yield put(getSvcMachineTypeDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get Svc Machine Type details";
    yield put(getSvcMachineTypeDetailsFailure(message));
  }
}

function* getManufacturerListData({ payload }) {
  try {
    const response = yield requestManufacturerDropDownList(payload);
    yield put(fetchManufacturerListSuccess(response));
  } catch (error) {
    yield put(
      fetchManufacturerListFailure("Failed to get list of Manufacturer Name")
    );
  }
}
