import { put, takeLatest } from "redux-saga/effects";
import { 
    requestServiceProviderList,
    requestCreateServiceProvider,
    requestUpdateServiceProvider,
    requestDeleteServiceProvider,
    requestServiceProviderDataById
 } from "services/serviceProvider";
import { 
    fetchServiceProviderListRequest,
    fetchServiceProviderListSuccess,
    fetchServiceProviderListFailure,
    createServiceProviderRequest,
    createServiceProviderSuccess,
    createServiceProviderFailure,
    updateServiceProviderRequest,
    updateServiceProviderSuccess,
    updateServiceProviderFailure,
    deleteServiceProviderRequest,
    deleteServiceProviderSuccess,
    deleteServiceProviderFailure,
    getServiceProviderDetailsRequest,
    getServiceProviderDetailsSuccess,
    getServiceProviderDetailsFailure
} from "redux/reducers/serviceProvider";

export function* watchServiceProviderSaga() {
    yield takeLatest(fetchServiceProviderListRequest, fetchServiceProviderList);
    yield takeLatest(createServiceProviderRequest, createServiceProvider);
    yield takeLatest(updateServiceProviderRequest, updateServiceProvider);
    yield takeLatest(deleteServiceProviderRequest, deleteServiceProvider);
    yield takeLatest(
      getServiceProviderDetailsRequest,
      getServiceProviderGetByIdData
    );
   
  }
  
  function* fetchServiceProviderList({ payload }) {
    try {
      const response = yield requestServiceProviderList(payload);
      yield put(fetchServiceProviderListSuccess(response));
    } catch (error) {
      yield put(
        fetchServiceProviderListFailure("Failed to get list of ServiceProvider")
      );
    }
  }
  
  function* createServiceProvider({ payload }) {
    try {
      //service add
      const response = yield requestCreateServiceProvider(payload);
      yield put(createServiceProviderSuccess(response.data));
    } catch (error) {
      const message =
        error?.response?.data?.data?.message || "Failed to create ServiceProvider";
      if (error?.response?.data?.data?.code === 11000) {
        let objectIntoString = JSON.stringify(
          error?.response?.data?.data?.keyValue
        );
        let removeSpecialChar = objectIntoString.replace(/[""{}]/g, "");
        let finalValue = removeSpecialChar.split(":")[0];
        yield put(
          createServiceProviderFailure(
            "Duplicate " +
              finalValue +
              " Field. " +
              " Unique Number is Required !"
          )
        );
      } else {
        yield put(createServiceProviderFailure(message));
      }
    }
  }
  
  function* updateServiceProvider({ payload }) {
    try {
      //service update
      const response = yield requestUpdateServiceProvider(payload);
      yield put(updateServiceProviderSuccess(response.data));
    } catch (error) {
      const message =
        error?.response?.data?.data?.message || "Failed to update ServiceProvider";
      if (error?.response?.data?.data?.code === 11000) {
        let objectIntoString = JSON.stringify(
          error?.response?.data?.data?.keyValue
        );
        let removeSpecialChar = objectIntoString.replace(/[""{}]/g, "");
        let finalValue = removeSpecialChar.split(":")[0];
        yield put(
          updateServiceProviderFailure(
            "Duplicate " +
              finalValue +
              " Field. " +
              " Unique Number is Required !"
          )
        );
      } else {
        yield put(updateServiceProviderFailure(message));
      }
    }
  }
  
  function* deleteServiceProvider({ payload }) {
    try {
      const response = yield requestDeleteServiceProvider(payload);
      yield put(deleteServiceProviderSuccess(response));
    } catch (error) {
      const message =
        error?.response?.data?.data?.message || "Failed to Delete ServiceProvider";
      yield put(deleteServiceProviderFailure(message));
    }
  }
  
  function* getServiceProviderGetByIdData({ payload }) {
    try {
      const response = yield requestServiceProviderDataById(payload);
      yield put(getServiceProviderDetailsSuccess(response));
    } catch (error) {
      const message =
        error?.response?.data?.data?.message ||
        "Failed to get ServiceProvider details";
      yield put(getServiceProviderDetailsFailure(message));
    }
  }
  