import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestServiceProviderList(params) {
  if (params === undefined) {
    return fetch.get(`${BASE_URL}/api/app/service-provider`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  } else {
    return fetch.get(`${BASE_URL}/api/app/service-provider?${params}`, {
      params: { limit: Number.MAX_SAFE_INTEGER },
    });
  }
}

export function requestCreateServiceProvider(data) {
  return fetch.post(`${BASE_URL}/api/app/service-provider`, data);
}

export function requestUpdateServiceProvider(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/app/service-provider/${data._id}`, body);
}

export function requestDeleteServiceProvider(data) {
  return fetch.delete(`${BASE_URL}/api/app/service-provider/${data}`);
}

export function requestServiceProviderDataById(data) {
  return fetch.get(`${BASE_URL}/api/app/service-provider/getById/${data.id}`);
}

