import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manageUserList: { loading: false, data: null, error: null },
  createManageUser: { loading: false, data: null, error: null },
  updateManageUser: { loading: false, data: null, error: null },
  deleteManageUser: { loading: false, data: null, error: null },
  updateManageUserDetails: { loading: false, data: null, error: null },
  getUserDetailsById: { loading: false, data: null, error: null },
  deleteUserFeatureMenu: { loading: false, data: null, error: null },
  getUserByRole: { loading: false, data: null, error: null },
  getByIDUserDetails: { loading: false, data: null, error: null },
};

export const manageUser = createSlice({
  name: "manageUser",
  initialState,
  reducers: {
    // user list
    fetchManageUserRequest: (manageUser, action) => {
      manageUser.manageUserList.loading = true;
      manageUser.manageUserList.error = null;
    },
    fetchManageUserSuccess: (manageUser, action) => {
      manageUser.manageUserList.data = action.payload;
      manageUser.manageUserList.loading = false;
      manageUser.manageUserList.error = null;
    },
    fetchManageUserFailure: (manageUser, action) => {
      manageUser.manageUserList.data = null;
      manageUser.manageUserList.loading = false;
      manageUser.manageUserList.error = action.payload;
    },
    // create user
    createManageUserRequest: (manageUser, action) => {
      manageUser.createManageUser.loading = true;
      manageUser.createManageUser.error = null;
    },
    createManageUserSuccess: (manageUser, action) => {
      manageUser.createManageUser.data = action.payload;
      manageUser.createManageUser.loading = false;
      manageUser.createManageUser.error = null;
      manageUser.manageUserList = addNewUserSuccess(action.payload, {
        ...manageUser.manageUserList,
      });
    },
    createManageUserFailure: (manageUser, action) => {
      manageUser.createManageUser.data = null;
      manageUser.createManageUser.loading = false;
      manageUser.createManageUser.error = action.payload;
    },
    // update user details
    updateManageUserRequest: (manageUser, action) => {
      manageUser.updateManageUser.loading = true;
      manageUser.updateManageUser.error = null;
    },
    updateManageUserSuccess: (manageUser, action) => {
      manageUser.updateManageUser.data = action.payload;
      manageUser.updateManageUser.loading = false;
      manageUser.updateManageUser.error = null;

      manageUser.manageUserList = updateUserSuccess(
        action.payload,
        manageUser.manageUserList
      );
    },
    updateManageUserFailure: (manageUser, action) => {
      manageUser.updateManageUser.data = null;
      manageUser.updateManageUser.loading = false;
      manageUser.updateManageUser.error = action.payload;
    },
    // delete user
    deleteManageUserRequest: (manageUser, action) => {
      manageUser.deleteManageUser.loading = true;
      manageUser.deleteManageUser.error = null;
    },
    deleteManageUserSuccess: (manageUser, action) => {
      manageUser.deleteManageUser.loading = false;
      manageUser.deleteManageUser.error = null;
      manageUser.deleteManageUser.data = action.payload;
      // add data to list
      manageUser.manageUserList = deleteUser(action.payload, {
        ...manageUser.manageUserList,
      });
    },
    deleteManageUserFailure: (manageUser, action) => {
      manageUser.deleteManageUser.data = null;
      manageUser.deleteManageUser.loading = false;
      manageUser.deleteManageUser.error = action.payload;
    },
    // update user for add feature menu
    updateManageUserDetailsRequest: (manageUser, action) => {
      manageUser.updateManageUserDetails.loading = true;
      manageUser.updateManageUserDetails.error = null;
    },
    updateManageUserDetailsSuccess: (manageUser, action) => {
      manageUser.updateManageUserDetails.loading = false;
      manageUser.updateManageUserDetails.error = null;
      manageUser.updateManageUserDetails.data = action.payload;
      manageUser.manageUserList = updateMangeUserDetails(
        action.payload,
        manageUser.manageUserList
      );
    },
    updateManageUserDetailsFailure: (manageUser, action) => {
      manageUser.updateManageUserDetails.data = null;
      manageUser.updateManageUserDetails.loading = false;
      manageUser.updateManageUserDetails.error = action.payload;
    },
    // get by id user details
    getUserDetailsRequest: (manageUser, action) => {
      manageUser.getUserDetailsById.loading = true;
      manageUser.getUserDetailsById.error = null;
    },
    getUserDetailsSuccess: (manageUser, action) => {
      manageUser.getUserDetailsById.loading = false;
      manageUser.getUserDetailsById.error = null;
      manageUser.getUserDetailsById.data = action.payload;
    },
    getUserDetailsFailure: (manageUser, action) => {
      manageUser.getUserDetailsById.data = null;
      manageUser.getUserDetailsById.loading = false;
      manageUser.getUserDetailsById.error = action.payload;
    },
    // get by id user details anather reducer
    getByIdUserDetailsRequest: (manageUser, action) => {
      manageUser.getByIDUserDetails.loading = true;
      manageUser.getByIDUserDetails.error = null;
    },
    getByIdUserDetailsSuccess: (manageUser, action) => {
      manageUser.getByIDUserDetails.loading = false;
      manageUser.getByIDUserDetails.error = null;
      manageUser.getByIDUserDetails.data = action.payload;
    },
    getByIdUserDetailsFailure: (manageUser, action) => {
      manageUser.getByIDUserDetails.data = null;
      manageUser.getByIDUserDetails.loading = false;
      manageUser.getByIDUserDetails.error = action.payload;
    },
    //delete FeatureMenu
    deleteFeatureUserRequest: (manageUser, action) => {
      manageUser.deleteUserFeatureMenu.loading = true;
      manageUser.deleteUserFeatureMenu.error = null;
    },
    deleteFeatureUserSuccess: (manageUser, action) => {
      manageUser.deleteUserFeatureMenu.loading = false;
      manageUser.deleteUserFeatureMenu.error = null;
      manageUser.deleteUserFeatureMenu.data = action.payload;
      manageUser.manageUserList = updateMangeUserDetails(
        action.payload,
        manageUser.manageUserList
      );
    },
    deleteFeatureUserFailure: (manageUser, action) => {
      manageUser.deleteUserFeatureMenu.data = null;
      manageUser.deleteUserFeatureMenu.loading = false;
      manageUser.deleteUserFeatureMenu.error = action.payload;
    },
    // get User ByRole list
    getUserByRoleRequest: (manageUser, action) => {
      manageUser.getUserByRole.loading = true;
      manageUser.getUserByRole.error = null;
    },
    getUserByRoleSuccess: (manageUser, action) => {
      manageUser.getUserByRole.data = action.payload;
      manageUser.getUserByRole.loading = false;
      manageUser.getUserByRole.error = null;
    },
    getUserByRoleFailure: (manageUser, action) => {
      manageUser.getUserByRole.data = null;
      manageUser.getUserByRole.loading = false;
      manageUser.getUserByRole.error = action.payload;
    },
    // reset user
    getUserDetailsReset: (manageUser, action) => {
      manageUser.createManageUser = initialState.createManageUser;
      manageUser.updateManageUser = initialState.updateManageUser;
      manageUser.updateManageUserDetails = initialState.updateManageUserDetails;
      manageUser.deleteManageUser = initialState.deleteManageUser;
      manageUser.deleteUserFeatureMenu = initialState.deleteUserFeatureMenu;
    },
  },
});

function updateMangeUserDetails(dataToUpdate, state) {
  let value = JSON.parse(JSON.stringify(state.data.data, undefined, 1));
  let filteredObject = value.map((e) => e._id);
  let obj = filteredObject.indexOf(dataToUpdate.data._id);
  if (obj !== -1) {
    value[obj] = dataToUpdate.data;
  }
  let finalValue = { loading: false, error: false, data: { data: value } };
  return finalValue;
}

function addNewUserSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

function updateUserSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

function deleteUser(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchManageUserRequest,
  fetchManageUserSuccess,
  fetchManageUserFailure,
  createManageUserRequest,
  createManageUserSuccess,
  createManageUserFailure,
  updateManageUserRequest,
  updateManageUserSuccess,
  updateManageUserFailure,
  deleteManageUserRequest,
  deleteManageUserSuccess,
  deleteManageUserFailure,
  updateManageUserDetailsRequest,
  updateManageUserDetailsSuccess,
  updateManageUserDetailsFailure,
  getUserDetailsRequest,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  deleteFeatureUserRequest,
  deleteFeatureUserSuccess,
  deleteFeatureUserFailure,
  getUserByRoleRequest,
  getUserByRoleSuccess,
  getUserByRoleFailure,
  getByIdUserDetailsRequest,
  getByIdUserDetailsSuccess,
  getByIdUserDetailsFailure,
  getUserDetailsReset,
} = manageUser.actions;

export const getManageUserListState = (store) =>
  store.manageUser?.manageUserList;
export const getManageUserCreateState = (store) =>
  store.manageUser?.createManageUser;
export const getUpdateManageUserState = (store) =>
  store.manageUser?.updateManageUser;
export const getUserDetailData = (store) =>
  store.manageUser?.getUserDetailsById;
export const getUpdateManageUserDetailsState = (store) =>
  store.manageUser?.updateManageUserDetails;
export const getDeleteManageUserState = (store) =>
  store.manageUser?.deleteManageUser;
export const getManageUserDeleteFeatureState = (store) =>
  store.manageUser?.deleteUserFeatureMenu;
export const getUserByRoleState = (store) => store.manageUser?.getUserByRole;
export const getByIdUserDetailData = (store) =>
  store.manageUser?.getByIDUserDetails;
export default manageUser.reducer;
