import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestManageSubscriberList(params) {
  return fetch.get(`${BASE_URL}/api/saas/subscriber`, {
    params: { limit: Number.MAX_SAFE_INTEGER },
  });
}

export function requestCreateSubscriber(data) {
  return fetch.post(`${BASE_URL}/api/saas/subscriber`, data);
}

export function requestUpdateSubscriber(data) {
  const body = { ...data };
  delete body["_id"];
  return fetch.put(`${BASE_URL}/api/saas/subscriber/${data._id}`, body);
}

export function requestSubscriberDataById(data) {
  return fetch.get(`${BASE_URL}/api/saas/subscriber/getById/${data.id}`);
}

export function requestSubscriberDataGetHeaderImages(data) {
  return fetch.get(`${BASE_URL}/api/auth/getHeaderImages/${data.id}`);
}
