import { put, takeLatest } from "redux-saga/effects";
import {
    fetchMaintenanceClientListFailure,
    fetchMaintenanceClientListRequest,
    fetchMaintenanceClientListSuccess
} from "redux/reducers/maintenanceClient";
import {
    requestMaintenanceClientList
} from "services/maintenanceClient";

export function* watchMaintenanceClientSaga() {
    yield takeLatest(fetchMaintenanceClientListRequest, fetchMaintenanceClientList);
}

function* fetchMaintenanceClientList({ payload }) {
    try {
        const response = yield requestMaintenanceClientList(payload);
        yield put(fetchMaintenanceClientListSuccess(response));
    } catch (error) {
        yield put(
            fetchMaintenanceClientListFailure("Failed to get list of Client")
        )
    }
}