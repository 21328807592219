import fetch from "auth/FetchInterceptor";
import { API_BASE_URL } from "configs/AppConfig";

const BASE_URL = API_BASE_URL;

export function requestNotificationList(params) {
  if (params === undefined) {
    return fetch.get(
      `${BASE_URL}/api/app/notification?receiver=${localStorage?.getItem(
        "user"
      )}`,
      {
        params: { limit: Number.MAX_SAFE_INTEGER },
      }
    );
  } else {
    return fetch.get(
      `${BASE_URL}/api/app/notification?${params}&receiver=${localStorage?.getItem(
        "user"
      )}`,
      {}
    );
  }
}

export function requestUpdateNotificationList(params) {
  return fetch.put(`${BASE_URL}/api/app/notification/updateMany`, {
    ...params,
    receiver: localStorage?.getItem("user"),
  });
}
