import { put, takeLatest } from "redux-saga/effects";

import {
    requestTicketList,
    requestCreateTicket,
    requestUpdateTicket,
    requestDeleteTicket,
    requestTicketDataById,
} from "services/ticket";

import {
    fetchTicketListRequest,
    fetchTicketListSuccess,
    fetchTicketListFailure,
    createTicketRequest,
    createTicketSuccess,
    createTicketFailure,
    updateTicketRequest,
    updateTicketSuccess,
    updateTicketFailure,
    deleteTicketRequest,
    deleteTicketSuccess,
    deleteTicketFailure,
    getTicketByIdRequest,
    getTicketByIdSuccess,
    getTicketByIdFailure,
} from "redux/reducers/ticket";

export function* watchTicketSaga() {
    yield takeLatest(fetchTicketListRequest, fetchTicketList);
    yield takeLatest(createTicketRequest, createTicket);
    yield takeLatest(updateTicketRequest, updateTicket);
    yield takeLatest(deleteTicketRequest, deleteTicket);
    yield takeLatest(getTicketByIdRequest, getTicketById);
}

function* fetchTicketList({ payload }) {
    try {
        const response = yield requestTicketList(payload);
        yield put(fetchTicketListSuccess(response));
    } catch (error) {
        yield put(
            fetchTicketListFailure("Failed to get list of ticket")
        );
    }
}

function* createTicket({ payload }) {
    try {
        // service add
        const response = yield requestCreateTicket(payload);
        yield put(createTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create ticket";
        yield put(createTicketFailure(message));
    }
}

function* updateTicket({ payload }) {
    try {
        const response = yield requestUpdateTicket(payload);
        yield put(updateTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update ticket";
        yield put(updateTicketFailure(message));
    }
}

function* deleteTicket({ payload }) {
    try {
        const response = yield requestDeleteTicket(payload);
        yield put(deleteTicketSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete ticket";
        yield put(deleteTicketFailure(message));
    }
}

function* getTicketById({ payload }) {
    try {
        const response = yield requestTicketDataById(payload);
        yield put(getTicketByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get ticket details";
        yield put(getTicketByIdFailure(message));
    }
}