import { put, takeLatest } from "redux-saga/effects";

import { requestDeleteDocument } from "../../../services/document";

import {
  deleteDocumentRequest,
  deleteDocumentSuccess,
  deleteDocumentFailure,
} from "../../reducers/document";

export function* watchDocumentSaga() {
  yield takeLatest(deleteDocumentRequest, deleteDocument);
}

function* deleteDocument({ payload }) {
  try {
    const response = yield requestDeleteDocument(payload);
    yield put(deleteDocumentSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete document";
    yield put(deleteDocumentFailure(message));
  }
}
