import error from "assets/error.jpg";
import React, { Component } from "react";
import { requestLogError } from "services/uierror";
class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    requestLogError({
      error: error.message,
      componentStack: errorInfo,
    });
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="d-flex justify-content-around">
          <div>
            <img src={error} alt="error" height={300} width={400} />
            <div className="error-heading">We're sorry...</div>
            <div className="error-label">
              An error occured while processing data
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
