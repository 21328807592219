import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    manageSiteList: { loading: false, data: null, error: null },
    createManageSite: { loading: false, data: null, error: null },
    updateManageSite: { loading: false, data: null, error: null },
    deleteManageSite: { loading: false, data: null, error: null },
    getManageSiteById: { loading: false, data: null, error: null }
};

export const ManageSiteSlice = createSlice({
    name: "manageSite",
    initialState,
    reducers: {
        fetchManageSiteListRequest: (manageSite, action) => {
            manageSite.manageSiteList.loading = true;
            manageSite.manageSiteList.error = null;
        },
        fetchManageSiteListSuccess: (manageSite, action) => {
            manageSite.manageSiteList.data = action.payload;
            manageSite.manageSiteList.loading = false;
            manageSite.manageSiteList.error = null;
        },
        fetchManageSiteListFailure: (manageSite, action) => {
            manageSite.manageSiteList.data = null;
            manageSite.manageSiteList.loading = false;
            manageSite.manageSiteList.error = action.payload;
        },

        //create manage site

        createManageSiteRequest: (manageSite, action) => {
            manageSite.createManageSite.loading = true;
            manageSite.createManageSite.error = null;
        },
        createManageSiteSuccess: (manageSite, action) => {
            manageSite.createManageSite.data = action.payload;
            manageSite.createManageSite.loading = false;
            manageSite.createManageSite.error = null;
        },
        createManageSiteFailure: (manageSite, action) => {
            manageSite.createManageSite.data = null;
            manageSite.createManageSite.loading = false;
            manageSite.createManageSite.error = action.payload;
        },

        // update manage  site

        updateManageSiteRequest: (manageSite, action) => {
            manageSite.updateManageSite.loading = true;
            manageSite.updateManageSite.error = null;
        },
        updateManageSiteSuccess: (manageSite, action) => {
            manageSite.updateManageSite.data = action.payload;
            manageSite.updateManageSite.loading = false;
            manageSite.updateManageSite.error = null;
        },
        updateManageSiteFailure: (manageSite, action) => {
            manageSite.updateManageSite.data = null;
            manageSite.updateManageSite.loading = false;
            manageSite.updateManageSite.error = action.payload;
        },

        //delete manage site

        deleteManageSiteRequest: (manageSite, action) => {
            manageSite.deleteManageSite.loading = true;
            manageSite.deleteManageSite.error = null;
        },
        deleteManageSiteSuccess: (manageSite, action) => {
            manageSite.deleteManageSite.data = action.payload;
            manageSite.deleteManageSite.loading = false;
            manageSite.deleteManageSite.error = null;
            manageSite.manageSiteList = deleteNewManageSiteSuccess(action.payload, {
                ...manageSite.manageSiteList,
            })
        },
        deleteManageSiteFailure: (manageSite, action) => {
            manageSite.deleteManageSite.data = null;
            manageSite.deleteManageSite.loading = false;
            manageSite.deleteManageSite.error = action.payload;
        },

        //getById manage site details

        getManageSiteByIdRequest: (manageSite, action) => {
            manageSite.getManageSiteById.loading = true;
            manageSite.getManageSiteById.error = null;
        },
        getManageSiteByIdSuccess: (manageSite, action) => {
            manageSite.getManageSiteById.data = action.payload;
            manageSite.getManageSiteById.loading = false;
            manageSite.getManageSiteById.error = null;
        },
        getManageSiteByIdFailure: (manageSite, action) => {
            manageSite.getManageSiteById.data = null;
            manageSite.getManageSiteById.loading = false;
            manageSite.getManageSiteById.error = action.payload;
        },

        //reset manage-site

        resetManageSite: (manageSite, action) => {
            manageSite.createManageSite.data = null;
            manageSite.createManageSite.loading = false;
            manageSite.createManageSite.error = null;

            manageSite.updateManageSite.data = null;
            manageSite.updateManageSite.loading = false;
            manageSite.updateManageSite.error = null;

            manageSite.deleteManageSite.data = null;
            manageSite.deleteManageSite.loading = false;
            manageSite.deleteManageSite.error = null;
        }
    }
})

function deleteNewManageSiteSuccess(dataToRemove, state) {
    state.data.data = state.data.data.filter((e) => e._id !== dataToRemove);
    return state;
}

export const {
    fetchManageSiteListRequest,
    fetchManageSiteListSuccess,
    fetchManageSiteListFailure,

    createManageSiteRequest,
    createManageSiteSuccess,
    createManageSiteFailure,

    updateManageSiteRequest,
    updateManageSiteSuccess,
    updateManageSiteFailure,

    deleteManageSiteRequest,
    deleteManageSiteSuccess,
    deleteManageSiteFailure,

    getManageSiteByIdRequest,
    getManageSiteByIdSuccess,
    getManageSiteByIdFailure,

    resetManageSite,

} = ManageSiteSlice.actions;

export const getManageSiteListState = (store) =>
    store?.siteManage?.manageSiteList;
export const getManageSiteCreateState = (store) =>
    store?.siteManage?.createManageSite;
export const getManageSiteUpdateState = (store) =>
    store?.siteManage?.updateManageSite;
export const getManageSiteByIdState = (store) =>
    store?.siteManage?.getManageSiteById;
export default ManageSiteSlice.reducer;