import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    machineList: { loading: false, data: null, error: null }
}

export const machineSlice = createSlice({
    name: "machine",
    initialState,
    reducers: {
        fetchMachineListRequest: (machine, action) => {
            machine.machineList.loading = true;
            machine.machineList.error = null;
        },
        fetchMachineListSuccess: (machine, action) => {
            machine.machineList.data = action.payload;
            machine.machineList.loading = false;
            machine.machineList.error = null
        },
        fetchMachineListFailure: (machine, action) => {
            machine.machineList.data = null;
            machine.machineList.loading = false;
            machine.machineList.error = action.payload;
        }
    }
})

export const {
    fetchMachineListRequest,
    fetchMachineListSuccess,
    fetchMachineListFailure
} = machineSlice.actions

export const getMachineListState = (store) =>
    store?.machine?.machineList;
export default machineSlice.reducer;