import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    svcExpenseList: { loading: false, data: null, error: null }
}

export const svcExpenseSlice = createSlice({
    name: "svcExpense",
    initialState,
    reducers: {
        fetchSvcExpenseListRequest: (svcExpense, action) => {
            svcExpense.svcExpenseList.loading = true;
            svcExpense.svcExpenseList.error = null;
        },
        fetchSvcExpenseListSuccess: (svcExpense, action) => {
            svcExpense.svcExpenseList.data = action.payload;
            svcExpense.svcExpenseList.loading = false;
            svcExpense.svcExpenseList.error = null
        },
        fetchSvcExpenseListFailure: (svcExpense, action) => {
            svcExpense.svcExpenseList.data = null;
            svcExpense.svcExpenseList.loading = false;
            svcExpense.svcExpenseList.error = action.payload;
        }
    }
})

export const {
    fetchSvcExpenseListRequest,
    fetchSvcExpenseListSuccess,
    fetchSvcExpenseListFailure
} = svcExpenseSlice.actions

export const getSvcExpenseListState = (store) =>
     store?.svcExpenseList?.svcExpenseList;
export default svcExpenseSlice.reducer;