import { put, takeLatest } from "redux-saga/effects";
import {
    requestExpenseList,
    requestCreateExpense,
    requestUpdateExpense,
    requestDeleteExpense,
    requestExpenseDataById,
} from "services/expense";

import {
    fetchExpenseListRequest,
    fetchExpenseListSuccess,
    fetchExpenseListFailure,
    createExpenseRequest,
    createExpenseSuccess,
    createExpenseFailure,
    updateExpenseRequest,
    updateExpenseSuccess,
    updateExpenseFailure,
    deleteExpenseRequest,
    deleteExpenseSuccess,
    deleteExpenseFailure,
    getExpenseByIdRequest,
    getExpenseByIdSuccess,
    getExpenseByIdFailure,
} from "redux/reducers/expense";

export function* watchExpenseSaga() {
    yield takeLatest(fetchExpenseListRequest, fetchExpenseList);
    yield takeLatest(createExpenseRequest, createExpense);
    yield takeLatest(updateExpenseRequest, updateExpense);
    yield takeLatest(deleteExpenseRequest, deleteExpense);
    yield takeLatest(getExpenseByIdRequest, getExpenseById);
}

function* fetchExpenseList({ payload }) {
    try {
        const response = yield requestExpenseList(payload);
        yield put(fetchExpenseListSuccess(response));
    } catch (error) {
        yield put(
            fetchExpenseListFailure("Failed to get list of expense")
        );
    }
}

function* createExpense({ payload }) {
    try {
        // service add
        const response = yield requestCreateExpense(payload);
        yield put(createExpenseSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to create expense";
        yield put(createExpenseFailure(message));
    }
}

function* updateExpense({ payload }) {
    try {
        const response = yield requestUpdateExpense(payload);
        yield put(updateExpenseSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to update expense";
        yield put(updateExpenseFailure(message));
    }
}

function* deleteExpense({ payload }) {
    try {
        const response = yield requestDeleteExpense(payload);
        yield put(deleteExpenseSuccess(response.data));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to delete expense";
        yield put(deleteExpenseFailure(message));
    }
}

function* getExpenseById({ payload }) {
    try {
        const response = yield requestExpenseDataById(payload);
        yield put(getExpenseByIdSuccess(response));
    } catch (error) {
        const message =
            error?.response?.data?.data?.message || "Failed to get expense details";
        yield put(getExpenseByIdFailure(message));
    }
}