import { put, takeLatest } from "redux-saga/effects";
import {
  requestManageUserList,
  requestCreateUser,
  requestUpdateUser,
  requestDeleteUser,
  requestUpdateUserDetails,
  requestUserDataById,
  requestGetUserByRoleList,
} from "../../../services/manageUser";
import {
  fetchManageUserRequest,
  fetchManageUserSuccess,
  fetchManageUserFailure,
  createManageUserRequest,
  createManageUserSuccess,
  createManageUserFailure,
  updateManageUserRequest,
  updateManageUserSuccess,
  updateManageUserFailure,
  deleteManageUserRequest,
  deleteManageUserSuccess,
  deleteManageUserFailure,
  updateManageUserDetailsRequest,
  updateManageUserDetailsSuccess,
  updateManageUserDetailsFailure,
  getUserDetailsRequest,
  getUserDetailsSuccess,
  getUserDetailsFailure,
  deleteFeatureUserRequest,
  deleteFeatureUserSuccess,
  deleteFeatureUserFailure,
  getUserByRoleRequest,
  getUserByRoleSuccess,
  getUserByRoleFailure,
  getByIdUserDetailsRequest,
  getByIdUserDetailsSuccess,
  getByIdUserDetailsFailure,
} from "../../reducers/manageUser";

export function* watchManageUserSaga() {
  yield takeLatest(fetchManageUserRequest, fetchManageUserList);
  yield takeLatest(createManageUserRequest, createManageUser);
  yield takeLatest(updateManageUserRequest, updateUser);
  yield takeLatest(deleteManageUserRequest, deleteUser);
  yield takeLatest(updateManageUserDetailsRequest, updateUserDetails);
  yield takeLatest(getUserDetailsRequest, getUserData);
  yield takeLatest(deleteFeatureUserRequest, deleteFeature);
  yield takeLatest(getUserByRoleRequest, fetchUserByRoleList);
  yield takeLatest(getByIdUserDetailsRequest, getUserByIdData);
}

function* fetchManageUserList({ payload }) {
  try {
    const response = yield requestManageUserList(payload);
    yield put(fetchManageUserSuccess(response));
  } catch (error) {
    const message = error.message || "Failed to get list of users";
    yield put(fetchManageUserFailure(message));
  }
}

function* createManageUser({ payload }) {
  try {
    //service add
    const response = yield requestCreateUser(payload);
    yield put(createManageUserSuccess(response.data));
  } catch (error) {
    const message = error?.response?.data?.data || "Failed to create User";
    yield put(createManageUserFailure(message));
  }
}

function* updateUser({ payload }) {
  try {
    //service update
    const response = yield requestUpdateUser(payload);
    yield put(updateManageUserSuccess(response.data));
  } catch (error) {
    const message = error?.response?.data?.data || "Failed to update User";
    yield put(updateManageUserFailure(message));
  }
}

function* deleteUser({ payload }) {
  try {
    const response = yield requestDeleteUser(payload);
    yield put(deleteManageUserSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.data || "Failed to Delete user";
    yield put(deleteManageUserFailure(message));
  }
}

function* updateUserDetails({ payload }) {
  try {
    const response = yield requestUpdateUserDetails(payload);
    yield put(updateManageUserDetailsSuccess(response));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message ||
      "Please delete existing Feature and then add same Feature with updated Privilege";
    yield put(updateManageUserDetailsFailure(message));
  }
}

function* getUserData({ payload }) {
  try {
    const response = yield requestUserDataById(payload);
    yield put(getUserDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data || "Failed to get users details";
    yield put(getUserDetailsFailure(message));
  }
}

function* deleteFeature({ payload }) {
  try {
    const response = yield requestUpdateUserDetails(payload);
    yield put(deleteFeatureUserSuccess(response));
  } catch (error) {
    yield put(deleteFeatureUserFailure("Failed to Delete feature"));
  }
}

function* fetchUserByRoleList({ payload }) {
  try {
    const response = yield requestGetUserByRoleList(payload);
    yield put(getUserByRoleSuccess(response));
  } catch (error) {
    const message = error.message || "Failed to get list of users";
    yield put(getUserByRoleFailure(message));
  }
}

function* getUserByIdData({ payload }) {
  try {
    const response = yield requestUserDataById(payload);
    yield put(getByIdUserDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data || "Failed to get users details";
    yield put(getByIdUserDetailsFailure(message));
  }
}
