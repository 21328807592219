import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cqrsDataList: { loading: false, data: null, error: null },
};

export const CqrsData = createSlice({
  name: "cqrsData",
  initialState,
  reducers: {
    fetchCqrsDataListRequest: (state, action) => {
      state.cqrsDataList.loading = true;
      state.cqrsDataList.error = null;
    },
    fetchCqrsDataListSuccess: (state, action) => {
      state.cqrsDataList.data = action.payload;
      state.cqrsDataList.loading = false;
      state.cqrsDataList.error = null;
    },
    fetchCqrsDataListFailure: (state, action) => {
      state.cqrsDataList.data = null;
      state.cqrsDataList.loading = false;
      state.cqrsDataList.error = action.payload;
    },
  },
});

export const {
  fetchCqrsDataListRequest,
  fetchCqrsDataListSuccess,
  fetchCqrsDataListFailure,
} = CqrsData.actions;

export const getCqrsDataListState = (store) => store.cqrsData?.cqrsDataList;

export default CqrsData.reducer;