import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceProviderList: { loading: false, data: null, error: null },
  createServiceProvider: { loading: false, data: null, error: null },
  updateServiceProvider: { loading: false, data: null, error: null },
  deleteServiceProvider: { loading: false, data: null, error: null },
  getServiceProviderById: { loading: false, data: null, error: null },
};

export const ServiceProviderSlice = createSlice({
  name: "serviceProvider",
  initialState,
  reducers: {
    fetchServiceProviderListRequest: (serviceProvider, action) => {
      serviceProvider.serviceProviderList.loading = true;
      serviceProvider.serviceProviderList.error = null;
    },
    fetchServiceProviderListSuccess: (serviceProvider, action) => {
      serviceProvider.serviceProviderList.data = action.payload;
      serviceProvider.serviceProviderList.loading = false;
      serviceProvider.serviceProviderList.error = null;
    },
    fetchServiceProviderListFailure: (serviceProvider, action) => {
      serviceProvider.serviceProviderList.data = null;
      serviceProvider.serviceProviderList.loading = false;
      serviceProvider.serviceProviderList.error = action.payload;
    },

    // create serviceProvider
    createServiceProviderRequest: (serviceProvider, action) => {
       serviceProvider.createServiceProvider.loading = true;
       serviceProvider.createServiceProvider.error = null;
    },
    createServiceProviderSuccess: (serviceProvider, action) => {
        serviceProvider.createServiceProvider.data = action.payload;
        serviceProvider.createServiceProvider.loading = false;
        serviceProvider.createServiceProvider.error = null;
        serviceProvider.serviceProviderList = addNewProviderSuccess(
        action.payload,
        {
          ...serviceProvider.serviceProviderList,
        }
      );
    },
    createServiceProviderFailure: (serviceProvider, action) => {
        serviceProvider.createServiceProvider.data = null;
        serviceProvider.createServiceProvider.loading = false;
        serviceProvider.createServiceProvider.error = action.payload;
    },

    // update serviceProvider details
    updateServiceProviderRequest: (serviceProvider, action) => {
        serviceProvider.updateServiceProvider.loading = true;
        serviceProvider.updateServiceProvider.error = null;
    },
    updateServiceProviderSuccess: (serviceProvider, action) => {
        serviceProvider.updateServiceProvider.data = action.payload;
        serviceProvider.updateServiceProvider.loading = false;
        serviceProvider.updateServiceProvider.error = null;
        serviceProvider.serviceProviderList = updateProviderSuccess(
        action.payload,
        serviceProvider.serviceProviderList
      );
    },
    updateServiceProviderFailure: (serviceProvider, action) => {
        serviceProvider.updateServiceProvider.data = null;
        serviceProvider.updateServiceProvider.loading = false;
        serviceProvider.updateServiceProvider.error = action.payload;
    },

    // delete serviceProvider
    deleteServiceProviderRequest: (serviceProvider, action) => {
        serviceProvider.deleteServiceProvider.loading = true;
        serviceProvider.deleteServiceProvider.error = null;
    },
    deleteServiceProviderSuccess: (serviceProvider, action) => {
        serviceProvider.deleteServiceProvider.loading = false;
        serviceProvider.deleteServiceProvider.error = null;
        serviceProvider.deleteServiceProvider.data = action.payload;
        serviceProvider.serviceProviderList = deleteProvider(action.payload, {
        ...serviceProvider.serviceProviderList,
      });
    },
    deleteServiceProviderFailure: (serviceProvider, action) => {
        serviceProvider.deleteServiceProvider.data = null;
        serviceProvider.deleteServiceProvider.loading = false;
        serviceProvider.deleteServiceProvider.error = action.payload;
    },

    // get by id serviceProvider details
    getServiceProviderDetailsRequest: (serviceProvider, action) => {
        serviceProvider.getServiceProviderById.loading = true;
        serviceProvider.getServiceProviderById.error = null;
    },
    getServiceProviderDetailsSuccess: (serviceProvider, action) => {
        serviceProvider.getServiceProviderById.loading = false;
        serviceProvider.getServiceProviderById.error = null;
        serviceProvider.getServiceProviderById.data = action.payload;
    },
    getServiceProviderDetailsFailure: (serviceProvider, action) => {
        serviceProvider.getServiceProviderById.data = null;
        serviceProvider.getServiceProviderById.loading = false;
        serviceProvider.getServiceProviderById.error = action.payload;
    },

    //Reset serviceProvider state
    resetServiceProvider: (serviceProvider, action) => {
        serviceProvider.createServiceProvider.data = null;
        serviceProvider.createServiceProvider.loading = false;
        serviceProvider.createServiceProvider.error = null;

        serviceProvider.updateServiceProvider.data = null;
        serviceProvider.updateServiceProvider.loading = false;
        serviceProvider.updateServiceProvider.error = null;

        serviceProvider.deleteServiceProvider.data = null;
        serviceProvider.deleteServiceProvider.loading = false;
        serviceProvider.deleteServiceProvider.error = null;
    },
  },
});

// addNewPrivilege function
function addNewProviderSuccess(dataToUpdate, state) {
  state.data.data = [dataToUpdate, ...state.data.data];
  state.data.total++;
  return state;
}

// updatePrivilege function
function updateProviderSuccess(dataToUpdate, state) {
  state.data.data = state.data.data.map((value) => {
    if (value._id !== dataToUpdate._id) return value;
    return { ...value, ...dataToUpdate };
  });

  return state;
}

// deletePrivilege function
function deleteProvider(dataToRemove, state) {
  state.data.data = state.data.data.filter((e) => e._id !== dataToRemove.data);
  return state;
}

export const {
  fetchServiceProviderListRequest,
  fetchServiceProviderListSuccess,
  fetchServiceProviderListFailure,

  createServiceProviderRequest,
  createServiceProviderSuccess,
  createServiceProviderFailure,

  updateServiceProviderRequest,
  updateServiceProviderSuccess,
  updateServiceProviderFailure,

  deleteServiceProviderRequest,
  deleteServiceProviderSuccess,
  deleteServiceProviderFailure,

  getServiceProviderDetailsRequest,
  getServiceProviderDetailsSuccess,
  getServiceProviderDetailsFailure,

  resetServiceProvider,
} = ServiceProviderSlice.actions;

export const getServiceProviderListState = (store) =>
  store?.serviceProvider?.serviceProviderList;
export const getServiceProviderCreateState = (store) =>
  store?.serviceProvider?.createServiceProvider;
export const getUpdateServiceProviderState = (store) =>
  store?.serviceProvider?.updateServiceProvider;
export const getServiceProviderDeleteState = (store) =>
  store?.serviceProvider?.deleteServiceProvider;
export const getServiceProviderGetByIdState = (store) =>
  store?.serviceProvider?.getServiceProviderById;
export default ServiceProviderSlice.reducer;
