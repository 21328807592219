import { put, takeLatest } from "redux-saga/effects";
import {
    fetchAssetTypeListFailure,
    fetchAssetTypeListRequest,
    fetchAssetTypeListSuccess
} from "redux/reducers/assetType";
import {
    requestAssetTypeList
} from "services/assetType";

export function* watchAssetTypeSaga() {
    yield takeLatest(fetchAssetTypeListRequest, fetchAssetTypeList);
}

function* fetchAssetTypeList({ payload }) {
    try {
        const response = yield requestAssetTypeList(payload);
        yield put(fetchAssetTypeListSuccess(response));
    } catch (error) {
        yield put(
            fetchAssetTypeListFailure("Failed to get list of asset type")
        )
    }
}