import { put, takeLatest } from "redux-saga/effects";
import {
  requestmanageRoleList,
  requestCreateRole,
  requestupdateRole,
  requestGetByIdmanageRole,
  requestAddFeatureRole,
  requestDeleteRole,
} from "services/manageRole";
import {
  fetchmanageRoleListRequest,
  fetchmanageRoleListSuccess,
  fetchmanageRoleListFaliure,
  createmanageRoleFaliure,
  createmanageRoleSuccess,
  createmanageRoleRequest,
  updatemanageRoleRequest,
  updatemanageRoleSuccess,
  updatemanageRoleFaliure,
  manageRoleGetByIdRequest,
  manageRoleGetByIdSuccess,
  manageRoleGetByIdFailure,
  addFeatureRoleRequest,
  addFeatureRoleSuccess,
  addFeatureRoleFaliure,
  deletemanageRoleRequest,
  deletemanageRoleSuccess,
  deletemanageRoleFaliure,
  deleteFeatureRoleRequest,
  deleteFeatureRoleSuccess,
  deleteFeatureRoleFaliure,
} from "redux/reducers/manageRole";
export function* watchmanageRoleSaga() {
  yield takeLatest(fetchmanageRoleListRequest, fetchRoles);
  yield takeLatest(createmanageRoleRequest, createRole);
  yield takeLatest(updatemanageRoleRequest, updateRole);
  yield takeLatest(manageRoleGetByIdRequest, fetchmanageRoleGetById);
  yield takeLatest(addFeatureRoleRequest, addFeature);
  yield takeLatest(deletemanageRoleRequest, deleteRole);
  yield takeLatest(deleteFeatureRoleRequest, deleteFeature);
}
function* fetchRoles({ payload }) {
  try {
    const response = yield requestmanageRoleList(payload);
    yield put(fetchmanageRoleListSuccess(response));
  } catch (error) {
    yield put(fetchmanageRoleListFaliure("failed to get list of roles"));
  }
}

function* createRole({ payload }) {
  try {
    const response = yield requestCreateRole(payload);
    yield put(createmanageRoleSuccess(response.data));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to create Role";
    yield put(createmanageRoleFaliure(message));
  }
}

function* updateRole({ payload }) {
  try {
    const response = yield requestupdateRole(payload);
    yield put(updatemanageRoleSuccess(response.data));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to get update Role";
    yield put(updatemanageRoleFaliure(message));
  }
}

function* fetchmanageRoleGetById({ payload }) {
  try {
    const response = yield requestGetByIdmanageRole(payload);
    yield put(manageRoleGetByIdSuccess(response));
  } catch (error) {
    yield put(manageRoleGetByIdFailure("Failed to get role details"));
  }
}

function* addFeature({ payload }) {
  try {
    const response = yield requestAddFeatureRole(payload);
    yield put(addFeatureRoleSuccess({ response, roleid: payload.id }));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message ||
      "Please delete existing Feature and then add same Feature with updated Privilege";
    yield put(addFeatureRoleFaliure(message));
  }
}

function* deleteRole({ payload }) {
  try {
    const response = yield requestDeleteRole(payload);
    yield put(deletemanageRoleSuccess(response));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to Delete Role";
    yield put(deletemanageRoleFaliure(message));
  }
}

function* deleteFeature({ payload }) {
  try {
    const response = yield requestAddFeatureRole(payload);
    yield put(deleteFeatureRoleSuccess({ response, roleid: payload.id }));
  } catch (error) {
    let message =
      error?.response?.data?.data?.message || "Failed to Delete role feature";
    yield put(deleteFeatureRoleFaliure(message));
  }
}
