import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationList: { loading: false, data: null, error: null },
};

export const notification = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    fetchNotificationRequest: (manageUser, action) => {
      manageUser.notificationList.loading = true;
      manageUser.notificationList.error = null;
    },
    fetchNotificationSuccess: (manageUser, action) => {
      manageUser.notificationList.data = action.payload;
      manageUser.notificationList.loading = false;
      manageUser.notificationList.error = null;
    },
    fetchNotificationFailure: (manageUser, action) => {
      manageUser.notificationList.data = null;
      manageUser.notificationList.loading = false;
      manageUser.notificationList.error = action.payload;
    },
  },
});

export const {
  fetchNotificationRequest,
  fetchNotificationSuccess,
  fetchNotificationFailure,
} = notification.actions;

export const getNotificationsState = (store) =>
  store?.notifications?.notificationList;

export default notification.reducer;
