import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  assetList: { loading: false, data: null, error: null },
};

export const assetSlice = createSlice({
  name: "asset",
  initialState,
  reducers: {
    fetchAssetListRequest: (asset, action) => {
      asset.assetList.loading = true;
      asset.assetList.error = null;
    },
    fetchAssetListSuccess: (asset, action) => {
      asset.assetList.data = action.payload;
      asset.assetList.loading = false;
      asset.assetList.error = null;
    },
    fetchAssetListFailure: (asset, action) => {
      asset.assetList.data = null;
      asset.assetList.loading = false;
      asset.assetList.error = action.payload;
    },
  },
});

export const {
  fetchAssetListRequest,
  fetchAssetListSuccess,
  fetchAssetListFailure,
} = assetSlice.actions;

export const getAssetListState = (store) => store?.asset?.assetList;
export default assetSlice.reducer;
