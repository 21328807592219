import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sparePartsList: { loading: false, data: null, error: null }
}

export const sparePartsSlice = createSlice({
    name: "spareParts",
    initialState,
    reducers: {
        fetchSparePartsListRequest: (spareParts, action) => {
            spareParts.sparePartsList.loading = true;
            spareParts.sparePartsList.error = null;
        },
        fetchSparePartsListSuccess: (spareParts, action) => {
            spareParts.sparePartsList.data = action.payload;
            spareParts.sparePartsList.loading = false;
            spareParts.sparePartsList.error = null
        },
        fetchSparePartsListFailure: (spareParts, action) => {
            spareParts.sparePartsList.data = null;
            spareParts.sparePartsList.loading = false;
            spareParts.sparePartsList.error = action.payload;
        }
    }
})

export const {
    fetchSparePartsListRequest,
    fetchSparePartsListSuccess,
    fetchSparePartsListFailure
} = sparePartsSlice.actions

export const getSparePartsListState = (store) =>
    store?.spareParts?.sparePartsList;
export default sparePartsSlice.reducer;