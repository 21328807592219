import { put, takeLatest } from "redux-saga/effects";

import { requestNotificationList } from "../../../services/notifications";
import {
  fetchNotificationFailure,
  fetchNotificationRequest,
  fetchNotificationSuccess,
  
} from "../../reducers/notification";

export function* watchNotificationsSaga() {
  yield takeLatest(fetchNotificationRequest, fetchNotificationList);
}

function* fetchNotificationList({ payload }) {
  try {
    const response = yield requestNotificationList(payload);
    yield put(fetchNotificationSuccess(response));
  } catch (error) {
    yield put(fetchNotificationFailure("Failed to get notifications"));
  }
}
