import { put, takeLatest } from "redux-saga/effects";

import {
  requestSensorInstanceList,
  requestCreateSensorInstance,
  requestUpdateSensorInstance,
  requestDeleteSensorInstance,
  requestSensorInstanceDataById,
  requestIMEINoDropDownList,
} from "../../../services/sensorInstance";
import {
  fetchSensorInstanceListRequest,
  fetchSensorInstanceListSuccess,
  fetchSensorInstanceListFailure,
  createSensorInstanceRequest,
  createSensorInstanceSuccess,
  createSensorInstanceFailure,
  updateSensorInstanceRequest,
  updateSensorInstanceSuccess,
  updateSensorInstanceFailure,
  deleteSensorInstanceRequest,
  deleteSensorInstanceSuccess,
  deleteSensorInstanceFailure,
  getSensorInstanceDetailsRequest,
  getSensorInstanceDetailsSuccess,
  getSensorInstanceDetailsFailure,
  fetchIMEINoListRequest,
  fetchIMEINoListSuccess,
  fetchIMEINoListFailure,
} from "../../reducers/sensorInstance";

export function* watchSensorInstanceSaga() {
  yield takeLatest(fetchSensorInstanceListRequest, fetchSensorInstanceList);
  yield takeLatest(createSensorInstanceRequest, createSensorInstance);
  yield takeLatest(updateSensorInstanceRequest, updateSensorInstance);
  yield takeLatest(deleteSensorInstanceRequest, deleteSensorInstance);
  yield takeLatest(
    getSensorInstanceDetailsRequest,
    getSensorInstanceGetByIdData
  );
  yield takeLatest(fetchIMEINoListRequest, getIMEINoListData);
}

function* fetchSensorInstanceList({ payload }) {
  try {
    const response = yield requestSensorInstanceList(payload);
    yield put(fetchSensorInstanceListSuccess(response));
  } catch (error) {
    yield put(
      fetchSensorInstanceListFailure("Failed to get list of SensorInstance")
    );
  }
}

function* createSensorInstance({ payload }) {
  try {
    //service add
    const response = yield requestCreateSensorInstance(payload);
    yield put(createSensorInstanceSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to create SensorInstance";
    if (error?.response?.data?.data?.code === 11000) {
      let objectIntoString = JSON.stringify(
        error?.response?.data?.data?.keyValue
      );
      let removeSpecialChar = objectIntoString.replace(/[""{}]/g, "");
      let finalValue = removeSpecialChar.split(":")[0];
      yield put(
        createSensorInstanceFailure(
          "Duplicate " +
            finalValue +
            " Field. " +
            " Unique Number is Required !"
        )
      );
    } else {
      yield put(createSensorInstanceFailure(message));
    }
  }
}

function* updateSensorInstance({ payload }) {
  try {
    //service update
    const response = yield requestUpdateSensorInstance(payload);
    yield put(updateSensorInstanceSuccess(response.data));
  } catch (error) {
    const message =
      error?.response?.data?.data?._message || "Failed to update SensorInstance";
    if (error?.response?.data?.data?.code === 11000) {
      let objectIntoString = JSON.stringify(
        error?.response?.data?.data?.keyValue
      );
      let removeSpecialChar = objectIntoString.replace(/[""{}]/g, "");
      let finalValue = removeSpecialChar.split(":")[0];
      yield put(
        updateSensorInstanceFailure(
          "Duplicate " +
            finalValue +
            " Field. " +
            " Unique Number is Required !"
        )
      );
    } else {
      yield put(updateSensorInstanceFailure(message));
    }
  }
}

function* deleteSensorInstance({ payload }) {
  try {
    const response = yield requestDeleteSensorInstance(payload);
    yield put(deleteSensorInstanceSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message || "Failed to Delete SensorInstance";
    yield put(deleteSensorInstanceFailure(message));
  }
}

function* getSensorInstanceGetByIdData({ payload }) {
  try {
    const response = yield requestSensorInstanceDataById(payload);
    yield put(getSensorInstanceDetailsSuccess(response));
  } catch (error) {
    const message =
      error?.response?.data?.data?.message ||
      "Failed to get SensorInstance details";
    yield put(getSensorInstanceDetailsFailure(message));
  }
}

function* getIMEINoListData({ payload }) {
  try {
    const response = yield requestIMEINoDropDownList(payload);
    yield put(fetchIMEINoListSuccess(response));
  } catch (error) {
    yield put(fetchIMEINoListFailure("Failed to get list of IMEI No"));
  }
}
