import { put, takeLatest } from "redux-saga/effects";

import { requestSideMenuList } from "../../../services/getSideMenu";
import {
  fetchSideMenuRequest,
  fetchSideMenuSuccess,
  fetchSideMenuFailure,
} from "../../reducers/getSideMenu";

export function* watchSideMenuSaga() {
  yield takeLatest(fetchSideMenuRequest, fetchSideMenuList);
}

/**
 *
 * @param {*} param0
 */
function* fetchSideMenuList({ payload }) {
  try {
    const response = yield requestSideMenuList(payload);
    yield put(fetchSideMenuSuccess(response));
  } catch (error) {
    yield put(fetchSideMenuFailure("Failed to get SideMenu"));
  }
}
